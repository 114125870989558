import { CCDLimb } from "../rendering/CCDLimb.ts";
import { Vector2D } from "../math/vector2D.ts";
import type { Camera } from "./camera.ts";
import type {
  SerializedActuatorData,
  SerializedActuatorState,
} from "../models.ts";
import type { Plane } from "./plane.ts";
import type { Body } from "./body.ts";

export enum ControlMode {
  PD,
  StateSpace,
}

export interface PDParams {
  Kp: number;
  Ki: number;
  Kd: number;
  windupLimit: number;
}

export enum ActuatorBody {
  First,
  Second,
}

export interface ActuatorConfig {
  pdParams: PDParams;
  maxLength: number; // Maximum allowed length between actuator objects
  thrustStrength: number;
  thrustCooldown: number;
  tetherRestitution: number;
  tetherBaumgarteScale: number;
  tetherSlop: number;
}

export enum GrabState {
  NotGrabbing,
  SearchingForGrabs,
  Holding,
  HoldingAndSearching,
}

export class Actuator {
  id: string;
  body1: Body;
  body2: Body;
  maxForce: number;
  pdParams: PDParams;
  maxLength: number;
  thrustStrength: number;
  tetherRestitution: number;
  tetherBaumgarteScale: number;
  tetherSlop: number;
  thrustCooldown: number;

  // State
  isGrabbing: [boolean, boolean] = [false, false];
  grabbedBodyIndices: [number | null, number | null] = [null, null];
  lastError?: Vector2D;
  integral: Vector2D;
  grabStates: [GrabState, GrabState] = [
    GrabState.NotGrabbing,
    GrabState.NotGrabbing,
  ];
  targetPos: Vector2D;
  lastImpulseTime: number = 0;
  impulseRequested: boolean = false;

  ccdLimb: CCDLimb;

  constructor(id: string, body1: Body, body2: Body, config?: ActuatorConfig) {
    this.id = id;
    this.body1 = body1;
    this.body2 = body2;

    this.maxForce = 100000000; // Maximum force that can be applied, prevents extreme reactions
    this.pdParams = config?.pdParams ?? {
      // Proportional gain: Immediate response to position error
      // Higher = stronger immediate response, but can cause oscillation
      // Lower = softer response, but may be sluggish
      Kp: 2000,

      // Integral gain: Accumulates error over time to eliminate steady-state error
      // Higher = faster elimination of steady-state error, but can cause overshoot
      // Lower = slower correction, but more stable
      Ki: 100,

      // Derivative gain: Provides damping based on rate of error change
      // Higher = more damping/stability, but can make response sluggish
      // Lower = faster response, but can lead to oscillation
      Kd: 500,

      // Maximum accumulated integral term to prevent excessive buildup
      // Higher = allows more accumulated correction, but can cause overshoot
      // Lower = limits correction, but prevents large overshoots
      windupLimit: 10000,
    };
    this.thrustStrength = config?.thrustStrength ?? 1000000;
    this.thrustCooldown = config?.thrustCooldown ?? 0.1;
    this.tetherRestitution = config?.tetherRestitution ?? 0.1;
    this.tetherBaumgarteScale = config?.tetherBaumgarteScale ?? 0.7;
    this.tetherSlop = config?.tetherSlop ?? 0.1;
    this.integral = new Vector2D(0, 0);
    this.maxLength = config?.maxLength ?? 2000; // Default max length
    this.targetPos = new Vector2D(0, 0);

    this.ccdLimb = new CCDLimb({
      length: this.maxLength,
      numSegments: 2,
    });
  }

  getData(): SerializedActuatorData {
    return {
      id: this.id,
      body1Id: this.body1.id,
      body2Id: this.body2.id,
      config: {
        pdParams: this.pdParams,
        maxLength: this.maxLength,
        thrustStrength: this.thrustStrength,
        thrustCooldown: this.thrustCooldown,
        tetherRestitution: this.tetherRestitution,
        tetherBaumgarteScale: this.tetherBaumgarteScale,
        tetherSlop: this.tetherSlop,
      },
    };
  }

  updateConfig(config: ActuatorConfig): void {
    this.pdParams = config.pdParams;
    this.maxLength = config.maxLength;
    this.thrustStrength = config.thrustStrength;
    this.thrustCooldown = config.thrustCooldown;
    this.tetherRestitution = config.tetherRestitution;
    this.tetherBaumgarteScale = config.tetherBaumgarteScale;
    this.tetherSlop = config.tetherSlop;
  }

  getState(): SerializedActuatorState {
    return {
      targetPos: [this.targetPos.x, this.targetPos.y],
      grabbedBodyIndices: this.grabbedBodyIndices,
      grabStates: this.grabStates,
      isGrabbing: this.isGrabbing,
      lastError: this.lastError ? [this.lastError.x, this.lastError.y] : null,
      integral: [this.integral.x, this.integral.y],
      lastImpulseTime: this.lastImpulseTime,
      impulseRequested: this.impulseRequested,
    };
  }

  updateTargetPos(pos: Vector2D): void {
    this.targetPos = pos;
  }

  setGrabbing(body: ActuatorBody, isGrabbing: boolean): void {
    if (body === ActuatorBody.Second) {
      // Second body behaves as before
      this.isGrabbing[body] = isGrabbing;
      return;
    }

    // First body uses simplified state machine
    switch (this.grabStates[ActuatorBody.First]) {
      case GrabState.NotGrabbing:
        if (isGrabbing) {
          this.grabStates[ActuatorBody.First] = GrabState.SearchingForGrabs;
          this.isGrabbing[body] = true;
        }
        break;

      case GrabState.SearchingForGrabs:
        if (!isGrabbing) {
          // If we never found anything to grab, go back to NotGrabbing
          if (this.grabbedBodyIndices[body] === null) {
            this.grabStates[ActuatorBody.First] = GrabState.NotGrabbing;
            this.isGrabbing[body] = false;
          } else {
            this.grabStates[ActuatorBody.First] = GrabState.Holding;
            this.isGrabbing[body] = false;
          }
        }
        break;

      case GrabState.Holding:
        if (isGrabbing) {
          this.grabStates[ActuatorBody.First] = GrabState.HoldingAndSearching;
          this.isGrabbing[body] = true;
        }
        break;

      case GrabState.HoldingAndSearching:
        if (!isGrabbing) {
          this.grabStates[ActuatorBody.First] = GrabState.Holding;
          this.isGrabbing[body] = false;
        }
        break;
    }
  }

  private updateGrab(plane: Plane): void {
    // Update grabs for both bodies
    this.updateBodyGrab(plane, ActuatorBody.First);
    this.updateBodyGrab(plane, ActuatorBody.Second);
  }

  private updateBodyGrab(plane: Plane, body: ActuatorBody): void {
    // Handle release - only for second body
    if (
      body === ActuatorBody.Second &&
      !this.isGrabbing[body] &&
      this.grabbedBodyIndices[body] !== null
    ) {
      plane.removeBodyFromComposite(this.body2.index);
      this.grabbedBodyIndices[body] = null;
      return;
    }

    // Handle grab
    if (body === ActuatorBody.First) {
      // First body can grab multiple objects when searching
      if (
        this.grabStates[ActuatorBody.First] === GrabState.SearchingForGrabs ||
        this.grabStates[ActuatorBody.First] === GrabState.HoldingAndSearching
      ) {
        this.searchForGrabs(plane, this.body1.index);
      }
    } else {
      // Second body can only grab one object at a time
      if (this.isGrabbing[body] && this.grabbedBodyIndices[body] === null) {
        this.searchForGrabs(plane, this.body2.index);
      }
    }
  }

  // Helper method to search for objects to grab
  private searchForGrabs(plane: Plane, actuatorBodyIndex: number): void {
    for (const result of plane.booleanResults) {
      if (
        actuatorBodyIndex === result.body1Index ||
        actuatorBodyIndex === result.body2Index
      ) {
        const grabbedIndex =
          actuatorBodyIndex === result.body1Index
            ? result.body2Index
            : result.body1Index;

        // Don't grab our own actuator bodies
        if (
          grabbedIndex === this.body1.index ||
          grabbedIndex === this.body2.index
        )
          continue;

        // Special handling for second body trying to grab from first body's composite
        if (actuatorBodyIndex === this.body2.index) {
          const firstBodyComposite = this.body1.composite;
          if (
            firstBodyComposite &&
            plane.isBodyInComposite(grabbedIndex, this.body1.index)
          ) {
            // Remove from first body's composite before grabbing
            plane.removeBodyFromComposite(grabbedIndex);
          }
        }

        // For first body, don't grab if we already have this body
        if (
          actuatorBodyIndex === this.body1.index &&
          plane.isBodyInComposite(grabbedIndex, actuatorBodyIndex)
        )
          continue;

        plane.composeBodies(actuatorBodyIndex, grabbedIndex);
        this.grabbedBodyIndices[
          actuatorBodyIndex === this.body1.index
            ? ActuatorBody.First
            : ActuatorBody.Second
        ] = grabbedIndex;
        break;
      }
    }
  }

  private applyTetherConstraint(): {
    appliedPositionCorrectionTo: Body[];
  } {
    // Get positions and masses
    const pos1 = this.body1.getCenter();
    const pos2 = this.body2.getCenter();
    const mass1 = this.body1.getMass(true);
    const mass2 = this.body2.getMass(true);

    // Calculate inverse masses (0 for infinite mass)
    const invMass1 = mass1 === Infinity ? 0 : 1 / mass1;
    const invMass2 = mass2 === Infinity ? 0 : 1 / mass2;

    // Skip if both objects have infinite mass
    if (invMass1 === 0 && invMass2 === 0)
      return { appliedPositionCorrectionTo: [] };

    // Calculate current length and direction
    const displacement = pos2.subtract(pos1);
    const currentLength = displacement.magnitude();

    // Only apply constraint if length exceeds maximum
    if (currentLength > this.maxLength) {
      const direction = displacement.scale(1 / currentLength); // Normalized direction

      // Get velocities at attachment points
      const vel1 = this.body1.getLinearVelocity(true);
      const vel2 = this.body2.getLinearVelocity(true);
      const relativeVelocity = vel2.subtract(vel1);

      // Project relative velocity onto constraint direction
      const normalVelocity = relativeVelocity.dot(direction);

      // Get moments of inertia and calculate inverse inertias
      const inertia1 = this.body1.getMomentOfInertia(true);
      const inertia2 = this.body2.getMomentOfInertia(true);

      const invInertia1 = this.body1.rotationLocked ? 0 : 1 / inertia1;
      const invInertia2 = this.body2.rotationLocked ? 0 : 1 / inertia2;

      // Calculate moment arms (from center of mass to point of force application)
      const r1 = pos1.subtract(this.body1.getCenter(true));
      const r2 = pos2.subtract(this.body2.getCenter(true));

      // Calculate r cross n terms for the denominator
      const r1CrossN = r1.x * direction.y - r1.y * direction.x;
      const r2CrossN = r2.x * direction.y - r2.y * direction.x;

      // Calculate denominator including angular terms
      const denominator =
        invMass1 +
        invMass2 +
        r1CrossN * r1CrossN * invInertia1 +
        r2CrossN * r2CrossN * invInertia2;

      if (denominator === 0) return { appliedPositionCorrectionTo: [] };

      // Calculate impulse magnitude
      const penetration = currentLength - this.maxLength;
      const restitutionImpulse =
        normalVelocity > 0 ? -(1 + this.tetherRestitution) * normalVelocity : 0;
      const penetrationImpulse = penetration * this.tetherBaumgarteScale;
      const totalImpulse =
        (restitutionImpulse + penetrationImpulse) / denominator;

      const impulseVector = direction.scale(-totalImpulse);

      // Apply linear and angular impulses
      if (invMass1 > 0) {
        this.body1.applyLinearImpulse(impulseVector, true);
        if (invInertia1 > 0) {
          this.body1.applyAngularImpulse(r1, impulseVector, true);
        }
      }
      if (invMass2 > 0) {
        this.body2.applyLinearImpulse(impulseVector.scale(-1), true);
        if (invInertia2 > 0) {
          this.body2.applyAngularImpulse(r2, impulseVector.scale(-1), true);
        }
      }

      let appliedPositionCorrectionTo: Body[] = [];

      // Position correction for large penetrations
      if (penetration > this.tetherSlop) {
        const correction = direction.scale(
          ((penetration - this.tetherSlop) * this.tetherBaumgarteScale) /
            denominator
        );

        if (invMass1 > 0) {
          this.body1.addPositionCorrection(correction, true);
          appliedPositionCorrectionTo.push(this.body1);
        }
        if (invMass2 > 0) {
          this.body2.addPositionCorrection(correction.scale(-1), true);
          appliedPositionCorrectionTo.push(this.body2);
        }
      }

      return { appliedPositionCorrectionTo };
    }

    return { appliedPositionCorrectionTo: [] };
  }

  private calculatePIDForce(params: {
    dt: number;
    targetPos: Vector2D;
    mass1: number;
    mass2: number;
  }): Vector2D | null {
    const { dt, targetPos, mass1, mass2 } = params;

    // Skip if both bodies locked
    if (!isFinite(mass1) && !isFinite(mass2)) return null;

    // Get current state for PID calculation
    const currentPos = this.body2.getCenter();
    const error = targetPos.subtract(currentPos);

    let massForScale: number;
    // Use the finite mass for scale calculation, or a default if both are infinite
    if (isFinite(mass1) && isFinite(mass2)) {
      massForScale = Math.min(Math.sqrt(mass1), Math.sqrt(mass2));
    } else {
      massForScale = isFinite(mass2) ? mass2 : isFinite(mass1) ? mass1 : 1;
    }

    const massScale = Math.min(massForScale, 100000); // Cap the mass scale

    // Calculate PID forces
    const proportionalForce = error.scale(this.pdParams.Kp * massScale);
    if (!proportionalForce.isValid()) {
      console.warn("Invalid proportional force calculated");
      return null;
    }

    // Integral term
    const integralDelta = error.scale(dt * this.pdParams.Ki * massScale);
    if (integralDelta.isValid()) {
      this.integral = this.integral.add(integralDelta);
      const integralMagnitude = this.integral.magnitude();
      if (integralMagnitude > this.pdParams.windupLimit) {
        this.integral = this.integral
          .normalize()
          .scale(this.pdParams.windupLimit);
      }
    }

    // Derivative term (damping)
    let derivativeTerm = new Vector2D(0, 0);
    if (this.lastError) {
      const errorDelta = error.subtract(this.lastError);
      const derivativeScale = (this.pdParams.Kd * massScale) / dt;
      if (isFinite(derivativeScale)) {
        derivativeTerm = errorDelta.scale(derivativeScale);
      }
    }
    this.lastError = error;

    // Calculate total force
    const force = proportionalForce.add(this.integral).add(derivativeTerm);
    if (!force.isValid()) {
      console.warn("Invalid force calculated");
      return null;
    }

    // Just apply max force limit without extension scaling
    const forceMagnitude = force.magnitude();
    if (forceMagnitude > this.maxForce) {
      return force.normalize().scale(this.maxForce);
    }

    return force;
  }

  private applyForcesToBodies(force: Vector2D): void {
    // Apply linear forces and torques
    const body1Force = force.scale(-1);
    this.body1.applyForce(body1Force, undefined, true);
    this.body2.applyForce(force, undefined, true);
  }

  private applyTapImpulse(): void {
    // Skip if second body is grabbing
    if (this.isGrabbing[ActuatorBody.Second]) {
      return;
    }

    // Get positions of both bodies
    const pos1 = this.body1.getCenter();
    const pos2 = this.body2.getCenter();

    // Calculate direction from body2 to body1
    const direction = pos1.subtract(pos2).normalize().scale(-1);
    const impulseVector = direction.scale(this.thrustStrength);

    // Calculate moment arm (from center of mass to point of force application)
    const r1 = pos1.subtract(this.body1.getCenter(true));

    // Apply both linear and angular impulses
    this.body1.applyLinearImpulse(impulseVector, true);
    if (!this.body1.rotationLocked) {
      this.body1.applyAngularImpulse(r1, impulseVector, true);
    }
  }

  // This should return a unit vector similar to what we use above
  getDirection(): Vector2D {
    const pos1 = this.body1.getCenter();
    const pos2 = this.body2.getCenter();
    return pos1.subtract(pos2).normalize();
  }

  requestImpulse(): void {
    this.impulseRequested = true;
  }

  cancelImpulse(): void {
    this.impulseRequested = false;
  }

  private handleImpulseRequest(now: number): void {
    if (
      this.impulseRequested &&
      now - this.lastImpulseTime >= this.thrustCooldown
    ) {
      this.applyTapImpulse();
      this.lastImpulseTime = now;
    }
  }

  applyForces(params: { plane: Plane; dt: number; now: number }): {
    appliedPositionCorrectionTo: Body[];
  } {
    const { plane, dt, now } = params;

    // Handle any pending impulse requests
    this.handleImpulseRequest(now);

    // Rest of existing force application
    this.updateGrab(plane);

    const mass1 = this.body1.getMass(true);
    const mass2 = this.body2.getMass(true);

    const pidForce = this.calculatePIDForce({
      dt,
      targetPos: this.targetPos,
      mass1,
      mass2,
    });

    if (pidForce?.isValid()) {
      this.applyForcesToBodies(pidForce);
    }

    return this.applyTetherConstraint();
  }

  render(camera: Camera, ctx: CanvasRenderingContext2D): void {
    const rootPos = this.body1.getCenter();
    const targetPos = this.body2.getCenter();
    this.ccdLimb.update(rootPos, targetPos);
    this.ccdLimb.render(ctx, camera);
  }
}
