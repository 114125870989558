import type { Vector2D } from "../math/vector2D";

export interface BodyProperties {
  centroidPosition: [number, number];
  momentOfInertia: number;
  mass: number;
  velocity: [number, number];
  angularVelocity: number;
}

export class Composite {
  centroidPosition: [number, number];
  rotation: number;
  velocity: [number, number];
  angularVelocity: number;
  acceleration: [number, number];
  angularAcceleration: number;
  mass: number;
  momentOfInertia: number;
  positionLocked: boolean;
  rotationLocked: boolean;
  numPositionLocked: number;
  numRotationLocked: number;
  bodies: Map<
    number,
    {
      offset: [number, number];
      offsetRotation: number;
    }
  >;

  positionCorrections: Vector2D[] = [];

  constructor({
    initialBodyIndex,
    initialBody,
    initialRotation,
    initialPositionLocked,
    initialRotationLocked,
  }: {
    initialBodyIndex: number;
    initialBody: BodyProperties;
    initialRotation: number;
    initialPositionLocked: boolean;
    initialRotationLocked: boolean;
  }) {
    this.centroidPosition = initialBody.centroidPosition;
    this.rotation = initialRotation;
    this.velocity = initialBody.velocity;
    this.angularVelocity = initialBody.angularVelocity;
    this.acceleration = [0, 0];
    this.angularAcceleration = 0;
    this.mass = initialBody.mass;
    this.momentOfInertia = initialBody.momentOfInertia;
    this.bodies = new Map([
      [
        initialBodyIndex,
        {
          offset: [0, 0],
          offsetRotation: 0,
        },
      ],
    ]);
    this.positionLocked = initialPositionLocked;
    this.rotationLocked = initialRotationLocked;
    this.numPositionLocked = initialPositionLocked ? 1 : 0;
    this.numRotationLocked = initialRotationLocked ? 1 : 0;
  }

  update(dt: number, gravity: number) {
    this.velocity[0] += this.positionLocked ? 0 : this.acceleration[0] * dt;
    this.velocity[1] += this.positionLocked ? 0 : this.acceleration[1] * dt;
    this.centroidPosition[0] += this.velocity[0] * dt;
    this.centroidPosition[1] += this.velocity[1] * dt;

    this.angularVelocity += this.rotationLocked
      ? 0
      : this.angularAcceleration * dt;
    this.rotation += this.angularVelocity * dt;

    this.acceleration[0] = 0;
    this.acceleration[1] = gravity;
    this.angularAcceleration = 0;
  }

  addPositionCorrection(correction: Vector2D) {
    this.positionCorrections.push(correction);
  }

  applyPositionCorrections() {
    if (this.positionCorrections.length === 0) {
      return;
    }

    // Calculate average correction
    const avgCorrection = this.positionCorrections.reduce(
      (sum, correction) => ({
        x: sum.x + correction.x,
        y: sum.y + correction.y,
      }),
      { x: 0, y: 0 }
    );

    // Apply the averaged correction
    this.centroidPosition[0] +=
      avgCorrection.x / this.positionCorrections.length / this.mass;
    this.centroidPosition[1] +=
      avgCorrection.y / this.positionCorrections.length / this.mass;

    this.positionCorrections = [];
  }

  applyForce(force: [number, number], applyAt: [number, number]) {
    if (!this.positionLocked) {
      const linearAccX = force[0] / this.mass;
      const linearAccY = force[1] / this.mass;
      this.acceleration[0] += linearAccX;
      this.acceleration[1] += linearAccY;
    }

    if (!this.rotationLocked) {
      const rX = applyAt[0] - this.centroidPosition[0];
      const rY = applyAt[1] - this.centroidPosition[1];
      const torque = rX * force[1] - rY * force[0];
      this.angularAcceleration += torque / this.momentOfInertia;
    }
  }

  applyImpulse(impulse: [number, number], applyAt: [number, number]) {
    if (!this.positionLocked) {
      const deltaVx = impulse[0] / this.mass;
      const deltaVy = impulse[1] / this.mass;
      this.velocity[0] += deltaVx;
      this.velocity[1] += deltaVy;
    }

    if (!this.rotationLocked) {
      const rX = applyAt[0] - this.centroidPosition[0];
      const rY = applyAt[1] - this.centroidPosition[1];
      const angularImpulse = rX * impulse[1] - rY * impulse[0];
      this.angularVelocity += angularImpulse / this.momentOfInertia;
    }
  }

  applyLinearImpulse(impulse: [number, number]) {
    if (!this.positionLocked) {
      this.velocity[0] += impulse[0] / this.mass;
      this.velocity[1] += impulse[1] / this.mass;
    }
  }

  addBody({
    index,
    properties,
    rotation,
    positionLocked,
    rotationLocked,
  }: {
    index: number;
    properties: BodyProperties;
    rotation: number;
    positionLocked: boolean;
    rotationLocked: boolean;
  }) {
    const [Cx, Cy] = this.centroidPosition;
    const [x, y] = properties.centroidPosition;

    const dx = x - Cx;
    const dy = y - Cy;

    const cosTheta = Math.cos(-this.rotation);
    const sinTheta = Math.sin(-this.rotation);
    const offset: [number, number] = [
      dx * cosTheta - dy * sinTheta,
      dx * sinTheta + dy * cosTheta,
    ];

    const offsetRotation = rotation - this.rotation;

    this.bodies.set(index, {
      offset,
      offsetRotation,
    });

    if (positionLocked) {
      this.numPositionLocked++;
      this.positionLocked = true;
      this.velocity = [0, 0];
    }

    if (rotationLocked) {
      this.numRotationLocked++;
      this.rotationLocked = true;
      this.angularVelocity = 0;
    }

    const { centroidPositionDelta } = this.updateCompositeAdd(properties);

    this.updateOffsets(centroidPositionDelta);
  }

  removeBody({
    index,
    properties,
    positionLocked,
    rotationLocked,
  }: {
    index: number;
    properties: BodyProperties;
    positionLocked: boolean;
    rotationLocked: boolean;
  }): BodyProperties {
    if (this.bodies.size === 1) {
      return {
        ...properties,
        velocity: this.velocity,
        angularVelocity: this.angularVelocity,
      };
    }

    if (positionLocked) {
      this.numPositionLocked--;

      if (this.numPositionLocked === 0) {
        this.positionLocked = false;
      }
    }

    if (rotationLocked) {
      this.numRotationLocked--;

      if (this.numRotationLocked === 0) {
        this.rotationLocked = false;
      }
    }

    const { centroidPositionDelta, removedBody } =
      this.updateCompositeRemove(properties);

    this.bodies.delete(index);

    this.updateOffsets(centroidPositionDelta);

    if (!removedBody) {
      throw new Error("Removed body not found");
    }

    return removedBody;
  }

  updateOffsets(centroidPositionDelta: [number, number]) {
    const cosTheta = Math.cos(-this.rotation);
    const sinTheta = Math.sin(-this.rotation);
    const rotatedDelta: [number, number] = [
      centroidPositionDelta[0] * cosTheta - centroidPositionDelta[1] * sinTheta,
      centroidPositionDelta[0] * sinTheta + centroidPositionDelta[1] * cosTheta,
    ];

    this.bodies.forEach((body) => {
      const { offset } = body;
      body.offset = [offset[0] - rotatedDelta[0], offset[1] - rotatedDelta[1]];
    });
  }

  updateCompositeAdd(body: BodyProperties): {
    centroidPositionDelta: [number, number];
  } {
    const [Cx, Cy] = this.centroidPosition;
    const {
      mass,
      centroidPosition: [x, y],
      momentOfInertia,
      velocity: [bodyVx, bodyVy],
      angularVelocity: bodyAngularVelocity,
    } = body;

    // Step 1: Update mass
    const newMass = this.mass + mass;

    // Step 2: Update centroid
    const newCx = (this.mass * Cx + mass * x) / newMass;
    const newCy = (this.mass * Cy + mass * y) / newMass;

    // Step 3: Update moment of inertia
    const deltaCx = newCx - Cx;
    const deltaCy = newCy - Cy;
    const deltaInertia = this.mass * (deltaCx ** 2 + deltaCy ** 2);
    const bodyInertiaContribution =
      momentOfInertia + mass * ((x - newCx) ** 2 + (y - newCy) ** 2);
    const newMomentOfInertia =
      this.momentOfInertia + bodyInertiaContribution + deltaInertia;

    // Step 4: Update velocities
    // Linear momentum conservation
    const [vx, vy] = this.velocity;
    const newVelocity: [number, number] = [
      (this.mass * vx + mass * bodyVx) / newMass,
      (this.mass * vy + mass * bodyVy) / newMass,
    ];

    // Angular momentum conservation
    // 1. Spin angular momentum
    const spinAngularMomentum =
      this.momentOfInertia * this.angularVelocity +
      momentOfInertia * bodyAngularVelocity;

    // 2. Orbital angular momentum
    const compositeOrbitalMoment =
      this.mass * ((Cx - newCx) * vy - (Cy - newCy) * vx);
    const bodyOrbitalMoment =
      mass * ((x - newCx) * bodyVy - (y - newCy) * bodyVx);

    const totalAngularMomentum =
      spinAngularMomentum + compositeOrbitalMoment + bodyOrbitalMoment;
    const newAngularVelocity = totalAngularMomentum / newMomentOfInertia;

    this.centroidPosition = [newCx, newCy];
    this.momentOfInertia = newMomentOfInertia;
    this.mass = newMass;
    this.velocity = this.positionLocked ? [0, 0] : newVelocity;
    this.angularVelocity = this.rotationLocked ? 0 : newAngularVelocity;

    return {
      centroidPositionDelta: [deltaCx, deltaCy],
    };
  }

  updateCompositeRemove(body: BodyProperties): {
    centroidPositionDelta: [number, number];
    removedBody: BodyProperties;
  } {
    // Early exit for last body
    if (this.bodies.size === 1) {
      return {
        centroidPositionDelta: [0, 0],
        removedBody: {
          ...body,
          velocity: this.velocity,
          angularVelocity: this.angularVelocity,
        },
      };
    }

    const [Cx, Cy] = this.centroidPosition;
    const {
      mass,
      centroidPosition: [x, y],
      momentOfInertia,
    } = body;

    // Step 1: Update mass and centroid
    const newMass = this.mass - mass;
    const newCx = (this.mass * Cx - mass * x) / newMass;
    const newCy = (this.mass * Cy - mass * y) / newMass;

    // Step 2: Update moment of inertia
    const deltaCx = newCx - Cx;
    const deltaCy = newCy - Cy;
    const deltaInertia = this.mass * (deltaCx ** 2 + deltaCy ** 2);
    const bodyInertiaContribution =
      momentOfInertia + mass * ((x - newCx) ** 2 + (y - newCy) ** 2);
    const newMomentOfInertia =
      this.momentOfInertia - bodyInertiaContribution + deltaInertia;

    // Step 3: Calculate positions relative to centers
    const rX = x - Cx;
    const rY = y - Cy;

    // Step 4: Calculate velocities at separation point
    if (!this.positionLocked) {
      // Calculate removed body velocity
      const removedBodyVelocity: [number, number] = [
        this.velocity[0] - this.angularVelocity * rY,
        this.velocity[1] + this.angularVelocity * rX,
      ];

      // Calculate new composite velocity using conservation of momentum
      const newVelocity: [number, number] = [
        (this.mass * this.velocity[0] - mass * removedBodyVelocity[0]) /
          newMass,
        (this.mass * this.velocity[1] - mass * removedBodyVelocity[1]) /
          newMass,
      ];

      // Update ALL composite properties
      this.centroidPosition = [newCx, newCy];
      this.velocity = newVelocity;
      this.mass = newMass;
      this.momentOfInertia = newMomentOfInertia;

      return {
        centroidPositionDelta: [deltaCx, deltaCy],
        removedBody: {
          ...body,
          velocity: removedBodyVelocity,
          angularVelocity: this.rotationLocked ? 0 : this.angularVelocity,
        },
      };
    }

    // Step 5: Update remaining composite properties
    this.centroidPosition = [newCx, newCy];
    this.momentOfInertia = newMomentOfInertia;
    this.mass = newMass;

    return {
      centroidPositionDelta: [deltaCx, deltaCy],
      removedBody: {
        ...body,
        velocity: this.positionLocked
          ? [0, 0]
          : [
              this.velocity[0] - this.angularVelocity * rY,
              this.velocity[1] + this.angularVelocity * rX,
            ],
        angularVelocity: this.rotationLocked ? 0 : this.angularVelocity,
      },
    };
  }

  getBodyPositionsAndRotations(): {
    index: number;
    position: [number, number];
    rotation: number;
  }[] {
    const cosTheta = Math.cos(this.rotation);
    const sinTheta = Math.sin(this.rotation);

    return Array.from(this.bodies.entries()).map(([index, body]) => {
      const { offset, offsetRotation } = body;

      // Match the old implementation's rotation direction
      const position: [number, number] = [
        this.centroidPosition[0] + offset[0] * cosTheta - offset[1] * sinTheta,
        this.centroidPosition[1] + offset[0] * sinTheta + offset[1] * cosTheta,
      ];

      return {
        index,
        position,
        rotation: this.rotation + offsetRotation,
      };
    });
  }

  mergeComposite(source: Composite) {
    // Step 1: Get global positions and rotations of source composite bodies
    const sourceBodyPositions = source.getBodyPositionsAndRotations();

    // Step 2: Calculate new offsets for each body in the source composite
    const cosTheta = Math.cos(-this.rotation);
    const sinTheta = Math.sin(-this.rotation);

    sourceBodyPositions.forEach(({ index, position, rotation }) => {
      const [globalX, globalY] = position;
      const [Cx, Cy] = this.centroidPosition;

      // Calculate the offset from the current composite's centroid
      const dx = globalX - Cx;
      const dy = globalY - Cy;

      // Rotate the offset to align with the current composite's local coordinates
      const offset: [number, number] = [
        dx * cosTheta - dy * sinTheta,
        dx * sinTheta + dy * cosTheta,
      ];

      // Calculate the rotation difference
      const offsetRotation = rotation - this.rotation;

      // Add the body to the current composite's map
      this.bodies.set(index, {
        offset,
        offsetRotation,
      });
    });

    // Step 3: Update mass properties
    const newMass = this.mass + source.mass;
    const [Cx, Cy] = this.centroidPosition;
    const [sourceCx, sourceCy] = source.centroidPosition;

    // Calculate new centroid position
    const newCx = (this.mass * Cx + source.mass * sourceCx) / newMass;
    const newCy = (this.mass * Cy + source.mass * sourceCy) / newMass;

    // Calculate moment of inertia using parallel axis theorem
    const deltaCx = newCx - Cx;
    const deltaCy = newCy - Cy;
    const deltaInertia = this.mass * (deltaCx ** 2 + deltaCy ** 2);
    const sourceDeltaInertia =
      source.mass * ((sourceCx - newCx) ** 2 + (sourceCy - newCy) ** 2);
    const newMomentOfInertia =
      this.momentOfInertia +
      source.momentOfInertia +
      deltaInertia +
      sourceDeltaInertia;

    // Update velocities using conservation of momentum
    const [vx, vy] = this.velocity;
    const [sourceVx, sourceVy] = source.velocity;
    const newVelocity: [number, number] = [
      (this.mass * vx + source.mass * sourceVx) / newMass,
      (this.mass * vy + source.mass * sourceVy) / newMass,
    ];

    // Update angular velocity using conservation of angular momentum
    const spinAngularMomentum =
      this.momentOfInertia * this.angularVelocity +
      source.momentOfInertia * source.angularVelocity;
    const compositeOrbitalMoment =
      this.mass * ((Cx - newCx) * vy - (Cy - newCy) * vx);
    const sourceOrbitalMoment =
      source.mass *
      ((sourceCx - newCx) * sourceVy - (sourceCy - newCy) * sourceVx);
    const totalAngularMomentum =
      spinAngularMomentum + compositeOrbitalMoment + sourceOrbitalMoment;
    const newAngularVelocity = totalAngularMomentum / newMomentOfInertia;

    // Step 4: Update locked states
    this.numPositionLocked += source.numPositionLocked;
    this.numRotationLocked += source.numRotationLocked;
    this.positionLocked = this.positionLocked || source.positionLocked;
    this.rotationLocked = this.rotationLocked || source.rotationLocked;

    // Step 5: Update composite properties
    this.centroidPosition = [newCx, newCy];
    this.momentOfInertia = newMomentOfInertia;
    this.mass = newMass;
    this.velocity = this.positionLocked ? [0, 0] : newVelocity;
    this.angularVelocity = this.rotationLocked ? 0 : newAngularVelocity;

    // Step 6: Update all offsets
    this.updateOffsets([deltaCx, deltaCy]);
  }
}
