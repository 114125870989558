import { Renderer } from "../rendering/renderer";
import { BrowserTiming } from "../plane/timing";
import { PlanarBase } from "./planarBase";
import type { Player } from "../plane/player";
import { Vector2D } from "../math/vector2D";
import { BodyOffset } from "../plane/plane";
import { ActuatorBody } from "../plane/actuator";
import { keyMap } from "../models";
import { VertexShapeBuilder } from "../shapes/shapeBuilder";
import { Body } from "../plane/body";
import { nanoid } from "nanoid";

export class PlanarLocal extends PlanarBase {
  private canvas: HTMLCanvasElement;
  private renderer: Renderer;
  private timing: BrowserTiming;
  private lastUpdateTime: number;
  private animationFrameId: number | null;
  private maxFrameTime: number = 0.25;
  private player: Player;
  wheelZoomEnabled: boolean = true;
  pinchZoomEnabled: boolean = true;
  private lastPinchDistance: number | null = null;
  shootDown: boolean = false;

  constructor(canvas: HTMLCanvasElement) {
    super({ id: "local", maxBodies: 10000 });
    this.canvas = canvas;
    this.renderer = new Renderer(canvas, this.plane);
    this.timing = new BrowserTiming();
    this.lastUpdateTime = 0;
    this.animationFrameId = null;
    this.player = this.addNewPlayer(window.innerWidth, window.innerHeight);
    this.setupListeners();
  }

  start(): void {
    this.lastUpdateTime = this.timing.now();

    const animate = () => {
      this.update();
      this.animationFrameId = requestAnimationFrame(animate);
    };
    this.animationFrameId = requestAnimationFrame(animate);
  }

  stop(): void {
    if (this.animationFrameId !== null) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
  }

  update(): void {
    this.startMetric("frameTime");
    const now = this.timing.now();
    let deltaTime = (now - this.lastUpdateTime) / 1000; // Convert to seconds

    // Prevent spiral of death if frame takes too long
    deltaTime = Math.min(deltaTime, this.maxFrameTime);

    this.startMetric("physicsUpdate");
    this.plane.update(deltaTime, now, this.playerCache.values());
    this.endMetric("physicsUpdate");

    this.startMetric("render");
    this.renderer.render(this.player, this.getMetricsReport(this.player));
    this.endMetric("render");

    this.lastUpdateTime = now;
    this.endMetric("frameTime");
  }

  setupListeners(): void {
    this.canvas.addEventListener("mousedown", (e) => {
      if (e.button === 2) {
        // Right click
        this.handleRightMouseDown();
      } else {
        // Left click
        this.handleMouseDown();
      }
    });
    this.canvas.addEventListener("mouseup", (e) => {
      if (e.button === 2) {
        // Right click
        this.handleRightMouseUp();
      } else {
        // Left click
        this.handleMouseUp();
      }
    });
    this.canvas.addEventListener("mousemove", (e) =>
      this.handleMouseMove([e.clientX, e.clientY])
    );
    this.canvas.addEventListener("wheel", (e) => this.handleWheel(e.deltaY));
    window.addEventListener("keydown", (e) => this.handleKeyDown(e.key));
    window.addEventListener("keyup", (e) => this.handleKeyUp(e.key));
    window.addEventListener("resize", () =>
      this.handleResize([window.innerWidth, window.innerHeight])
    );
    globalThis.addEventListener("touchstart", (e) =>
      this.handleTouchStart([
        [e.touches[0].clientX, e.touches[0].clientY],
        [e.touches[1].clientX, e.touches[1].clientY],
      ])
    );
    globalThis.addEventListener("touchmove", (e) =>
      this.handleTouchMove([
        [e.touches[0].clientX, e.touches[0].clientY],
        [e.touches[1].clientX, e.touches[1].clientY],
      ])
    );
    globalThis.addEventListener("touchend", (e) => this.handleTouchEnd());
    globalThis.addEventListener("touchcancel", (e) => this.handleTouchEnd());
    this.canvas.addEventListener("contextmenu", (e) => {
      e.preventDefault(); // Prevent the default context menu
    });
  }

  handleMouseDown(): void {
    this.player.mouseDown = true;
    this.player.actuator.setGrabbing(ActuatorBody.Second, true);
  }

  handleMouseUp(): void {
    this.player.mouseDown = false;
    this.player.actuator.setGrabbing(ActuatorBody.Second, false);
  }

  handleMouseMove(point: [number, number]): void {
    this.player.screenMousePosition.x = point[0];
    this.player.screenMousePosition.y = point[1];
  }

  handleKeyDown(key: string): void {
    this.player.keysDown[key] = true;

    if (keyMap.armGrab.includes(key)) {
      this.player.actuator.setGrabbing(ActuatorBody.Second, true);
    } else if (keyMap.bodyGrab.includes(key)) {
      this.player.actuator.setGrabbing(ActuatorBody.First, true);
    } else if (keyMap.thrust.includes(key)) {
      this.player.actuator.requestImpulse();
    } else if (keyMap.action.includes(key)) {
      this.player.actuator.body2.afterCollisionTriggers = [];
      this.player.actuator.body2.beforeCollisionTriggers = [];
      this.player.actuator.body2.afterCollisionTriggers.push((input) => {
        this.plane.cutBody(input.otherIndex, input.thisIndex);
      });
      this.player.actuator.body2.beforeCollisionTriggers.push((input) => {
        return false;
      });
    } else if (keyMap.shoot.includes(key)) {
      this.shootDown = true;

      const projectileShape = VertexShapeBuilder.circle(50);

      const projectileBody = new Body({
        id: nanoid(),
        shape: projectileShape,
        material: {
          density: 1,
          restitution: 1,
          staticFriction: 0,
          dynamicFriction: 0,
          color: "red",
        },
        positionLocked: false,
        rotationLocked: false,
      });

      const armBody = this.player.actuator.body2;

      const direction = this.player.actuator.getDirection();

      const linearVelocity = direction.scale(-10000);

      const armBodyVelocity = armBody.getLinearVelocity(true);

      const position = armBody.getCenter().add(direction.scale(-20));

      const momentum = linearVelocity.scale(projectileBody.mass);

      const impulseOnArmBody = momentum.scale(-0.15);

      const finalLinearVelocity = armBodyVelocity.add(linearVelocity);

      armBody.applyLinearImpulse(impulseOnArmBody, true);

      projectileBody.afterCollisionTriggers.push((input) => {
        const bodies = this.plane.cutCircle(
          input.intersectionInfo.centroid,
          650,
          input.otherIndex
        );

        const impulseMagnitude = 500000;
        const angularImpulseMagnitude = 10000;

        // Apply impulse to each body
        for (const body of bodies) {
          // The impulse vector is between the centroid of the cut and the center of the body
          const r = input.intersectionInfo.centroid.subtract(body.getCenter());
          const impulse = r.scale(-impulseMagnitude);
          body.applyLinearImpulse(impulse, true);
          const angularImpulse = finalLinearVelocity.scale(
            angularImpulseMagnitude
          );
          body.applyAngularImpulse(r, angularImpulse);
        }

        // Delete projectile after it collides
        this.plane.unloadBody(projectileBody.index);
      });

      this.addAndLoadBody({
        body: projectileBody,
        state: {
          position: [position.x, position.y, 0],
          velocity: [finalLinearVelocity.x, finalLinearVelocity.y, 0],
        },
      });

      setTimeout(() => {
        if (projectileBody.isLoaded()) {
          this.plane.unloadBody(projectileBody.index);
        }
      }, 10000);
    }
  }

  handleKeyUp(key: string): void {
    this.player.keysDown[key] = false;

    if (keyMap.armGrab.includes(key)) {
      this.player.actuator.setGrabbing(ActuatorBody.Second, false);
    } else if (keyMap.bodyGrab.includes(key)) {
      this.player.actuator.setGrabbing(ActuatorBody.First, false);
    } else if (keyMap.thrust.includes(key)) {
      this.player.actuator.cancelImpulse();
    } else if (keyMap.action.includes(key)) {
      this.player.actuator.body2.afterCollisionTriggers = [];
      this.player.actuator.body2.beforeCollisionTriggers = [];
    } else if (keyMap.shoot.includes(key)) {
      this.shootDown = false;
    }
  }

  handleResize(size: [number, number]): void {
    this.player.camera.updateSize(size[0], size[1]);
  }

  handleWheel(deltaY: number): void {
    if (!this.wheelZoomEnabled) return;

    const zoomFactor = Math.pow(0.999, deltaY);
    this.player.camera.zoomBy(zoomFactor);
  }

  handleTouchStart(touches: [[number, number], [number, number]]): void {
    this.lastPinchDistance = Math.hypot(
      touches[1][0] - touches[0][0],
      touches[1][1] - touches[0][1]
    );
  }

  handleTouchMove(touches: [[number, number], [number, number]]): void {
    if (this.pinchZoomEnabled && this.lastPinchDistance !== null) {
      const currentDistance = Math.hypot(
        touches[1][0] - touches[0][0],
        touches[1][1] - touches[0][1]
      );

      const zoomFactor = currentDistance / this.lastPinchDistance;
      this.player.camera.zoomBy(zoomFactor);

      this.lastPinchDistance = currentDistance;
    }
  }

  handleTouchEnd(): void {
    this.lastPinchDistance = null;
  }

  handleRightMouseDown(): void {
    this.player.rightMouseDown = true;
    this.player.actuator.body2.afterCollisionTriggers = [];
    this.player.actuator.body2.beforeCollisionTriggers = [];
    this.player.actuator.body2.afterCollisionTriggers.push((input) => {
      this.plane.cutBody(input.otherIndex, input.thisIndex);
    });
    this.player.actuator.body2.beforeCollisionTriggers.push((input) => {
      return false;
    });
  }

  handleRightMouseUp(): void {
    this.player.rightMouseDown = false;
    this.player.actuator.body2.afterCollisionTriggers = [];
    this.player.actuator.body2.beforeCollisionTriggers = [];
  }
}
