import { Vector2D } from "./vector2D.ts";

export class Transform {
  x: number;
  y: number;
  angle: number;
  sin: number;
  cos: number;

  constructor(x: number, y: number, angle: number) {
    this.x = x;
    this.y = y;
    this.angle = angle;
    this.sin = Math.sin(angle);
    this.cos = Math.cos(angle);
  }

  apply(point: Vector2D): Vector2D {
    return new Vector2D(
      this.x + this.cos * point.x - this.sin * point.y,
      this.y + this.sin * point.x + this.cos * point.y
    );
  }

  applyInPlace(point: Vector2D, target: Vector2D): void {
    target.x = this.x + this.cos * point.x - this.sin * point.y;
    target.y = this.y + this.sin * point.x + this.cos * point.y;
  }

  applyToArray(point: [number, number]): [number, number] {
    return [
      this.x + this.cos * point[0] - this.sin * point[1],
      this.y + this.sin * point[0] + this.cos * point[1],
    ];
  }

  applyInverse(point: Vector2D): Vector2D {
    const subtractedX = point.x - this.x;
    const subtractedY = point.y - this.y;
    return new Vector2D(
      subtractedX * this.cos + subtractedY * this.sin,
      subtractedY * this.cos - subtractedX * this.sin
    );
  }

  static identity(): Transform {
    return new Transform(0, 0, 0);
  }
}
