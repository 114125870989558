import type { Transform } from "../../math/transform.ts";
import { normalizeAngle, TWO_PI } from "../../math/utils.ts";
import { Vector2D } from "../../math/vector2D.ts";
import type { Camera } from "../../plane/camera.ts";
import {
  type Segment,
  SegmentType,
  type CentroidAndAreaContribution,
  type SerializedCircleSegment,
  type LineIntersection,
} from "../../models.ts";
import { ArcSegment } from "./arcSegment.ts";
import type { Circle } from "../../plane/quadtree.ts";
import type { QuadBounds } from "../../plane/segmentQuadtree.ts";

export class CircleSegment implements Segment {
  type: SegmentType = SegmentType.CIRCLE;
  localCenter: Vector2D;
  radius: number;
  center: Vector2D;
  clockwise: boolean;
  area: number;

  constructor(center: Vector2D, radius: number, clockwise: boolean = false) {
    this.localCenter = center;
    this.radius = radius;
    this.center = new Vector2D(0, 0);
    this.clockwise = clockwise;
    this.area = clockwise
      ? -Math.PI * radius * radius
      : Math.PI * radius * radius;
  }

  static fromSerialized(serialized: SerializedCircleSegment): CircleSegment {
    return new CircleSegment(
      new Vector2D(serialized.center[0], serialized.center[1]),
      Math.abs(serialized.radius),
      serialized.radius < 0
    );
  }

  updateGlobal(transform: Transform): void {
    transform.applyInPlace(this.localCenter, this.center);
  }

  calculateCentralAngleFromAngles(
    startAngle: number,
    endAngle: number,
    clockwise: boolean
  ): number {
    let centralAngle: number;
    if (!clockwise) {
      if (startAngle < endAngle) {
        centralAngle = endAngle - startAngle;
      } else {
        centralAngle = TWO_PI - startAngle + endAngle;
      }
    } else {
      if (startAngle > endAngle) {
        centralAngle = startAngle - endAngle;
      } else {
        centralAngle = TWO_PI - endAngle + startAngle;
      }
    }

    return normalizeAngle(centralAngle);
  }

  globalClone(
    invert: boolean,
    tValues?: [[number, number], [Vector2D, Vector2D]]
  ): Segment {
    if (tValues) {
      // For circles, t values are angles in radians
      const [[t1, t2], [start, end]] = tValues;

      if (t1 < 0 || t1 > TWO_PI || t2 < 0 || t2 > TWO_PI) {
        throw new Error("t values must be between 0 and 2π");
      }

      if (start === null || end === null) {
        throw new Error("start and end must be defined on circle segments");
      }

      if (!invert) {
        const centralAngle = this.calculateCentralAngleFromAngles(
          t1,
          t2,
          this.clockwise
        );

        const segment = new ArcSegment(
          start,
          end,
          {
            center: this.center.clone(),
            radius: this.radius,
            startAngle: t1,
            centralAngle: this.clockwise ? -centralAngle : centralAngle,
          },
          false
        );
        return segment;
      } else {
        const centralAngle = this.calculateCentralAngleFromAngles(
          t2,
          t1,
          !this.clockwise
        );

        const segment = new ArcSegment(
          end,
          start,
          {
            center: this.center.clone(),
            radius: this.radius,
            startAngle: t2,
            centralAngle: this.clockwise ? -centralAngle : centralAngle,
          },
          false
        );
        return segment;
      }
    } else {
      if (!invert) {
        return new CircleSegment(
          this.center.clone(),
          this.radius,
          this.clockwise
        );
      } else {
        return new CircleSegment(
          this.center.clone(),
          this.radius,
          !this.clockwise
        );
      }
    }
  }

  clone(invert: boolean): Segment {
    if (!invert) {
      return new CircleSegment(
        this.localCenter.clone(),
        this.radius,
        this.clockwise
      );
    } else {
      return new CircleSegment(
        this.localCenter.clone(),
        this.radius,
        !this.clockwise
      );
    }
  }

  serialize(): SerializedCircleSegment {
    return {
      type: SegmentType.CIRCLE,
      center: this.localCenter.toArray(),
      radius: this.clockwise ? -this.radius : this.radius,
    };
  }

  translate(dx: number, dy: number): void {
    this.localCenter.x += dx;
    this.localCenter.y += dy;
  }

  getCentroidAndAreaContribution(local: boolean): CentroidAndAreaContribution {
    // For a circle, moment of inertia about its center is (1/2)mr²
    const secondMomentOfArea = 0.5 * this.area * this.radius * this.radius;

    return {
      area: this.area,
      centroid: local ? this.localCenter : this.center,
      compositeSecondMomentOfArea: secondMomentOfArea,
    };
  }

  getLineIntersection(
    lineStart: Vector2D,
    lineEnd: Vector2D,
    local: boolean
  ): LineIntersection[] {
    const center = local ? this.localCenter : this.center;

    // Convert line to parametric form: p = p0 + t(p1-p0)
    const dx = lineEnd.x - lineStart.x;
    const dy = lineEnd.y - lineStart.y;

    // Solve quadratic equation for intersection
    const a = dx * dx + dy * dy;
    const b =
      2 * (dx * (lineStart.x - center.x) + dy * (lineStart.y - center.y));
    const c =
      center.x * center.x +
      center.y * center.y +
      lineStart.x * lineStart.x +
      lineStart.y * lineStart.y -
      2 * (center.x * lineStart.x + center.y * lineStart.y) -
      this.radius * this.radius;

    const discriminant = b * b - 4 * a * c;

    if (discriminant < 0) {
      return []; // No intersections
    }

    const intersections: LineIntersection[] = [];
    const sqrtDiscriminant = Math.sqrt(discriminant);

    const t1 = (-b + sqrtDiscriminant) / (2 * a);
    const t2 = (-b - sqrtDiscriminant) / (2 * a);

    const point1 = new Vector2D(lineStart.x + t1 * dx, lineStart.y + t1 * dy);
    const point2 = new Vector2D(lineStart.x + t2 * dx, lineStart.y + t2 * dy);

    if (!point1.isValid() || !point2.isValid()) {
      return [];
    }

    intersections.push({
      point: point1,
      t: t1,
      segment: this,
    });

    intersections.push({
      point: point2,
      t: t2,
      segment: this,
    });

    return intersections;
  }

  rayIntersectionCount(point: Vector2D, local: boolean): number {
    const centerX = local ? this.localCenter.x : this.center.x;
    const centerY = local ? this.localCenter.y : this.center.y;

    // Early exit if the ray is too far above or below the circle
    const dy = point.y - centerY;
    if (Math.abs(dy) > this.radius) {
      return 0;
    }

    // Calculate the x-coordinates of intersections using the circle equation
    const dx = Math.sqrt(this.radius * this.radius - dy * dy);
    const x1 = centerX - dx;
    const x2 = centerX + dx;

    let count = 0;

    if (x1 >= point.x) {
      count++;
    }
    if (x2 >= point.x) {
      count++;
    }

    return count;
  }

  addToPath(camera: Camera, path: Path2D): void {
    const [centerX, centerY] = camera.worldToScreen(
      this.center.x,
      this.center.y
    );
    const radius = camera.worldToScreenDistance(this.radius);
    path.arc(centerX, centerY, radius, 0, TWO_PI, this.clockwise);
  }

  intersectsBounds(bounds: QuadBounds): boolean {
    // Check if circle is too far left, right, top, or bottom
    if (
      this.localCenter.x + this.radius < bounds.left ||
      this.localCenter.x - this.radius > bounds.right ||
      this.localCenter.y + this.radius < bounds.top ||
      this.localCenter.y - this.radius > bounds.bottom
    ) {
      return false;
    }

    // Check if circle contains any corner of the bounds
    const radiusSquared = this.radius * this.radius;
    const corners = [
      { x: bounds.left, y: bounds.top },
      { x: bounds.right, y: bounds.top },
      { x: bounds.left, y: bounds.bottom },
      { x: bounds.right, y: bounds.bottom },
    ];

    for (const corner of corners) {
      const dx = corner.x - this.localCenter.x;
      const dy = corner.y - this.localCenter.y;
      if (dx * dx + dy * dy <= radiusSquared) {
        return true;
      }
    }

    // If circle center is inside bounds, it must intersect
    if (
      this.localCenter.x >= bounds.left &&
      this.localCenter.x <= bounds.right &&
      this.localCenter.y >= bounds.top &&
      this.localCenter.y <= bounds.bottom
    ) {
      return true;
    }

    // Check if circle intersects any edge of the bounds
    // Only need to check if we haven't already found an intersection
    return (
      this.intersectsHorizontalLine(bounds.top, bounds.left, bounds.right) ||
      this.intersectsHorizontalLine(bounds.bottom, bounds.left, bounds.right) ||
      this.intersectsVerticalLine(bounds.left, bounds.top, bounds.bottom) ||
      this.intersectsVerticalLine(bounds.right, bounds.top, bounds.bottom)
    );
  }

  private intersectsHorizontalLine(
    y: number,
    left: number,
    right: number
  ): boolean {
    // If circle is too far above or below the line, no intersection
    if (Math.abs(y - this.localCenter.y) > this.radius) {
      return false;
    }

    // Calculate x-coordinates of intersection points
    const dy = y - this.localCenter.y;
    const dx = Math.sqrt(this.radius * this.radius - dy * dy);
    const x1 = this.localCenter.x - dx;
    const x2 = this.localCenter.x + dx;

    // Check if either intersection point lies within the line segment
    return (x1 >= left && x1 <= right) || (x2 >= left && x2 <= right);
  }

  private intersectsVerticalLine(
    x: number,
    top: number,
    bottom: number
  ): boolean {
    // If circle is too far left or right of the line, no intersection
    if (Math.abs(x - this.localCenter.x) > this.radius) {
      return false;
    }

    // Calculate y-coordinates of intersection points
    const dx = x - this.localCenter.x;
    const dy = Math.sqrt(this.radius * this.radius - dx * dx);
    const y1 = this.localCenter.y - dy;
    const y2 = this.localCenter.y + dy;

    // Check if either intersection point lies within the line segment
    return (y1 >= top && y1 <= bottom) || (y2 >= top && y2 <= bottom);
  }

  intersectsCircle(circle: Circle): boolean {
    // Calculate distance between centers
    const dx = circle.x - this.localCenter.x;
    const dy = circle.y - this.localCenter.y;
    const distanceSquared = dx * dx + dy * dy;

    // Circles intersect if distance between centers is less than sum of radii
    const radiusSum = this.radius + circle.radius;
    return distanceSquared <= radiusSum * radiusSum;
  }
}
