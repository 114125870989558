import PartySocket from "partysocket";
import { Renderer } from "../rendering/renderer.ts";
import {
  ClientMessageType,
  type InitializePlayerMessage,
  type NetworkState,
  type NetworkStateEntry,
} from "../models.ts";
import { BrowserTiming } from "../plane/timing.ts";
import { parsePacket } from "./packets.ts";
import { PlanarBase } from "./planarBase.ts";
import { Actuator } from "../plane/actuator.ts";
import type { Player } from "../plane/player.ts";
import { Body } from "../plane/body.ts";

export class PlanarClient extends PlanarBase {
  private host: string;
  private room: string;
  private canvas: HTMLCanvasElement;
  private renderer: Renderer;
  private socket: PartySocket;
  private cursorX: number;
  private cursorY: number;
  private lastTouchX: number | null = null;
  private lastTouchY: number | null = null;
  private timing: BrowserTiming;
  private lastUpdateTime: number;
  private animationFrameId: number | null = null;
  private maxFrameTime: number = 0.25;
  private networkStates: NetworkState[] = [];
  private networkStateBufferSize: number = 10;
  private player: Player | null = null;

  constructor({
    maxBodies,
    canvas,
    host,
    room,
  }: {
    maxBodies: number;
    canvas: HTMLCanvasElement;
    host: string;
    room: string;
  }) {
    super({ id: "client", maxBodies });
    this.canvas = canvas;
    this.host = host;
    this.room = room;
    this.socket = new PartySocket({ host, room, startClosed: true });
    this.socket.binaryType = "arraybuffer";
    this.renderer = new Renderer(canvas, this.plane);
    this.timing = new BrowserTiming();
    this.lastUpdateTime = 0;
    this.animationFrameId = null;

    this.cursorX = canvas.width / 2;
    this.cursorY = canvas.height / 2;

    this.setupInteractionListeners();
    this.setupSocketMessageListener();
  }

  async initialize() {
    const response = await PartySocket.fetch(
      { host: this.host, room: this.room },
      {
        method: "GET",
      }
    );

    if (!response.ok) {
      throw new Error("Failed to get initial plane state");
    }

    const planeData = (await response.json()) as InitializePlayerMessage;
    this.initializeFromMessage(planeData);
    this.setupListeners();

    this.socket.updateProperties({
      id: planeData.key,
    });
    this.socket.reconnect();

    // On socket connection, send a resize message to the server
    this.socket.onopen = () => {
      this.sendToServer(
        JSON.stringify({
          type: ClientMessageType.Resize,
          data: [window.innerWidth, window.innerHeight],
        })
      );
    };
  }

  initializeFromMessage(message: InitializePlayerMessage): void {
    const { actuatorId, bodies, actuators } = message;

    for (let i = 0; i < bodies.length; i++) {
      this.addAndLoadBody({
        body: Body.fromSerialized(bodies[i]),
        state: {
          position: [0, 0, 0],
          velocity: [0, 0, 0],
        },
      });
    }

    for (let i = 0; i < actuators.length; i++) {
      const body1 = this.bodyCache.get(actuators[i].body1Id);
      const body2 = this.bodyCache.get(actuators[i].body2Id);

      if (!body1 || !body2) {
        throw new Error("Actuator bodies not found");
      }

      const actuator = new Actuator(
        actuators[i].id,
        body1,
        body2,
        actuators[i].config
      );

      this.addAndLoadActuator(actuator);
    }

    this.player = this.addNewPlayer(
      window.innerWidth,
      window.innerHeight,
      actuatorId
    );
  }

  async start(): Promise<void> {
    await this.initialize();
    this.lastUpdateTime = this.timing.now();

    const animate = () => {
      this.update();
      this.animationFrameId = requestAnimationFrame(animate);
    };
    this.animationFrameId = requestAnimationFrame(animate);
  }

  stop(): void {
    if (this.animationFrameId !== null) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
  }

  update(): void {
    const now = this.timing.now();
    const latestState = this.networkStates[this.networkStates.length - 1];

    if (latestState && !latestState.used) {
      this.unloadAllBut(latestState.entries.map((e) => e.id));
      this.loadFromNetworkState(latestState.entries);

      const stateAge = now - latestState.clientTimestamp;

      let deltaTime = (now - this.lastUpdateTime) / 1000; // Convert to seconds
      // Prevent spiral of death if frame takes too long
      deltaTime = Math.min(deltaTime, this.maxFrameTime);

      this.plane.interpolateUpdate(
        this.player!,
        latestState,
        stateAge,
        this.bodyIdToIndex,
        deltaTime
      );

      latestState.used = true;
    } else {
      let deltaTime = (now - this.lastUpdateTime) / 1000; // Convert to seconds
      // Prevent spiral of death if frame takes too long
      deltaTime = Math.min(deltaTime, this.maxFrameTime);
      this.plane.update(deltaTime, now, this.playerCache.values());
    }

    this.renderer.render(this.player!, this.getMetricsReport(this.player!));

    this.lastUpdateTime = now;
  }

  handleNetworkStateUpdate(serializedState: ArrayBuffer): void {
    const { ids, states, dt } = parsePacket(serializedState);

    // Convert flat array into structured entries
    const entries: NetworkStateEntry[] = [];
    for (let i = 0; i < ids.length; i++) {
      entries.push({
        id: ids[i],
        position: [states[i * 6], states[i * 6 + 1], states[i * 6 + 2]],
        velocity: [states[i * 6 + 3], states[i * 6 + 4], states[i * 6 + 5]],
      });
    }

    this.addNetworkState({
      clientTimestamp: this.timing.now(),
      dt,
      entries,
      used: false,
    });
  }

  addNetworkState(state: NetworkState): void {
    this.networkStates.push(state);
    while (this.networkStates.length > this.networkStateBufferSize) {
      this.networkStates.shift();
    }
  }

  setupSocketMessageListener() {
    this.socket.onmessage = (e) => {
      this.handleNetworkStateUpdate(e.data);
    };
  }

  setupInteractionListeners() {
    this.canvas.addEventListener("mousedown", () =>
      this.sendToServer(JSON.stringify({ type: ClientMessageType.MouseDown }))
    );
    this.canvas.addEventListener("mousemove", (e) =>
      this.sendToServer(
        JSON.stringify({
          type: ClientMessageType.MouseMove,
          data: [e.clientX, e.clientY],
        })
      )
    );
    this.canvas.addEventListener("pointerup", () =>
      this.sendToServer(JSON.stringify({ type: ClientMessageType.MouseUp }))
    );
    this.canvas.addEventListener("mouseleave", () =>
      this.sendToServer(JSON.stringify({ type: ClientMessageType.MouseUp }))
    );

    // Add keyboard event listener
    globalThis.addEventListener("keydown", (e) =>
      this.sendToServer(
        JSON.stringify({ type: ClientMessageType.KeyDown, data: e.key })
      )
    );
    globalThis.addEventListener("keyup", (e) =>
      this.sendToServer(
        JSON.stringify({ type: ClientMessageType.KeyUp, data: e.key })
      )
    );

    globalThis.addEventListener("resize", () => {
      this.canvas.width = globalThis.innerWidth;
      this.canvas.height = globalThis.innerHeight;
      this.sendToServer(
        JSON.stringify({
          type: ClientMessageType.Resize,
          data: [this.canvas.width, this.canvas.height],
        })
      );
    });

    // Add these new event listeners
    this.canvas.addEventListener("wheel", (e) => {
      e.preventDefault();
      this.sendToServer(
        JSON.stringify({
          type: ClientMessageType.Wheel,
          data: e.deltaY,
        })
      );
    });

    let touchStartedOnButton = false;

    this.canvas.addEventListener("touchstart", (e) => {
      // Check if this specific touch started on a button
      if ((e.target as Element).closest(".mobile-controls")) {
        touchStartedOnButton = true;
        return;
      }

      e.preventDefault();
      if (e.touches.length === 1) {
        // Always allow single-touch cursor movement, even if a button is held
        this.lastTouchX = e.touches[0].clientX;
        this.lastTouchY = e.touches[0].clientY;
      } else if (e.touches.length === 2 && !touchStartedOnButton) {
        // Only handle zoom if no touch started on button
        this.sendToServer(
          JSON.stringify({
            type: ClientMessageType.TouchStart,
            data: [
              [e.touches[0].clientX, e.touches[0].clientY],
              [e.touches[1].clientX, e.touches[1].clientY],
            ],
          })
        );
      }
    });

    this.canvas.addEventListener("touchmove", (e) => {
      // Don't return early for button touches anymore
      // Instead, find the first touch that's not on a button
      let movementTouch: Touch | null = null;

      for (let i = 0; i < e.touches.length; i++) {
        const touch = e.touches[i];
        const touchTarget = document.elementFromPoint(
          touch.clientX,
          touch.clientY
        );
        if (!touchTarget?.closest(".mobile-controls")) {
          movementTouch = touch;
          break;
        }
      }

      e.preventDefault();
      if (
        movementTouch &&
        this.lastTouchX !== null &&
        this.lastTouchY !== null
      ) {
        const deltaX = movementTouch.clientX - this.lastTouchX;
        const deltaY = movementTouch.clientY - this.lastTouchY;

        this.cursorX = Math.max(
          0,
          Math.min(this.canvas.width, this.cursorX + deltaX)
        );
        this.cursorY = Math.max(
          0,
          Math.min(this.canvas.height, this.cursorY + deltaY)
        );

        this.lastTouchX = movementTouch.clientX;
        this.lastTouchY = movementTouch.clientY;

        this.sendToServer(
          JSON.stringify({
            type: ClientMessageType.MouseMove,
            data: [this.cursorX, this.cursorY],
          })
        );
      } else if (e.touches.length === 2 && !touchStartedOnButton) {
        // Only handle zoom if no touch started on button
        this.sendToServer(
          JSON.stringify({
            type: ClientMessageType.TouchMove,
            data: [
              [e.touches[0].clientX, e.touches[0].clientY],
              [e.touches[1].clientX, e.touches[1].clientY],
            ],
          })
        );
      }
    });

    this.canvas.addEventListener("touchend", (e) => {
      if ((e.target as Element).closest(".mobile-controls")) {
        return;
      }

      e.preventDefault();
      if (e.touches.length === 0) {
        this.lastTouchX = null;
        this.lastTouchY = null;
        touchStartedOnButton = false; // Reset the flag when all touches end
      }
      this.sendToServer(
        JSON.stringify({
          type: ClientMessageType.TouchEnd,
        })
      );
    });

    this.canvas.addEventListener("touchcancel", (e) => {
      e.preventDefault();
      this.lastTouchX = null;
      this.lastTouchY = null;
      touchStartedOnButton = false; // Reset the flag on touch cancel
      this.sendToServer(
        JSON.stringify({
          type: ClientMessageType.TouchEnd,
        })
      );
    });

    // Add mobile button handlers
    const mobileButtons = document.querySelectorAll(".mobile-button");
    mobileButtons.forEach((button) => {
      const key = (button as HTMLElement).dataset.key;
      if (!key) return;

      // Handle touch start (keydown)
      button.addEventListener("touchstart", (e) => {
        e.preventDefault();
        this.sendToServer(
          JSON.stringify({
            type: ClientMessageType.KeyDown,
            data: key,
          })
        );
      });

      // Handle touch end (keyup)
      button.addEventListener("touchend", (e) => {
        e.preventDefault();
        this.sendToServer(
          JSON.stringify({
            type: ClientMessageType.KeyUp,
            data: key,
          })
        );
      });

      // Prevent holding the button from triggering selection
      button.addEventListener("touchcancel", (e) => {
        e.preventDefault();
        this.sendToServer(
          JSON.stringify({
            type: ClientMessageType.KeyUp,
            data: key,
          })
        );
      });
    });
  }

  sendToServer(data: string) {
    this.socket.send(data);
  }

  setupListeners(): void {
    this.canvas.addEventListener("mousedown", () =>
      this.plane.handleMouseDown(this.player!)
    );
    this.canvas.addEventListener("mouseup", () =>
      this.plane.handleMouseUp(this.player!)
    );
    this.canvas.addEventListener("mousemove", (e) =>
      this.plane.handleMouseMove(this.player!, [e.clientX, e.clientY])
    );
    this.canvas.addEventListener("wheel", (e) =>
      this.plane.handleWheel(this.player!, e.deltaY)
    );
    window.addEventListener("keydown", (e) =>
      this.plane.handleKeyDown(this.player!, e.key)
    );
    window.addEventListener("keyup", (e) =>
      this.plane.handleKeyUp(this.player!, e.key)
    );
    window.addEventListener("resize", () =>
      this.plane.handleResize(this.player!, [
        window.innerWidth,
        window.innerHeight,
      ])
    );
    globalThis.addEventListener("touchstart", (e) =>
      this.plane.handleTouchStart(this.player!, [
        [e.touches[0].clientX, e.touches[0].clientY],
        [e.touches[1].clientX, e.touches[1].clientY],
      ])
    );
    globalThis.addEventListener("touchmove", (e) =>
      this.plane.handleTouchMove(this.player!, [
        [e.touches[0].clientX, e.touches[0].clientY],
        [e.touches[1].clientX, e.touches[1].clientY],
      ])
    );
    globalThis.addEventListener("touchend", (e) =>
      this.plane.handleTouchEnd(this.player!)
    );
    globalThis.addEventListener("touchcancel", (e) =>
      this.plane.handleTouchEnd(this.player!)
    );
  }
}
