import { Actuator } from "../plane/actuator.ts";
import type {
  BodyState,
  NetworkStateEntry,
  SerializedBodyData,
} from "../models.ts";
import { Plane } from "../plane/plane.ts";
import { VertexShapeBuilder } from "../shapes/shapeBuilder.ts";
import { Player } from "../plane/player.ts";
import {
  generateVariety,
  type VarietyGenerationParams,
} from "./planeInitUtil.ts";
import { Body } from "../plane/body.ts";

interface PerformanceMetric {
  name: string;
  lastTime: number;
  samples: number[];
  maxSamples: number;
  type: "time" | "numeric";
}

const METRIC_SAMPLE_SIZE = 60; // Store last 60 frames for averaging

const METRIC_NAMES = [
  "frameTime",
  "physicsUpdate",
  "quadtreeConstruction",
  "intersectMECs",
  "updateGlobalPositions",
  "findPossibleSegments",
  "intersectSegments",
  "findEnclosure",
  "buildIntersectionShapes",
  "resolve",
  "callBeforeCollisionTriggers",
  "callAfterCollisionTriggers",
  "applyPositionCorrections",
  "positionUpdate",
  "serialize",
  "getVisibleBodies",
  "render",
] as const;

const NUMERIC_METRIC_NAMES = [
  "mecCheckCount",
  "mecIntersectionCount",
  "unfilteredSegmentCheckCount",
  "segmentCheckCount",
  "percentSegmentsFiltered",
  "segmentIntersectionCount",
  "numCollisions",
] as const;

export abstract class PlanarBase {
  id: string;
  plane: Plane;
  bodyCache: Map<string, Body> = new Map();
  neverUnloadBodyIds: Set<string> = new Set();
  actuatorCache: Map<string, Actuator> = new Map();
  playerCache: Map<string, Player> = new Map();
  bodyIdToIndex: Map<string, number> = new Map();
  actuatorIdToIndex: Map<string, number> = new Map();
  playerIdToIndex: Map<string, number> = new Map();
  nextBodyId = 0;
  nextActuatorId = 0;
  nextPlayerId = 0;
  metrics: Map<string, PerformanceMetric> = new Map();

  constructor({ id, maxBodies }: { id: string; maxBodies: number }) {
    this.id = id;
    this.plane = new Plane({
      maxBodies,
      startMetric: this.startMetric,
      endMetric: this.endMetric,
      recordNumericMetric: this.recordNumericMetric,
    });
    this.initializeMetrics([...METRIC_NAMES]);
    this.initializeMetrics([...NUMERIC_METRIC_NAMES], "numeric");
  }

  private initializeMetrics(
    metricNames: string[],
    type: "time" | "numeric" = "time"
  ): void {
    metricNames.forEach((name) => {
      this.metrics.set(name, {
        name,
        lastTime: 0,
        samples: [],
        maxSamples: METRIC_SAMPLE_SIZE,
        type,
      });
    });
  }

  startMetric = (name: string) => {
    const metric = this.metrics.get(name);
    if (metric) {
      metric.lastTime = performance.now();
    }
  };

  endMetric = (name: string, startTime?: number): void => {
    const metric = this.metrics.get(name);
    if (metric) {
      const duration = performance.now() - (startTime ?? metric.lastTime);
      metric.samples.push(duration);
      if (metric.samples.length > metric.maxSamples) {
        metric.samples.shift();
      }
    }
  };

  recordNumericMetric = (name: string, value: number): void => {
    const metric = this.metrics.get(name);
    if (metric && metric.type === "numeric") {
      metric.samples.push(value);
      if (metric.samples.length > metric.maxSamples) {
        metric.samples.shift();
      }
    }
  };

  getMetricsReport(player: Player): string {
    let report = "";

    // Add FPS at the top of the report
    const fps = this.calculateFPS();
    report += `FPS: ${fps.toFixed(1)}\n`;

    // Add camera position
    report += `Camera: (${player.camera.x.toFixed(
      1
    )}, ${player.camera.y.toFixed(1)})\n`;
    report += `Zoom: ${player.camera.zoom.toFixed(2)}x\n`;

    // Add shape counts
    report += `Number of Bodies: ${this.plane.numBodies}\n`;

    // Add next index
    report += `Next Body Index: ${this.plane.nextIndex}\n`;

    // Add existing metrics
    this.metrics.forEach((metric) => {
      if (metric.samples.length > 0) {
        const avg =
          metric.samples.reduce((a, b) => a + b, 0) / metric.samples.length;
        report += `${metric.name}: ${avg.toFixed(2)}${
          metric.type === "time" ? "ms" : ""
        }\n`;
      }
    });
    return report;
  }

  private calculateFPS(): number {
    const frameTimeMetric = this.metrics.get("frameTime");
    if (!frameTimeMetric || frameTimeMetric.samples.length === 0) {
      return 0;
    }

    // Calculate average frame time from recent samples
    const avgFrameTime =
      frameTimeMetric.samples.reduce((a, b) => a + b, 0) /
      frameTimeMetric.samples.length;
    return 1000 / avgFrameTime; // Convert ms/frame to frames/second
  }

  getNextBodyId(): string {
    return `${this.id}-${this.nextBodyId++}`;
  }

  getNextActuatorId(): string {
    return `${this.id}-${this.nextActuatorId++}`;
  }

  getNextPlayerId(): string {
    return `${this.id}-${this.nextPlayerId++}`;
  }

  addBody(body: Body): void {
    this.bodyCache.set(body.id, body);
  }

  loadBody(id: string, state: BodyState): number {
    const body = this.bodyCache.get(id);
    if (body === undefined) {
      throw new Error("Body not found");
    }

    const currentIndex = this.bodyIdToIndex.get(body.id);
    if (currentIndex !== undefined) {
      return currentIndex;
    }

    const index = this.plane.loadBody({ body, state });
    this.bodyIdToIndex.set(body.id, index);
    return index;
  }

  unloadBody(id: string): void {
    if (this.neverUnloadBodyIds.has(id)) {
      return;
    }

    this.plane.unloadBody(this.bodyIdToIndex.get(id)!);
    this.bodyIdToIndex.delete(id);
  }

  addAndLoadBody({ body, state }: { body: Body; state: BodyState }): number {
    const currentIndex = this.bodyIdToIndex.get(body.id);
    if (currentIndex !== undefined) {
      return currentIndex;
    }

    this.bodyCache.set(body.id, body);
    const index = this.plane.loadBody({ body, state });
    this.bodyIdToIndex.set(body.id, index);
    return index;
  }

  addActuator(actuator: Actuator): void {
    this.actuatorCache.set(actuator.id, actuator);
  }

  loadActuator(id: string): number {
    const actuator = this.actuatorCache.get(id);
    if (actuator === undefined) {
      throw new Error("Actuator not found");
    }

    if (!actuator.body1.isLoaded() || !actuator.body2.isLoaded()) {
      throw new Error("Actuator bodies not loaded");
    }

    const actuatorIndex = this.plane.loadActuator(actuator);
    this.actuatorIdToIndex.set(actuator.id, actuatorIndex);

    return actuatorIndex;
  }

  addAndLoadActuator(actuator: Actuator): number {
    this.actuatorCache.set(actuator.id, actuator);
    return this.loadActuator(actuator.id);
  }

  addNewPlayer(
    width: number,
    height: number,
    givenActuatorId?: string
  ): Player {
    if (givenActuatorId !== undefined) {
      const actuator = this.actuatorCache.get(givenActuatorId);
      if (actuator === undefined) {
        throw new Error("Actuator not found");
      }

      const player = new Player(
        this.getNextPlayerId(),
        width,
        height,
        actuator
      );
      this.playerCache.set(player.id, player);
      return player;
    }

    const body1Id = this.getNextBodyId();

    const body1 = new Body({
      id: body1Id,
      shape: VertexShapeBuilder.regularPolygon(150, 6),
      material: {
        density: 0.5,
        restitution: 0.1,
        staticFriction: 0.9,
        dynamicFriction: 0.9,
        color: "rgb(50, 100, 50)",
      },
      positionLocked: false,
      rotationLocked: false,
    });

    this.addAndLoadBody({
      body: body1,
      state: {
        position: [0, 0, 0],
        velocity: [10, 10, 0.001],
      },
    });
    this.neverUnloadBodyIds.add(body1Id);
    const body2Id = this.getNextBodyId();

    const body2 = new Body({
      id: body2Id,
      shape: VertexShapeBuilder.circle(40),
      material: {
        density: 0.3,
        restitution: 0.2,
        staticFriction: 100,
        dynamicFriction: 100,
        color: "rgb(50, 100, 50)",
      },
      positionLocked: false,
      rotationLocked: true,
    });

    body2.deferRotationLock = true;

    this.addAndLoadBody({
      body: body2,
      state: {
        position: [0, 0, 0],
        velocity: [10, 10, 0.001],
      },
    });

    this.neverUnloadBodyIds.add(body2Id);
    const actuatorId = this.getNextActuatorId();
    const actuator = new Actuator(actuatorId, body1, body2);

    this.addAndLoadActuator(actuator);

    const player = new Player(this.getNextPlayerId(), width, height, actuator);
    this.playerCache.set(player.id, player);
    return player;
  }

  unloadAllBut(ids: string[]): void {
    for (const id of this.bodyIdToIndex.keys()) {
      if (!ids.includes(id)) {
        this.unloadBody(id);
      }
    }
  }

  loadFromNetworkState(entries: NetworkStateEntry[]): void {
    for (let i = 0; i < entries.length; i++) {
      this.loadBody(entries[i].id, {
        position: entries[i].position,
        velocity: entries[i].velocity,
      });
    }
  }

  generateVariety(params: VarietyGenerationParams): void {
    const bodies = generateVariety(params);
    for (const body of bodies) {
      this.addAndLoadBody(body);
    }
  }
}
