import { Vector2D } from "../math/vector2D.ts";
import {
  type SerializedIntersectionShape,
  type SerializedActuatorData,
  type SerializedBodyData,
  type BodyState,
  type NetworkState,
  CollisionPairKey,
  type CollisionPair,
  type DragInfo,
  type GrabInfo,
  type Timer,
} from "../models.ts";
import { Player } from "./player.ts";
import { Transform } from "../math/transform.ts";
import {
  findShapeEnclosure,
  findShapeIntersections,
} from "../geometry/intersection.ts";
import type { IntersectionShape } from "../models.ts";
import { Actuator } from "./actuator.ts";
import { QuadTree } from "./quadtree.ts";
import { Body } from "./body.ts";
import { Composite } from "./composite.ts";
import { intersectionOrUnionShapes } from "../geometry/buildResultShape.ts";
import { VertexShapeBuilder } from "../shapes/shapeBuilder.ts";

export enum BodyOffset {
  PositionX = 0,
  PositionY = 1,
  Rotation = 2,
  VelocityX = 3,
  VelocityY = 4,
  AngularVelocity = 5,
  AccelerationX = 6,
  AccelerationY = 7,
  AngularAcceleration = 8,
  LocalMecPositionX = 9,
  LocalMecPositionY = 10,
  MecPositionX = 11,
  MecPositionY = 12,
  MecRadius = 13,
}

export class Plane {
  currentFrame: number = 0;
  readonly bodyDataStride: number = 14;
  nextIndex: number = 0;
  numBodies: number = 0;
  bodies: (Body | undefined)[];
  bodyStates: Float32Array;
  freeBodyIndices: number[] = [];
  actuators: Actuator[] = [];
  compositeBodies: Composite[] = [];

  private timers: Map<number, Timer>;
  private nextTimerId: number;
  booleanResults: IntersectionShape[] = [];
  sendingArray: Float32Array;
  startMetric: (name: string) => void;
  endMetric: (name: string) => void;
  recordNumericMetric: (name: string, value: number) => void;

  velocityDamping: number = 1;
  angularVelocityDamping: number = 1;
  private grabInfo: GrabInfo | null = null;
  gravity: number = 0;
  dragInfo: DragInfo | null = null;
  private ignoredCollisionPairs: Set<string> = new Set();
  private worldBounds: { min: Vector2D; max: Vector2D };
  quadtree: QuadTree | null = null;

  constructor({
    maxBodies,
    startMetric,
    endMetric,
    recordNumericMetric,
  }: {
    maxBodies: number;
    startMetric: (name: string) => void;
    endMetric: (name: string) => void;
    recordNumericMetric: (name: string, value: number) => void;
  }) {
    this.bodies = new Array(maxBodies);
    this.bodyStates = new Float32Array(maxBodies * this.bodyDataStride);
    this.timers = new Map();
    this.nextTimerId = 0;
    this.sendingArray = new Float32Array(maxBodies * this.bodyDataStride);
    this.startMetric = startMetric;
    this.endMetric = endMetric;
    this.recordNumericMetric = recordNumericMetric;
    this.worldBounds = {
      min: new Vector2D(-1000000, -1000000),
      max: new Vector2D(1000000, 1000000),
    };
  }

  getBody(index: number): Body | undefined {
    return this.bodies[index];
  }

  getBodyData(index: number): SerializedBodyData | undefined {
    const body = this.getBody(index);
    if (!body) {
      return undefined;
    }
    return {
      id: body.id,
      shape: body.shape.serialize(),
      material: body.material,
      positionLocked: body.positionLocked,
      rotationLocked: body.rotationLocked,
    };
  }

  serializeAllBodies(): SerializedBodyData[] {
    const bodyStates: SerializedBodyData[] = [];
    for (let i = 0; i < this.nextIndex; i++) {
      const bodyData = this.getBodyData(i);
      if (bodyData) {
        bodyStates.push(bodyData);
      }
    }
    return bodyStates;
  }

  serializeAllActuators(): SerializedActuatorData[] {
    const actuators: SerializedActuatorData[] = [];
    for (const actuator of this.actuators) {
      actuators.push(actuator.getData());
    }
    return actuators;
  }

  getStateForPlayer(player: Player): {
    data: Float32Array;
    numElements: number;
    visibleBodyIds: string[];
  } {
    const STATE_DATA_LENGTH = 6;
    const visibleBodyIndices = this.getVisibleBodyIndices(player, 500);
    const visibleBodyIds: string[] = [];

    // Pre-validate array size
    if (
      visibleBodyIndices.length * STATE_DATA_LENGTH >
      this.sendingArray.length
    ) {
      throw new Error(
        `Sending array too small: need ${
          visibleBodyIndices.length * STATE_DATA_LENGTH
        }, have ${this.sendingArray.length}`
      );
    }

    for (let i = 0; i < visibleBodyIndices.length; i++) {
      const bodyIndex = visibleBodyIndices[i];
      const body = this.getBody(bodyIndex);
      if (!body) {
        continue;
      }
      visibleBodyIds.push(body.id);

      const sourceIndex = bodyIndex * this.bodyDataStride;
      const targetIndex = i * STATE_DATA_LENGTH;

      this.sendingArray[targetIndex] =
        this.bodyStates[sourceIndex + BodyOffset.PositionX];
      this.sendingArray[targetIndex + 1] =
        this.bodyStates[sourceIndex + BodyOffset.PositionY];
      this.sendingArray[targetIndex + 2] =
        this.bodyStates[sourceIndex + BodyOffset.Rotation];
      this.sendingArray[targetIndex + 3] =
        this.bodyStates[sourceIndex + BodyOffset.VelocityX];
      this.sendingArray[targetIndex + 4] =
        this.bodyStates[sourceIndex + BodyOffset.VelocityY];
      this.sendingArray[targetIndex + 5] =
        this.bodyStates[sourceIndex + BodyOffset.AngularVelocity];
    }

    const numElements = visibleBodyIds.length * STATE_DATA_LENGTH;

    return {
      data: this.sendingArray,
      numElements,
      visibleBodyIds,
    };
  }

  serializeBooleanResults(): string {
    const shapes: SerializedIntersectionShape[] = [];
    for (const shape of this.booleanResults) {
      shapes.push({
        shape: shape.shape.serialize(),
        centroid: [shape.centroid.x, shape.centroid.y],
        normal: shape.normal ? [shape.normal.x, shape.normal.y] : null,
        penetrationDistance: shape.penetrationDistance,
        penetrationVector: shape.penetrationVector
          ? [shape.penetrationVector.x, shape.penetrationVector.y]
          : null,
      });
    }
    return JSON.stringify(shapes);
  }

  loadBody({ body, state }: { body: Body; state: BodyState }): number {
    // Use a free index if available, otherwise use the current count
    const index =
      this.freeBodyIndices.length > 0
        ? this.freeBodyIndices.pop()!
        : this.nextIndex++;

    body.index = index;
    body.strideIndex = index * this.bodyDataStride;
    body.stateArray = this.bodyStates;

    this.bodies[index] = body;
    this.bodyStates[body.strideIndex + BodyOffset.PositionX] =
      state.position[0];
    this.bodyStates[body.strideIndex + BodyOffset.PositionY] =
      state.position[1];
    this.bodyStates[body.strideIndex + BodyOffset.Rotation] = state.position[2];
    this.bodyStates[body.strideIndex + BodyOffset.VelocityX] =
      state.velocity[0];
    this.bodyStates[body.strideIndex + BodyOffset.VelocityY] =
      state.velocity[1];
    this.bodyStates[body.strideIndex + BodyOffset.AngularVelocity] =
      state.velocity[2];
    this.bodyStates[body.strideIndex + BodyOffset.AccelerationX] = 0;
    this.bodyStates[body.strideIndex + BodyOffset.AccelerationY] = 0;
    this.bodyStates[body.strideIndex + BodyOffset.AngularAcceleration] = 0;
    this.bodyStates[body.strideIndex + BodyOffset.LocalMecPositionX] =
      body.shape.localMec.center.x;
    this.bodyStates[body.strideIndex + BodyOffset.LocalMecPositionY] =
      body.shape.localMec.center.y;

    const cos = Math.cos(state.position[2]);
    const sin = Math.sin(state.position[2]);

    this.bodyStates[body.strideIndex + BodyOffset.MecPositionX] =
      body.shape.localMec.center.x * cos -
      body.shape.localMec.center.y * sin +
      state.position[0];
    this.bodyStates[body.strideIndex + BodyOffset.MecPositionY] =
      body.shape.localMec.center.x * sin +
      body.shape.localMec.center.y * cos +
      state.position[1];
    this.bodyStates[body.strideIndex + BodyOffset.MecRadius] =
      body.shape.localMec.radius;

    this.numBodies++;

    this.quadtree?.insert({
      index: body.index,
      x: this.bodyStates[body.strideIndex + BodyOffset.MecPositionX],
      y: this.bodyStates[body.strideIndex + BodyOffset.MecPositionY],
      radius: this.bodyStates[body.strideIndex + BodyOffset.MecRadius],
    });

    return index;
  }

  unloadBody(index: number): void {
    if (index < 0 || index >= this.nextIndex) {
      console.warn(`Invalid body index: ${index}`);
      return;
    }

    const body = this.bodies[index];

    if (!body) {
      console.error("Body not found");
      return;
    }

    // Clear the body data in the Float32Array
    const stride = index * this.bodyDataStride;
    for (let i = 0; i < this.bodyDataStride; i++) {
      this.bodyStates[stride + i] = 0;
    }

    // Remove from quadtree
    this.quadtree?.remove(index);

    body.index = -1;
    body.strideIndex = -1;
    body.stateArray = null;

    this.bodies[index] = undefined;

    // Add the index to the free list
    this.freeBodyIndices.push(index);

    // Remove any ignored collision pairs involving this body
    this.cleanupIgnoredCollisionPairs(index);
    this.numBodies--;
  }

  // Add this helper method to clean up collision pairs
  private cleanupIgnoredCollisionPairs(bodyIndex: number): void {
    // Create a new Set to store pairs we want to keep
    const updatedPairs = new Set<string>();

    // Only keep pairs that don't involve the removed body
    for (const pair of this.ignoredCollisionPairs) {
      const [body1, body2] = pair.split(",").map(Number);
      if (body1 !== bodyIndex && body2 !== bodyIndex) {
        updatedPairs.add(pair);
      }
    }

    this.ignoredCollisionPairs = updatedPairs;
  }

  loadActuator(actuator: Actuator): number {
    this.actuators.push(actuator);
    this.addIgnoredCollisionPair(actuator.body1.index, actuator.body2.index);
    return this.actuators.length - 1;
  }

  updateCompositeBodies(dt: number): void {
    for (const composite of this.compositeBodies) {
      composite.update(dt, this.gravity);

      const bodyStates = composite.getBodyPositionsAndRotations();
      for (const { index, position, rotation } of bodyStates) {
        this.bodyStates[index * this.bodyDataStride + BodyOffset.PositionX] =
          position[0];
        this.bodyStates[index * this.bodyDataStride + BodyOffset.PositionY] =
          position[1];
        this.bodyStates[index * this.bodyDataStride + BodyOffset.Rotation] =
          rotation;

        // Update MEC position
        const cos = Math.cos(rotation);
        const sin = Math.sin(rotation);
        const localMecX =
          this.bodyStates[
            index * this.bodyDataStride + BodyOffset.LocalMecPositionX
          ];
        const localMecY =
          this.bodyStates[
            index * this.bodyDataStride + BodyOffset.LocalMecPositionY
          ];
        this.bodyStates[index * this.bodyDataStride + BodyOffset.MecPositionX] =
          localMecX * cos -
          localMecY * sin +
          this.bodyStates[index * this.bodyDataStride + BodyOffset.PositionX];
        this.bodyStates[index * this.bodyDataStride + BodyOffset.MecPositionY] =
          localMecX * sin +
          localMecY * cos +
          this.bodyStates[index * this.bodyDataStride + BodyOffset.PositionY];

        const body = this.getBody(index);
        if (body) {
          body.shape.globalDirty = true;
        }
      }
    }
  }

  composeBodies(index1: number, index2: number): void {
    const body1 = this.getBody(index1);
    const body2 = this.getBody(index2);

    if (!body1 || !body2) {
      return;
    }

    const composite1 = body1.composite;
    const composite2 = body2.composite;

    // Case 1 & 2: Handle cases where at least one shape isn't in a composite
    if (!composite1 || !composite2 || composite1 === composite2) {
      if (!composite1 && !composite2) {
        const newComposite = new Composite({
          initialBodyIndex: index1,
          initialBody: {
            centroidPosition: [
              this.bodyStates[
                index1 * this.bodyDataStride + BodyOffset.PositionX
              ],
              this.bodyStates[
                index1 * this.bodyDataStride + BodyOffset.PositionY
              ],
            ],
            momentOfInertia: body1.momentOfInertia,
            mass: body1.mass,
            velocity: [
              this.bodyStates[
                index1 * this.bodyDataStride + BodyOffset.VelocityX
              ],
              this.bodyStates[
                index1 * this.bodyDataStride + BodyOffset.VelocityY
              ],
            ],
            angularVelocity:
              this.bodyStates[
                index1 * this.bodyDataStride + BodyOffset.AngularVelocity
              ],
          },
          initialRotation:
            this.bodyStates[index1 * this.bodyDataStride + BodyOffset.Rotation],
          initialPositionLocked: body1.positionLocked,
          initialRotationLocked: body1.deferRotationLock
            ? false
            : body1.rotationLocked,
        });

        this.compositeBodies.push(newComposite);
        body1.composite = newComposite;

        this.addBodyToComposite(index2, newComposite);
        return;
      } else if (composite1 && !composite2) {
        this.addBodyToComposite(index2, composite1);
        return;
      } else if (!composite1 && composite2) {
        this.addBodyToComposite(index1, composite2);
        return;
      } else {
        throw new Error("Should not happen");
      }
    }

    // Case 3: Handle merging two composites
    // Put all the indices of composite2 into an array for later
    const composite2Indices = Array.from(composite2.bodies.keys());

    composite1.mergeComposite(composite2);
    this.compositeBodies = this.compositeBodies.filter((c) => c !== composite2);
    for (const index of composite2Indices) {
      const body = this.getBody(index);
      if (body) {
        body.composite = composite1;
      }
    }
  }

  removeBodyFromComposite(bodyIndex: number): void {
    const body = this.getBody(bodyIndex);
    if (!body) {
      return;
    }
    const composite = body.composite;
    if (composite) {
      const removed = composite.removeBody({
        index: bodyIndex,
        properties: {
          centroidPosition: [
            this.bodyStates[
              bodyIndex * this.bodyDataStride + BodyOffset.PositionX
            ],
            this.bodyStates[
              bodyIndex * this.bodyDataStride + BodyOffset.PositionY
            ],
          ],
          momentOfInertia: body.momentOfInertia,
          mass: body.mass,
          velocity: [0, 0],
          angularVelocity: 0,
        },
        positionLocked: body.positionLocked,
        rotationLocked: body.deferRotationLock ? false : body.rotationLocked,
      });

      body.composite = null;

      this.bodyStates[bodyIndex * this.bodyDataStride + BodyOffset.VelocityX] =
        removed.velocity[0];
      this.bodyStates[bodyIndex * this.bodyDataStride + BodyOffset.VelocityY] =
        removed.velocity[1];
      this.bodyStates[
        bodyIndex * this.bodyDataStride + BodyOffset.AngularVelocity
      ] = removed.angularVelocity;

      // If there's only one body left in the composite, remove that body from the composite
      if (composite.bodies.size === 1) {
        const onlyBody = Array.from(composite.bodies.keys())[0];
        const onlyBodyBody = this.getBody(onlyBody);
        if (!onlyBodyBody) {
          return;
        }

        // Use removeBody() to properly calculate velocities for the last body
        const removed = composite.removeBody({
          index: onlyBody,
          properties: {
            centroidPosition: [
              this.bodyStates[
                onlyBody * this.bodyDataStride + BodyOffset.PositionX
              ],
              this.bodyStates[
                onlyBody * this.bodyDataStride + BodyOffset.PositionY
              ],
            ],
            momentOfInertia: onlyBodyBody.momentOfInertia,
            mass: onlyBodyBody.mass,
            velocity: [0, 0], // These will be calculated by removeBody()
            angularVelocity: 0, // This will be calculated by removeBody()
          },
          positionLocked: onlyBodyBody.positionLocked,
          rotationLocked: onlyBodyBody.rotationLocked,
        });

        // Update the body's velocities with properly calculated values
        this.bodyStates[onlyBody * this.bodyDataStride + BodyOffset.VelocityX] =
          removed.velocity[0];
        this.bodyStates[onlyBody * this.bodyDataStride + BodyOffset.VelocityY] =
          removed.velocity[1];
        this.bodyStates[
          onlyBody * this.bodyDataStride + BodyOffset.AngularVelocity
        ] = removed.angularVelocity;

        onlyBodyBody.composite = null;
        this.compositeBodies = this.compositeBodies.filter(
          (c) => c !== composite
        );
      }
    }
  }

  addBodyToComposite(bodyIndex: number, composite: Composite): void {
    const body = this.getBody(bodyIndex);
    if (!body) {
      return;
    }

    if (body.composite) {
      throw new Error("Body is already in a composite");
    }

    composite.addBody({
      index: bodyIndex,
      properties: {
        centroidPosition: [
          this.bodyStates[
            bodyIndex * this.bodyDataStride + BodyOffset.PositionX
          ],
          this.bodyStates[
            bodyIndex * this.bodyDataStride + BodyOffset.PositionY
          ],
        ],
        momentOfInertia: body.momentOfInertia,
        mass: body.mass,
        velocity: [
          this.bodyStates[
            bodyIndex * this.bodyDataStride + BodyOffset.VelocityX
          ],
          this.bodyStates[
            bodyIndex * this.bodyDataStride + BodyOffset.VelocityY
          ],
        ],
        angularVelocity:
          this.bodyStates[
            bodyIndex * this.bodyDataStride + BodyOffset.AngularVelocity
          ],
      },
      rotation:
        this.bodyStates[bodyIndex * this.bodyDataStride + BodyOffset.Rotation],
      positionLocked: body.positionLocked,
      rotationLocked: body.deferRotationLock ? false : body.rotationLocked,
    });

    body.composite = composite;
  }

  isBodyInComposite(bodyIndex: number, compositeIndex: number): boolean {
    const composite = this.getBody(bodyIndex)?.composite;
    if (!composite) {
      return false;
    }
    return composite.bodies.has(compositeIndex);
  }

  cutCircle(position: Vector2D, radius: number, targetIndex: number): Body[] {
    const cutShape = VertexShapeBuilder.circle(radius);
    cutShape.updateAllGlobal(position.x, position.y, 0, true);

    const target = this.getBody(targetIndex);
    if (!target) {
      return [];
    }

    const bodies = target.cut(cutShape, {
      x: position.x,
      y: position.y,
      radius,
      index: -1,
    });

    if (bodies.length === 0) {
      return [];
    }

    this.unloadBody(targetIndex);

    for (const body of bodies) {
      this.loadBody(body);
    }

    return bodies.map((b) => b.body);
  }

  cutBody(targetIndex: number, cutterIndex: number): void {
    const target = this.getBody(targetIndex);
    const cutter = this.getBody(cutterIndex);
    if (!target || !cutter) {
      return;
    }

    const bodies = target.cut(cutter.shape, cutter.getMec());

    if (bodies.length === 0) {
      return;
    }

    this.unloadBody(targetIndex);

    for (const body of bodies) {
      this.loadBody(body);
    }
  }

  interpolateUpdate(
    player: Player,
    latestState: NetworkState,
    stateAge: number,
    bodyIdToIndex: Map<string, number>,
    clientDt: number
  ): void {
    this.currentFrame++;
    const playerBody = player.actuator.body1;
    const playerBodyPosition = playerBody.getPosition();
    const playerBodyVelocity = playerBody.getVelocity();

    player.camera.update(
      playerBodyPosition[0],
      playerBodyPosition[1],
      Math.sqrt(
        playerBodyVelocity[0] * playerBodyVelocity[0] +
          playerBodyVelocity[1] * playerBodyVelocity[1]
      ),
      clientDt
    );
    player.updateWorldMousePosition();
    player.actuator.updateTargetPos(player.mousePosition);
    this.directManipulationUpdate(player);

    const dt = latestState.dt;
    const predictionDt = Math.max(0, Math.min(dt, stateAge / 1000));
    const bodyIndices: number[] = [];

    // Network interpolation tuning parameters
    const LINEAR_SPEED_THRESHOLD = 10000;
    const ANGULAR_SPEED_THRESHOLD = 10; // radians/second

    // Linear blend components
    const LINEAR_BLEND_BASE = 0.4; // Base blend amount (higher = more responsive but potentially jittery)
    const LINEAR_BLEND_RANGE = 0.6; // Additional blend based on speed (lower = less speed-based adjustment)
    const LINEAR_BLEND_MAX = 0.5; // Maximum blend factor (lower = smoother but more lag)

    // Angular blend components
    const ANGULAR_BLEND_BASE = 0.3;
    const ANGULAR_BLEND_RANGE = 0.5;
    const ANGULAR_BLEND_MAX = 0.4;

    for (const entry of latestState.entries) {
      const bodyIndex = bodyIdToIndex.get(entry.id);
      if (bodyIndex === undefined) {
        console.warn("could not find body index", entry.id);
        continue;
      }
      bodyIndices.push(bodyIndex);

      const body = this.getBody(bodyIndex);

      if (!body) {
        continue;
      }

      const targetIndex = bodyIndex * this.bodyDataStride;

      const predictedPos = {
        x: entry.position[0] + entry.velocity[0] * predictionDt,
        y: entry.position[1] + entry.velocity[1] * predictionDt,
        rotation: entry.position[2] + entry.velocity[2] * predictionDt,
      };

      // Get current position
      const currentPos = {
        x: this.bodyStates[targetIndex + BodyOffset.PositionX],
        y: this.bodyStates[targetIndex + BodyOffset.PositionY],
        rotation: this.bodyStates[targetIndex + BodyOffset.Rotation],
      };

      // Calculate separate blend factors for linear and angular motion
      const linearSpeed = Math.sqrt(
        entry.velocity[0] * entry.velocity[0] +
          entry.velocity[1] * entry.velocity[1]
      );
      const angularSpeed = Math.abs(entry.velocity[2]);

      const LINEAR_BLEND_FACTOR = Math.min(
        LINEAR_BLEND_MAX,
        LINEAR_BLEND_BASE +
          (linearSpeed / LINEAR_SPEED_THRESHOLD) * LINEAR_BLEND_RANGE
      );

      const ANGULAR_BLEND_FACTOR = Math.min(
        ANGULAR_BLEND_MAX,
        ANGULAR_BLEND_BASE +
          (angularSpeed / ANGULAR_SPEED_THRESHOLD) * ANGULAR_BLEND_RANGE
      );

      // Blend current position toward predicted position
      const newPos = {
        x: currentPos.x + (predictedPos.x - currentPos.x) * LINEAR_BLEND_FACTOR,
        y: currentPos.y + (predictedPos.y - currentPos.y) * LINEAR_BLEND_FACTOR,
        rotation:
          currentPos.rotation +
          (predictedPos.rotation - currentPos.rotation) * ANGULAR_BLEND_FACTOR,
      };

      // Update body state
      this.bodyStates[targetIndex + BodyOffset.PositionX] = newPos.x;
      this.bodyStates[targetIndex + BodyOffset.PositionY] = newPos.y;
      this.bodyStates[targetIndex + BodyOffset.Rotation] = newPos.rotation;

      // Also update velocities for next frame's prediction
      this.bodyStates[targetIndex + BodyOffset.VelocityX] = entry.velocity[0];
      this.bodyStates[targetIndex + BodyOffset.VelocityY] = entry.velocity[1];
      this.bodyStates[targetIndex + BodyOffset.AngularVelocity] =
        entry.velocity[2];

      // Update MEC position
      const cos = Math.cos(newPos.rotation);
      const sin = Math.sin(newPos.rotation);
      const localMecX =
        this.bodyStates[targetIndex + BodyOffset.LocalMecPositionX];
      const localMecY =
        this.bodyStates[targetIndex + BodyOffset.LocalMecPositionY];

      this.bodyStates[targetIndex + BodyOffset.MecPositionX] =
        localMecX * cos - localMecY * sin + currentPos.x;
      this.bodyStates[targetIndex + BodyOffset.MecPositionY] =
        localMecX * sin + localMecY * cos + currentPos.y;

      body.shape.globalDirty = true;
    }

    return;
  }

  update(
    dt: number,
    now: number,
    players: Iterable<Player>,
    dontResolveCollisions: boolean = false
  ): void {
    this.currentFrame++;
    for (const player of players) {
      const playerBody = player.actuator.body1;
      const playerBodyPosition = playerBody.getPosition();
      const playerBodyVelocity = playerBody.getVelocity();

      player.camera.update(
        playerBodyPosition[0],
        playerBodyPosition[1],
        Math.sqrt(
          playerBodyVelocity[0] * playerBodyVelocity[0] +
            playerBodyVelocity[1] * playerBodyVelocity[1]
        ),
        dt
      );
      player.updateWorldMousePosition();
      player.actuator.updateTargetPos(player.mousePosition);
      this.directManipulationUpdate(player);
    }

    this.startMetric("positionUpdate");
    this.updateCompositeBodies(dt);
    // Update velocities and positions with dt
    for (let i = 0; i < this.nextIndex; i++) {
      const body = this.getBody(i);
      if (!body) {
        continue;
      }

      if (body.composite) {
        continue;
      }

      const stride = i * this.bodyDataStride;

      if (!body.positionLocked) {
        this.bodyStates[stride + BodyOffset.VelocityX] +=
          this.bodyStates[stride + BodyOffset.AccelerationX] * dt;
        this.bodyStates[stride + BodyOffset.VelocityY] +=
          this.bodyStates[stride + BodyOffset.AccelerationY] * dt;
        this.bodyStates[stride + BodyOffset.VelocityX] *= this.velocityDamping;
        this.bodyStates[stride + BodyOffset.VelocityY] *= this.velocityDamping;
        this.bodyStates[stride + BodyOffset.PositionX] +=
          this.bodyStates[stride + BodyOffset.VelocityX] * dt;
        this.bodyStates[stride + BodyOffset.PositionY] +=
          this.bodyStates[stride + BodyOffset.VelocityY] * dt;
      }
      if (!body.rotationLocked) {
        this.bodyStates[stride + BodyOffset.AngularVelocity] +=
          this.bodyStates[stride + BodyOffset.AngularAcceleration] * dt;
        this.bodyStates[stride + BodyOffset.AngularVelocity] *=
          this.angularVelocityDamping;
        this.bodyStates[stride + BodyOffset.Rotation] +=
          this.bodyStates[stride + BodyOffset.AngularVelocity] * dt;
      }

      // Update MEC position based on new rotation
      const cos = Math.cos(this.bodyStates[stride + BodyOffset.Rotation]);
      const sin = Math.sin(this.bodyStates[stride + BodyOffset.Rotation]);
      const localMecX = this.bodyStates[stride + BodyOffset.LocalMecPositionX];
      const localMecY = this.bodyStates[stride + BodyOffset.LocalMecPositionY];
      this.bodyStates[stride + BodyOffset.MecPositionX] =
        localMecX * cos -
        localMecY * sin +
        this.bodyStates[stride + BodyOffset.PositionX];
      this.bodyStates[stride + BodyOffset.MecPositionY] =
        localMecX * sin +
        localMecY * cos +
        this.bodyStates[stride + BodyOffset.PositionY];

      this.bodyStates[stride + BodyOffset.AccelerationX] = 0;
      this.bodyStates[stride + BodyOffset.AccelerationY] = this.gravity;
      this.bodyStates[stride + BodyOffset.AngularAcceleration] = 0;
      body.shape.globalDirty = true;
    }
    this.endMetric("positionUpdate");

    // Update timers
    const currentTime = performance.now();
    for (const timer of this.timers.values()) {
      if (currentTime >= timer.nextTick) {
        timer.callback();
        if (timer.repeating) {
          timer.nextTick = currentTime + timer.interval;
        } else {
          this.timers.delete(timer.id);
        }
      }
    }

    const bodiesWithPositionCorrection: Body[] = [];

    this.startMetric("collision");
    const {
      bodiesWithPositionCorrection: collisionBodiesWithPositionCorrection,
    } = this.detectAndResolveCollisions(dontResolveCollisions);
    bodiesWithPositionCorrection.push(...collisionBodiesWithPositionCorrection);
    this.endMetric("collision");

    this.endMetric("applyPositionCorrections");

    for (const body of bodiesWithPositionCorrection) {
      if (body.isLoaded()) {
        body.applyPositionCorrections();
      }
    }

    this.endMetric("applyPositionCorrections");

    const actuatorPositionCorrections: Body[] = [];

    // Update actuators
    for (const actuator of this.actuators) {
      const appliedCorrection = actuator.applyForces({
        plane: this,
        dt,
        now,
      });

      actuatorPositionCorrections.push(
        ...appliedCorrection.appliedPositionCorrectionTo
      );
    }

    for (const body of actuatorPositionCorrections) {
      if (body.isLoaded()) {
        body.applyPositionCorrections();
      }
    }
  }

  getBodyAt(point: Vector2D): number | null {
    for (let i = 0; i < this.nextIndex; i++) {
      const globalMecX =
        this.bodyStates[i * this.bodyDataStride + BodyOffset.MecPositionX];
      const globalMecY =
        this.bodyStates[i * this.bodyDataStride + BodyOffset.MecPositionY];
      const dx = point.x - globalMecX;
      const dy = point.y - globalMecY;
      const mecRadius =
        this.bodyStates[i * this.bodyDataStride + BodyOffset.MecRadius];

      if (!(dx * dx + dy * dy <= mecRadius * mecRadius)) {
        continue;
      }

      const transform = new Transform(
        this.bodyStates[i * this.bodyDataStride + BodyOffset.PositionX],
        this.bodyStates[i * this.bodyDataStride + BodyOffset.PositionY],
        this.bodyStates[i * this.bodyDataStride + BodyOffset.Rotation]
      );

      const body = this.getBody(i);
      if (!body) {
        continue;
      }

      if (body.shape.contains(point, transform)) {
        return i;
      }
    }
    return null;
  }

  detectAndResolveCollisions(dontResolveCollisions: boolean = false): {
    bodiesWithPositionCorrection: Body[];
  } {
    this.startMetric("collision");
    const potentialCollisions: CollisionPair[] = [];
    this.booleanResults = [];

    // First, update world bounds if needed
    this.updateWorldBounds(Array.from({ length: this.nextIndex }, (_, i) => i));

    // Construct quadtree
    this.startMetric("quadtreeConstruction");
    this.quadtree = new QuadTree({
      x: this.worldBounds.min.x,
      y: this.worldBounds.min.y,
      width: this.worldBounds.max.x - this.worldBounds.min.x,
      height: this.worldBounds.max.y - this.worldBounds.min.y,
    });

    // Insert all MECs into quadtree
    for (let i = 0; i < this.nextIndex; i++) {
      const mecX =
        this.bodyStates[i * this.bodyDataStride + BodyOffset.MecPositionX];
      const mecY =
        this.bodyStates[i * this.bodyDataStride + BodyOffset.MecPositionY];

      this.quadtree.insert({
        x: mecX,
        y: mecY,
        radius: this.bodyStates[i * this.bodyDataStride + BodyOffset.MecRadius],
        index: i,
      });
    }
    this.endMetric("quadtreeConstruction");

    this.startMetric("intersectMECs");

    // Use the new function to get intersecting pairs and check count
    const { pairs: intersectingPairs, checkCount: mecCheckCount } =
      this.quadtree.findIntersectingPairs();

    this.endMetric("intersectMECs");

    this.recordNumericMetric("mecCheckCount", mecCheckCount);
    this.recordNumericMetric("mecIntersectionCount", intersectingPairs.size);

    for (const [i, j] of intersectingPairs) {
      const body1 = this.getBody(i);
      const body2 = this.getBody(j);

      if (!body1 || !body2) {
        continue;
      }

      // Skip if bodyStates share a composite
      if (body1.composite && body1.composite === body2.composite) {
        continue;
      }

      // Skip if this pair is in the ignored list
      if (!this.shouldCheckCollision(i, j)) {
        continue;
      }

      potentialCollisions.push({
        body1: body1,
        body2: body2,
        body1Index: i,
        body2Index: j,
        body1PossibleSegments: new Set(),
        body2PossibleSegments: new Set(),
        body1Mec: {
          x: this.bodyStates[i * this.bodyDataStride + BodyOffset.MecPositionX],
          y: this.bodyStates[i * this.bodyDataStride + BodyOffset.MecPositionY],
          radius:
            this.bodyStates[i * this.bodyDataStride + BodyOffset.MecRadius],
          index: i,
        },
        body2Mec: {
          x: this.bodyStates[j * this.bodyDataStride + BodyOffset.MecPositionX],
          y: this.bodyStates[j * this.bodyDataStride + BodyOffset.MecPositionY],
          radius:
            this.bodyStates[j * this.bodyDataStride + BodyOffset.MecRadius],
          index: j,
        },
        intersectionPoints: null,
        intersectionInfo: null,
        shouldResolve: true,
      });
    }

    this.startMetric("updateGlobalPositions");
    for (const collision of potentialCollisions) {
      // Update global positions for accurate intersection testing
      collision.body1.updateShapeTransform();
      collision.body2.updateShapeTransform();
    }
    this.endMetric("updateGlobalPositions");

    this.startMetric("findPossibleSegments");
    for (const collision of potentialCollisions) {
      // Find possible intersections, using the mec of each shape to query the other's segment quadtree
      const body1Segments = collision.body1.shape.querySegmentQuadtree(
        collision.body2Mec
      );
      const body2Segments = collision.body2.shape.querySegmentQuadtree(
        collision.body1Mec
      );

      collision.body1PossibleSegments = body1Segments;
      collision.body2PossibleSegments = body2Segments;
    }
    this.endMetric("findPossibleSegments");

    let unfilteredSegmentCheckCount = 0;
    let segmentCheckCount = 0;
    let segmentIntersectionCount = 0;

    this.startMetric("intersectSegments");
    for (const collision of potentialCollisions) {
      unfilteredSegmentCheckCount +=
        collision.body1.shape.segments.length *
        collision.body2.shape.segments.length;

      // Get intersection points
      const result = findShapeIntersections(
        collision.body1.shape,
        collision.body2.shape,
        collision.body1PossibleSegments,
        collision.body2PossibleSegments
      );

      if (result) {
        segmentCheckCount += result.segmentCheckCount;
        segmentIntersectionCount += result.segmentIntersectionCount;
        collision.intersectionPoints = result.intersections;
      }
    }
    this.endMetric("intersectSegments");

    this.recordNumericMetric(
      "unfilteredSegmentCheckCount",
      unfilteredSegmentCheckCount
    );
    this.recordNumericMetric("segmentCheckCount", segmentCheckCount);

    const percentSegmentsFiltered =
      (unfilteredSegmentCheckCount - segmentCheckCount) /
      unfilteredSegmentCheckCount;

    this.recordNumericMetric(
      "percentSegmentsFiltered",
      percentSegmentsFiltered
    );

    this.recordNumericMetric(
      "segmentIntersectionCount",
      segmentIntersectionCount
    );

    const collisions: CollisionPair[] = [];

    this.startMetric("findEnclosure");
    for (const collision of potentialCollisions) {
      // Get intersection points
      if (collision.intersectionPoints === null) {
        collision.intersectionPoints = findShapeEnclosure(
          collision.body1.shape,
          collision.body2.shape
        );
      }

      if (collision.intersectionPoints !== null) {
        collisions.push(collision);
      }
    }
    this.endMetric("findEnclosure");

    let numCollisions = 0;

    this.startMetric("buildIntersectionShapes");
    for (const collision of collisions) {
      numCollisions++;
      const [x1, y1, r1] = collision.body1.getPosition();
      const [x2, y2, r2] = collision.body2.getPosition();
      const [vx1, vy1, w1] = collision.body1.getVelocity();
      const [vx2, vy2, w2] = collision.body2.getVelocity();
      const result = intersectionOrUnionShapes(
        [collision.body1.shape, collision.body2.shape],
        collision.body1Index,
        collision.body2Index,
        collision.intersectionPoints!,
        false,
        {
          shape1: {
            x: x1,
            y: y1,
            vx: vx1,
            vy: vy1,
            angularVelocity: w1,
          },
          shape2: {
            x: x2,
            y: y2,
            vx: vx2,
            vy: vy2,
            angularVelocity: w2,
          },
        }
      );

      if (result) {
        this.booleanResults.push(result);
        collision.intersectionInfo = {
          centroid: result.centroid,
          normal: result.normal,
          penetrationDistance: result.penetrationDistance,
        };
      }
    }
    this.endMetric("buildIntersectionShapes");

    this.recordNumericMetric("numCollisions", numCollisions);

    this.startMetric("callBeforeCollisionTriggers");

    for (const collision of collisions) {
      if (!collision.intersectionInfo) {
        continue;
      }

      const shouldResolve1 = collision.body1.callBeforeCollisionTriggers({
        thisIndex: collision.body1Index,
        otherIndex: collision.body2Index,
        otherBody: collision.body2,
        intersectionInfo: collision.intersectionInfo,
      });

      const shouldResolve2 = collision.body2.callBeforeCollisionTriggers({
        thisIndex: collision.body2Index,
        otherIndex: collision.body1Index,
        otherBody: collision.body1,
        intersectionInfo: collision.intersectionInfo,
      });

      if (!shouldResolve1 || !shouldResolve2) {
        collision.shouldResolve = false;
      }
    }

    this.endMetric("callBeforeCollisionTriggers");

    const bodiesWithPositionCorrection: Body[] = [];

    this.startMetric("resolve");
    // Resolve collisions
    if (!dontResolveCollisions) {
      for (const collision of collisions) {
        if (!collision.intersectionInfo || !collision.shouldResolve) {
          continue;
        }

        bodiesWithPositionCorrection.push(collision.body1);
        bodiesWithPositionCorrection.push(collision.body2);

        this.resolveImpulseCollision(
          collision.body1,
          collision.body2,
          collision.intersectionInfo.centroid,
          collision.intersectionInfo.normal,
          collision.intersectionInfo.penetrationDistance,
          typeof collision.intersectionPoints === "boolean"
        );
      }
    }

    this.endMetric("resolve");

    this.startMetric("callAfterCollisionTriggers");

    for (const collision of collisions) {
      if (!collision.intersectionInfo) {
        continue;
      }

      collision.body1.callAfterCollisionTriggers({
        thisIndex: collision.body1Index,
        otherIndex: collision.body2Index,
        otherBody: collision.body2,
        intersectionInfo: collision.intersectionInfo,
      });

      collision.body2.callAfterCollisionTriggers({
        thisIndex: collision.body2Index,
        otherIndex: collision.body1Index,
        otherBody: collision.body1,
        intersectionInfo: collision.intersectionInfo,
      });
    }

    this.endMetric("callAfterCollisionTriggers");

    this.startMetric("applyPositionCorrections");

    return { bodiesWithPositionCorrection };
  }

  resolveImpulseCollision(
    body1: Body,
    body2: Body,
    pointOfContact: Vector2D,
    normal: Vector2D,
    penetrationDistance: number,
    enclosure: boolean
  ): void {
    if (!normal.isValid()) {
      console.warn("normal is invalid", body1.id, body2.id);
      return;
    }

    if (!pointOfContact.isValid()) {
      return;
    }

    if (penetrationDistance <= 0 || !isFinite(penetrationDistance)) {
      console.warn("penetrationDistance is invalid", penetrationDistance);
      return;
    }

    const restitution = Math.min(
      body1.material.restitution,
      body2.material.restitution
    );

    // Find geometric mean of friction coefficients
    const staticFriction = Math.sqrt(
      body1.material.staticFriction * body2.material.staticFriction
    );
    const dynamicFriction = Math.sqrt(
      body1.material.dynamicFriction * body2.material.dynamicFriction
    );

    const mass1 = body1.getMass(true);
    const mass2 = body2.getMass(true);

    // Calculate inverse masses (0 for infinite mass)
    const invMass1 = mass1 === Infinity ? 0 : 1 / mass1;
    const invMass2 = mass2 === Infinity ? 0 : 1 / mass2;

    // Skip if both objects have infinite mass
    if (invMass1 === 0 && invMass2 === 0) {
      return;
    }

    const inertia1 = body1.getMomentOfInertia(true);
    const inertia2 = body2.getMomentOfInertia(true);

    // Calculate centers and moment arms
    const center1 = body1.getCenter(true);
    const center2 = body2.getCenter(true);
    const r1 = pointOfContact.subtract(center1);
    const r2 = pointOfContact.subtract(center2);

    // Get point velocities
    const v1Point = body1.getPointVelocity(r1, true);
    const v2Point = body2.getPointVelocity(r2, true);
    const relativeVelocity = v1Point.subtract(v2Point);

    if (!relativeVelocity.isValid()) {
      console.error(
        `relativeVelocity is invalid for pair ${body1.id} and ${body2.id} (enclosure: ${enclosure}): ${relativeVelocity.x}, ${relativeVelocity.y}\nr1: ${r1.x}, ${r1.y}\nr2: ${r2.x}, ${r2.y}\nv1Point: ${v1Point.x}, ${v1Point.y}\nv2Point: ${v2Point.x}, ${v2Point.y}\nmass1: ${mass1}\nmass2: ${mass2}\ninertia1: ${inertia1}\ninertia2: ${inertia2}\nnormal: ${normal.x}, ${normal.y}\ncenter1: ${center1.x}, ${center1.y}\ncenter2: ${center2.x}, ${center2.y}\nframeNumber: ${this.currentFrame}\npointOfContact: ${pointOfContact.x}, ${pointOfContact.y}`
      );
      return;
    }

    const normalVelocity = relativeVelocity.dot(normal);

    // Only proceed if objects are moving toward each other
    if (normalVelocity < 0) {
      const tangent = new Vector2D(-normal.y, normal.x);
      const tangentVelocity = relativeVelocity.dot(tangent);

      const r1CrossN = r1.x * normal.y - r1.y * normal.x;
      const r2CrossN = r2.x * normal.y - r2.y * normal.x;
      const r1CrossT = r1.x * tangent.y - r1.y * tangent.x;
      const r2CrossT = r2.x * tangent.y - r2.y * tangent.x;

      // Calculate inverse inertias (0 for locked rotation)
      const invInertia1 = body1.rotationLocked ? 0 : 1 / inertia1;
      const invInertia2 = body2.rotationLocked ? 0 : 1 / inertia2;

      const denominator =
        invMass1 +
        invMass2 +
        r1CrossN * r1CrossN * invInertia1 +
        r2CrossN * r2CrossN * invInertia2;

      // Avoid division by zero
      if (denominator === 0) {
        return;
      }

      const j = (-(1 + restitution) * normalVelocity) / denominator;
      const normalImpulse = normal.scale(j);

      // Calculate friction
      const tangentDenominator =
        invMass1 +
        invMass2 +
        r1CrossT * r1CrossT * invInertia1 +
        r2CrossT * r2CrossT * invInertia2;

      // Avoid division by zero
      if (tangentDenominator === 0) {
        return;
      }

      let jt = -tangentVelocity / tangentDenominator;
      const maxFriction = j * staticFriction;

      if (Math.abs(jt) > maxFriction) {
        jt = Math.sign(jt) * j * dynamicFriction;
      }

      const tangentImpulse = tangent.scale(jt);
      const totalImpulse = normalImpulse.add(tangentImpulse);

      // Apply impulses using inverse mass
      if (invMass1 > 0) {
        body1.applyLinearImpulse(totalImpulse, true);
      }
      if (invMass2 > 0) {
        body2.applyLinearImpulse(totalImpulse.scale(-1), true);
      }

      // Apply angular impulses only if rotation isn't locked
      if (invInertia1 > 0) {
        body1.applyAngularImpulse(r1, totalImpulse, true, enclosure);
      }
      if (invInertia2 > 0) {
        body2.applyAngularImpulse(r2, totalImpulse.scale(-1), true, enclosure);
      }
    }

    // Position correction using Baumgarte Stabilization with velocity scaling
    const basePercent = 0.9;
    const baseSlop = 0.05;

    // Calculate relative speed at point of contact
    const relativeSpeed = relativeVelocity.length();
    const speedScale = Math.max(1, relativeSpeed / 10);

    // Exponentially reduce slop at high speeds while linearly increasing correction
    const percent = Math.min(1.0, basePercent * (1 + speedScale * 0.5));
    const slop = baseSlop * Math.exp(-speedScale * 0.2);

    if (penetrationDistance > slop) {
      const correction = normal.scale(
        ((penetrationDistance - slop) * percent) / (invMass1 + invMass2 || 1)
      );

      if (invMass1 > 0) {
        body1.addPositionCorrection(correction, true);
      }
      if (invMass2 > 0) {
        body2.addPositionCorrection(correction.scale(-1), true);
      }
    }
  }

  addTimer(
    callback: () => void,
    delay: number,
    repeating: boolean = false
  ): number {
    const id = this.nextTimerId++;
    this.timers.set(id, {
      id,
      callback,
      interval: delay,
      nextTick: performance.now() + delay,
      repeating,
    });
    return id;
  }

  removeTimer(id: number): void {
    this.timers.delete(id);
  }

  getVisibleBodyIndices(player: Player, margin: number = 0): number[] {
    const viewBounds = player.camera.getWorldBounds(margin);

    // Query the quadtree with the view bounds
    const visibleCircles = this.quadtree?.queryRect(viewBounds);

    // If no quadtree or no results, return empty array
    if (!visibleCircles) {
      return [];
    }

    // Convert the Set of circles back to array of indices
    return Array.from(visibleCircles).map((circle) => circle.index);
  }

  private directManipulationUpdate(player: Player): void {
    if (this.dragInfo) {
      const index = this.dragInfo.bodyIndex;

      // Calculate current position of grab point
      const centerPos = new Vector2D(
        this.bodyStates[index * this.bodyDataStride + BodyOffset.PositionX],
        this.bodyStates[index * this.bodyDataStride + BodyOffset.PositionY]
      );
      const currentGrabPos = centerPos.add(this.dragInfo.grabPointLocal);

      // Calculate how far we need to move to reach the mouse
      const targetPos = player.mousePosition;
      const displacement = targetPos.subtract(currentGrabPos);

      // Move the shape directly
      this.bodyStates[index * this.bodyDataStride + BodyOffset.PositionX] +=
        displacement.x;
      this.bodyStates[index * this.bodyDataStride + BodyOffset.PositionY] +=
        displacement.y;

      // Set velocity to zero to prevent continued motion
      this.bodyStates[index * this.bodyDataStride + BodyOffset.VelocityX] = 10;
      this.bodyStates[index * this.bodyDataStride + BodyOffset.VelocityY] = 10;
      this.bodyStates[
        index * this.bodyDataStride + BodyOffset.AngularVelocity
      ] = 0.001;
      this.bodyStates[
        index * this.bodyDataStride + BodyOffset.AccelerationX
      ] = 0;
      this.bodyStates[
        index * this.bodyDataStride + BodyOffset.AccelerationY
      ] = 0;
      this.bodyStates[
        index * this.bodyDataStride + BodyOffset.AngularAcceleration
      ] = 0;
    }
  }

  addIgnoredCollisionPair(body1: number, body2: number): void {
    const key = new CollisionPairKey(body1, body2).toString();
    this.ignoredCollisionPairs.add(key);
  }

  removeIgnoredCollisionPair(body1: number, body2: number): void {
    const key = new CollisionPairKey(body1, body2).toString();
    this.ignoredCollisionPairs.delete(key);
  }

  shouldCheckCollision(body1: number, body2: number): boolean {
    const key = new CollisionPairKey(body1, body2).toString();
    return !this.ignoredCollisionPairs.has(key);
  }

  updateWorldBounds(bodyStates: number[]): void {
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    for (const i of bodyStates) {
      const x = this.bodyStates[i * this.bodyDataStride + BodyOffset.PositionX];
      const y = this.bodyStates[i * this.bodyDataStride + BodyOffset.PositionY];
      const radius =
        this.bodyStates[i * this.bodyDataStride + BodyOffset.MecRadius];

      minX = Math.min(minX, x - radius);
      minY = Math.min(minY, y - radius);
      maxX = Math.max(maxX, x + radius);
      maxY = Math.max(maxY, y + radius);
    }

    // Add padding to bounds
    const padding = 100; // Adjust as needed
    this.worldBounds = {
      min: new Vector2D(minX - padding, minY - padding),
      max: new Vector2D(maxX + padding, maxY + padding),
    };
  }
}
