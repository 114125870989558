import type { Vector2D } from "./math/vector2D.ts";

import type { Transform } from "./math/transform.ts";
import type { Shape } from "./shapes/shape.ts";
import type { ActuatorConfig, GrabState } from "./plane/actuator.ts";
import type { Camera } from "./plane/camera.ts";
import type { LineSegment } from "./shapes/segments/lineSegment.ts";
import type { ArcSegment } from "./shapes/segments/arcSegment.ts";
import type { Body } from "./plane/body.ts";
import type { QuadBounds } from "./plane/segmentQuadtree.ts";
import type { Circle } from "./plane/quadtree.ts";

export interface BodyState {
  position: [number, number, number];
  velocity: [number, number, number];
}

export type SerializedBodyState = {
  id: string;
  transform: [number, number, number];
  velocity: [number, number, number];
};

export type SerializedBodyData = {
  id: string;
  shape: SerializedShape;
  material: Material;
  positionLocked: boolean;
  rotationLocked: boolean;
};

export type SerializedActuatorState = {
  isGrabbing: [boolean, boolean];
  grabbedBodyIndices: [number | null, number | null];
  lastError: [number, number] | null;
  integral: [number, number];
  grabStates: [GrabState, GrabState];
  targetPos: [number, number];
  lastImpulseTime: number;
  impulseRequested: boolean;
};

export type SerializedActuatorData = {
  id: string;
  body1Id: string;
  body2Id: string;
  config: ActuatorConfig;
};

export type PlaneStateUpdate = {
  key: string;
  cameraCenter: [number, number];
  cameraZoom: number;
  bodyStates: SerializedBodyState[];
  actuatorStates: SerializedActuatorState[];
  newOrUpdatedBodies?: SerializedBodyData[];
  newOrUpdatedActuators?: SerializedActuatorData[];
  removedBodies?: string[];
  removedActuators?: string[];
};

export type InitializePlayerMessage = {
  key: string;
  actuatorId: string;
  bodies: SerializedBodyData[];
  actuators: SerializedActuatorData[];
};

export enum SegmentType {
  LINE,
  ARC,
  CIRCLE,
}

export type CentroidAndAreaContribution = {
  shoelaceFactor?: number;
  shoelaceCentroid?: Vector2D;
  shoelaceSecondMomentOfArea?: number;
  area?: number;
  centroid?: Vector2D;
  compositeSecondMomentOfArea?: number;
};

export interface LineIntersection {
  point: Vector2D;
  t: number; // Parameter along the line (0-1)
  segment: Segment;
}

export type SerializedLineSegment = {
  type: SegmentType.LINE;
  start: [number, number];
  end: [number, number];
};

export type SerializedArcSegment = {
  type: SegmentType.ARC;
  start: [number, number];
  end: [number, number];
  sagitta: number;
};

export type SerializedCircleSegment = {
  type: SegmentType.CIRCLE;
  center: [number, number];
  radius: number;
};

export type SerializedSegment =
  | SerializedLineSegment
  | SerializedArcSegment
  | SerializedCircleSegment;

export interface Segment {
  type: SegmentType;
  updateGlobal(transform: Transform): void;
  rayIntersectionCount(point: Vector2D, local: boolean): number;
  globalClone(
    invert: boolean,
    tValues?: [[number, number], [Vector2D | null, Vector2D | null]]
  ): Segment;
  clone(invert: boolean): Segment;
  translate(dx: number, dy: number): void;
  getCentroidAndAreaContribution(local: boolean): CentroidAndAreaContribution;
  serialize(): SerializedSegment;
  getLineIntersection(
    lineStart: Vector2D,
    lineEnd: Vector2D,
    local: boolean
  ): LineIntersection[];
  addToPath(camera: Camera, path: Path2D): void;
  intersectsBounds(bounds: QuadBounds): boolean;
  intersectsCircle(circle: Circle): boolean;
}

export type SerializedShape = {
  segments: SerializedSegment[];
  boundaryIndices: number[];
  area: number;
  centroid: [number, number];
  mec: {
    center: [number, number];
    radius: number;
  };
  secondMomentOfArea: number;
};

export type SerializedIntersection = {
  shape: SerializedShape;
  centroid: [number, number];
  normals: [number, number][];
};

export type OpenSegment = LineSegment | ArcSegment;

export type IntersectionShape = {
  body1Index: number;
  body2Index: number;
  shapes: Shape[];
  centroid: Vector2D;
  rotation: number;
  normal: Vector2D;
  penetrationDistance: number;
  penetrationVector: Vector2D;
  enclosingShape: number | null;
};

export type SerializedIntersectionShape = {
  shape: SerializedShape;
  centroid: [number, number];
  normal: [number, number] | null;
  penetrationDistance: number;
  penetrationVector: [number, number] | null;
};

export enum ClientMessageType {
  MouseDown,
  MouseUp,
  MouseMove,
  Wheel,
  TouchStart,
  TouchMove,
  TouchEnd,
  KeyDown,
  KeyUp,
  Resize,
}

export type Point = [number, number];
export type TouchPoints = [Point, Point];

export type ClientMessage =
  | { type: ClientMessageType.MouseDown }
  | { type: ClientMessageType.MouseUp }
  | { type: ClientMessageType.MouseMove; data: Point }
  | { type: ClientMessageType.Wheel; data: number }
  | { type: ClientMessageType.TouchStart; data: TouchPoints }
  | { type: ClientMessageType.TouchMove; data: TouchPoints }
  | { type: ClientMessageType.TouchEnd }
  | { type: ClientMessageType.KeyDown; data: string }
  | { type: ClientMessageType.KeyUp; data: string }
  | { type: ClientMessageType.Resize; data: Point };

export interface NetworkStateEntry {
  id: string;
  position: [number, number, number]; // [x, y, rotation]
  velocity: [number, number, number]; // [x, y, angular velocity]
}

export interface NetworkState {
  clientTimestamp: number;
  dt: number;
  entries: NetworkStateEntry[];
  used: boolean;
}

export interface Timer {
  id: number;
  callback: () => void;
  interval: number;
  nextTick: number;
  repeating: boolean;
}

export interface GrabInfo {
  bodyIndex: number;
  grabPointLocal: Vector2D;
}

export interface DragInfo {
  bodyIndex: number;
  grabPointLocal: Vector2D;
}

export interface CollisionPair {
  body1Index: number;
  body2Index: number;
  body1Mec: Circle;
  body2Mec: Circle;
  body1: Body;
  body2: Body;
  body1PossibleSegments: Set<number>;
  body2PossibleSegments: Set<number>;
  intersectionPoints:
    | [IntersectionPoint[], IntersectionPoint[]]
    | boolean
    | null;
  intersectionInfo: {
    centroid: Vector2D;
    normal: Vector2D;
    penetrationDistance: number;
  } | null;
  shouldResolve: boolean;
}

export type IntersectionPoint = {
  t: number;
  segmentIndex: number;
  boundaryIndex: number;
  entering: boolean;
  visited: boolean;
  point: Vector2D;
  counterpart?: IntersectionPoint;
  previousIndex?: number;
  nextIndex?: number;
};

export interface ShapeMotionInfo {
  shape1: {
    x: number;
    y: number;
    vx: number;
    vy: number;
    angularVelocity: number; // in radians/second
  };
  shape2: {
    x: number;
    y: number;
    vx: number;
    vy: number;
    angularVelocity: number; // in radians/second
  };
}

export interface Material {
  density: number;
  restitution: number;
  staticFriction: number;
  dynamicFriction: number;
  color: string;
}

export const keyMap = {
  thrust: ["w", "ArrowUp"],
  bodyGrab: ["p"],
  armGrab: ["v"],
  action: ["e"],
  shoot: [" "],
};

export class CollisionPairKey {
  constructor(private body1: number, private body2: number) {
    // Always store pairs in sorted order to ensure consistent lookup
    if (body1 > body2) {
      [this.body1, this.body2] = [body2, body1];
    }
  }

  toString(): string {
    return `${this.body1},${this.body2}`;
  }
}
