import type { Transform } from "../../math/transform.ts";
import { normalizeAngle, TWO_PI } from "../../math/utils.ts";
import { Vector2D } from "../../math/vector2D.ts";
import type { Camera } from "../../plane/camera.ts";
import {
  SegmentType,
  type CentroidAndAreaContribution,
  type LineIntersection,
  type Segment,
  type SerializedArcSegment,
  type SerializedSegment,
} from "../../models.ts";
import type { QuadBounds } from "../../plane/segmentQuadtree.ts";
import type { Circle } from "../../plane/quadtree.ts";

export class ArcSegment implements Segment {
  type: SegmentType = SegmentType.ARC;
  localStart: Vector2D;
  localEnd: Vector2D;
  localDisplacement: Vector2D;
  length: number;
  start: Vector2D;
  end: Vector2D;
  displacement: Vector2D;
  sagitta: number;

  localCenter: Vector2D;
  localCentroid: Vector2D;
  localStartAngle: number;
  localEndAngle: number;
  localArcMidpoint: Vector2D;

  centralAngle: number;
  radius: number;
  area: number;
  clockwise: boolean;

  center: Vector2D;
  centroid: Vector2D;
  startAngle: number;
  endAngle: number;
  arcMidpoint: Vector2D;

  constructor(
    start: Vector2D,
    end: Vector2D,
    sagOrCircle:
      | number
      | {
          center: Vector2D;
          radius: number;
          startAngle: number;
          centralAngle: number;
        },
    cloning: boolean = false
  ) {
    this.localStart = start;
    this.localEnd = end;
    if (!cloning) {
      this.localDisplacement = end.subtract(start);
      this.length = this.localDisplacement.magnitude();
      if (typeof sagOrCircle === "number") {
        this.sagitta = sagOrCircle;
        const signedRadius =
          sagOrCircle / 2 + (this.length * this.length) / (8 * sagOrCircle);

        const perpX = -this.localDisplacement.y / this.length;
        const perpY = this.localDisplacement.x / this.length;

        this.localArcMidpoint = new Vector2D(
          (start.x + end.x) / 2 - perpX * sagOrCircle,
          (start.y + end.y) / 2 - perpY * sagOrCircle
        );

        this.localCenter = new Vector2D(
          this.localArcMidpoint.x + perpX * signedRadius,
          this.localArcMidpoint.y + perpY * signedRadius
        );

        this.radius = Math.abs(signedRadius);

        this.localStartAngle = normalizeAngle(
          Math.atan2(
            this.localStart.y - this.localCenter.y,
            this.localStart.x - this.localCenter.x
          )
        );

        const unsignedCentralAngle =
          Math.abs(sagOrCircle) > this.radius
            ? TWO_PI - 2 * Math.asin(this.length / (2 * this.radius))
            : 2 * Math.asin(this.length / (2 * this.radius));

        this.centralAngle =
          sagOrCircle < 0 ? -unsignedCentralAngle : unsignedCentralAngle;

        this.localEndAngle = normalizeAngle(
          this.localStartAngle + this.centralAngle
        );
        this.clockwise = this.centralAngle < 0;
      } else {
        this.localCenter = sagOrCircle.center;
        this.radius = sagOrCircle.radius;
        this.localStartAngle = sagOrCircle.startAngle;
        this.centralAngle = sagOrCircle.centralAngle;
        this.localEndAngle = normalizeAngle(
          this.localStartAngle + this.centralAngle
        );
        this.clockwise = this.centralAngle < 0;
        this.localArcMidpoint = new Vector2D(
          this.localCenter.x +
            this.radius *
              Math.cos(this.localStartAngle + this.centralAngle / 2),
          this.localCenter.y +
            this.radius * Math.sin(this.localStartAngle + this.centralAngle / 2)
        );
        this.sagitta = this.radius * (1 - Math.cos(this.centralAngle / 2));
      }
      const angleTerm = this.centralAngle - Math.sin(this.centralAngle);
      this.area = (angleTerm * this.radius * this.radius) / 2;

      const centroidDistance =
        (4 * this.radius * Math.sin(this.centralAngle / 2) ** 3) /
        (3 * angleTerm);

      this.localCentroid = new Vector2D(
        this.localCenter.x +
          (this.localArcMidpoint.x - this.localCenter.x) *
            (centroidDistance / this.radius),
        this.localCenter.y +
          (this.localArcMidpoint.y - this.localCenter.y) *
            (centroidDistance / this.radius)
      );
    } else {
      if (typeof sagOrCircle === "number") {
        throw new Error("Cannot clone an arc segment with a sagitta");
      }
      this.localCenter = sagOrCircle.center;
      this.radius = sagOrCircle.radius;
      this.localStartAngle = sagOrCircle.startAngle;
      this.centralAngle = sagOrCircle.centralAngle;
      this.localEndAngle = normalizeAngle(
        this.localStartAngle + this.centralAngle
      );
      this.clockwise = this.centralAngle < 0;
      this.sagitta = this.radius * (1 - Math.cos(this.centralAngle / 2));

      this.localDisplacement = new Vector2D(0, 0);
      this.length = 0;
      this.localArcMidpoint = new Vector2D(0, 0);
      this.localCentroid = new Vector2D(0, 0);
      this.area = 0;
    }

    this.start = new Vector2D(0, 0);
    this.end = new Vector2D(0, 0);
    this.displacement = new Vector2D(0, 0);
    this.center = new Vector2D(0, 0);
    this.centroid = new Vector2D(0, 0);
    this.startAngle = 0;
    this.endAngle = 0;
    this.arcMidpoint = new Vector2D(0, 0);
  }

  static fromSerialized(serialized: SerializedArcSegment): ArcSegment {
    const segment = new ArcSegment(
      new Vector2D(serialized.start[0], serialized.start[1]),
      new Vector2D(serialized.end[0], serialized.end[1]),
      serialized.sagitta
    );
    return segment;
  }

  updateGlobal(transform: Transform) {
    transform.applyInPlace(this.localStart, this.start);
    transform.applyInPlace(this.localEnd, this.end);
    this.displacement.x = this.end.x - this.start.x;
    this.displacement.y = this.end.y - this.start.y;

    transform.applyInPlace(this.localCenter, this.center);
    transform.applyInPlace(this.localCentroid, this.centroid);
    transform.applyInPlace(this.localArcMidpoint, this.arcMidpoint);
    this.startAngle = normalizeAngle(this.localStartAngle + transform.angle);
    this.endAngle = normalizeAngle(this.startAngle + this.centralAngle);
  }

  globalClone(
    invert: boolean,
    tValues?: [[number, number], [Vector2D | null, Vector2D | null]]
  ): Segment {
    if (tValues) {
      const [[t1, t2], [start, end]] = tValues;
      if (t1 < 0 || t1 > 1 || t2 < 0 || t2 > 1) {
        throw new Error("t values must be between 0 and 1");
      }

      if (t1 > t2) {
        throw new Error("t1 must be less than t2");
      }

      const tDiff = t2 - t1;

      if (!invert) {
        const segment = new ArcSegment(
          start ?? this.start.clone(),
          end ?? this.end.clone(),
          {
            center: this.center.clone(),
            radius: this.radius,
            startAngle: normalizeAngle(
              this.startAngle + t1 * this.centralAngle
            ),
            centralAngle: this.centralAngle * tDiff,
          },
          false
        );
        return segment;
      } else {
        const segment = new ArcSegment(
          end ?? this.end.clone(),
          start ?? this.start.clone(),
          {
            center: this.center.clone(),
            radius: this.radius,
            startAngle: normalizeAngle(
              this.startAngle + t2 * this.centralAngle
            ),
            centralAngle: -this.centralAngle * tDiff,
          },
          false
        );
        return segment;
      }
    } else {
      if (!invert) {
        const segment = new ArcSegment(
          this.start.clone(),
          this.end.clone(),
          {
            center: this.center.clone(),
            radius: this.radius,
            startAngle: this.startAngle,
            centralAngle: this.centralAngle,
          },
          true
        );
        segment.localDisplacement.x = this.displacement.x;
        segment.localDisplacement.y = this.displacement.y;
        segment.length = this.length;
        segment.area = this.area;
        segment.localCentroid = this.centroid.clone();
        segment.localArcMidpoint = this.arcMidpoint.clone();

        return segment;
      } else {
        const segment = new ArcSegment(
          this.end.clone(),
          this.start.clone(),
          {
            center: this.center.clone(),
            radius: this.radius,
            startAngle: this.endAngle,
            centralAngle: -this.centralAngle,
          },
          true
        );
        segment.localDisplacement.x = -this.localDisplacement.x;
        segment.localDisplacement.y = -this.localDisplacement.y;
        segment.length = this.length;
        segment.area = -this.area;
        segment.localCentroid = this.centroid.clone();
        segment.localArcMidpoint = this.arcMidpoint.clone();
        return segment;
      }
    }
  }

  clone(invert: boolean): Segment {
    if (!invert) {
      const segment = new ArcSegment(
        this.localStart.clone(),
        this.localEnd.clone(),
        {
          center: this.localCenter.clone(),
          radius: this.radius,
          startAngle: this.localStartAngle,
          centralAngle: this.centralAngle,
        },
        true
      );
      segment.localDisplacement.x = this.localDisplacement.x;
      segment.localDisplacement.y = this.localDisplacement.y;
      segment.length = this.length;
      segment.area = this.area;
      segment.localCentroid = this.localCentroid.clone();
      segment.localArcMidpoint = this.localArcMidpoint.clone();

      return segment;
    } else {
      const segment = new ArcSegment(
        this.localEnd.clone(),
        this.localStart.clone(),
        {
          center: this.localCenter.clone(),
          radius: this.radius,
          startAngle: this.localEndAngle,
          centralAngle: -this.centralAngle,
        },
        true
      );
      segment.localDisplacement.x = -this.localDisplacement.x;
      segment.localDisplacement.y = -this.localDisplacement.y;
      segment.length = this.length;
      segment.area = -this.area;
      segment.localCentroid = this.localCentroid.clone();
      segment.localArcMidpoint = this.localArcMidpoint.clone();
      return segment;
    }
  }

  rayIntersectionCount(point: Vector2D, local: boolean): number {
    const centerX = local ? this.localCenter.x : this.center.x;
    const centerY = local ? this.localCenter.y : this.center.y;

    // Early exit if the ray is too far above or below the circle
    const dy = point.y - centerY;
    if (Math.abs(dy) > this.radius) {
      return 0;
    }

    // Calculate the x-coordinates of intersections using the circle equation
    const dx = Math.sqrt(this.radius * this.radius - dy * dy);
    const x1 = centerX - dx;
    const x2 = centerX + dx;

    const angles: number[] = [];

    if (x1 >= point.x) {
      const angle = Math.atan2(dy, x1 - centerX);
      angles.push(normalizeAngle(angle));
    }
    if (x2 >= point.x) {
      const angle = Math.atan2(dy, x2 - centerX);
      angles.push(normalizeAngle(angle));
    }

    if (angles.length === 0) {
      return 0;
    }

    const startAngle = local ? this.localStartAngle : this.startAngle;
    const endAngle = local ? this.localEndAngle : this.endAngle;

    // Count regular intersections
    let count = 0;
    for (const angle of angles) {
      if (this.angleIsWithinArc(angle, startAngle, endAngle)) {
        count++;
      }
    }
    return count;
  }

  angleIsWithinArc(
    angle: number,
    startAngle: number,
    endAngle: number
  ): boolean {
    if (!this.clockwise) {
      if (startAngle < endAngle) {
        return angle >= startAngle && angle <= endAngle;
      } else {
        return angle >= startAngle || angle <= endAngle;
      }
    } else {
      if (startAngle > endAngle) {
        return angle <= startAngle && angle >= endAngle;
      } else {
        return angle <= startAngle || angle >= endAngle;
      }
    }
  }

  angleToTValue(
    angle: number,
    startAngle: number,
    endAngle: number,
    centralAngle: number
  ): number | null {
    const unsignedCentralAngle = Math.abs(centralAngle);
    if (!this.clockwise) {
      if (startAngle < endAngle) {
        if (angle >= startAngle && angle <= endAngle) {
          return (angle - startAngle) / unsignedCentralAngle;
        }
        return null;
      } else {
        if (angle >= startAngle && angle <= TWO_PI) {
          return (angle - startAngle) / unsignedCentralAngle;
        }
        if (angle <= endAngle && angle >= 0) {
          return 1 - (endAngle - angle) / unsignedCentralAngle;
        }
        return null;
      }
    } else {
      if (startAngle > endAngle) {
        if (angle <= startAngle && angle >= endAngle) {
          return (startAngle - angle) / unsignedCentralAngle;
        }
        return null;
      } else {
        if (angle <= startAngle && angle >= 0) {
          return (startAngle - angle) / unsignedCentralAngle;
        }
        if (angle >= endAngle && angle <= TWO_PI) {
          return 1 - (angle - endAngle) / unsignedCentralAngle;
        }
        return null;
      }
    }
  }

  translate(dx: number, dy: number) {
    this.localStart.x += dx;
    this.localStart.y += dy;
    this.localEnd.x += dx;
    this.localEnd.y += dy;
    this.localCenter.x += dx;
    this.localCenter.y += dy;
    this.localCentroid.x += dx;
    this.localCentroid.y += dy;
    this.localArcMidpoint.x += dx;
    this.localArcMidpoint.y += dy;
  }

  getCentroidAndAreaContribution(local: boolean): CentroidAndAreaContribution {
    const startX = local ? this.localStart.x : this.start.x;
    const startY = local ? this.localStart.y : this.start.y;
    const endX = local ? this.localEnd.x : this.end.x;
    const endY = local ? this.localEnd.y : this.end.y;

    // Handle shoelace contributions for the chord
    const shoelaceFactor = startX * endY - endX * startY;
    const shoelaceSecondMomentOfArea =
      (startX * startX +
        startX * endX +
        endX * endX +
        startY * startY +
        startY * endY +
        endY * endY) *
      shoelaceFactor;

    // Calculate moment of inertia for the circular segment about circle center
    const theta = Math.abs(this.centralAngle);
    const sinTheta = Math.sin(theta);
    const sinHalfTheta = Math.sin(theta / 2);

    // Using the formula for Iz about circle center
    const segmentMomentAboutCenter =
      (this.radius ** 4 / 4) *
      (theta - sinTheta + (2 / 3) * sinTheta * sinHalfTheta * sinHalfTheta);

    // Get the vector from circle center to arc centroid
    const centerToCentroid = (
      local ? this.localCentroid : this.centroid
    ).subtract(local ? this.localCenter : this.center);

    // Apply parallel axis theorem to shift to arc centroid
    const segmentMomentAboutCentroid =
      segmentMomentAboutCenter -
      Math.abs(this.area) * centerToCentroid.lengthSquared();

    // If the central angle is negative, the moment should be negative
    const compositeSecondMomentOfArea =
      this.centralAngle < 0
        ? -segmentMomentAboutCentroid
        : segmentMomentAboutCentroid;

    return {
      // Shoelace contributions for the chord
      shoelaceFactor,
      shoelaceCentroid: new Vector2D(
        (startX + endX) * shoelaceFactor,
        (startY + endY) * shoelaceFactor
      ),
      shoelaceSecondMomentOfArea,

      // Circular segment contributions
      area: this.area,
      centroid: local ? this.localCentroid : this.centroid,
      compositeSecondMomentOfArea,
    };
  }

  getLineIntersection(
    lineStart: Vector2D,
    lineEnd: Vector2D,
    local: boolean
  ): LineIntersection[] {
    const center = local ? this.localCenter : this.center;
    const startAngle = local ? this.localStartAngle : this.startAngle;

    // First find circle intersections
    const dx = lineEnd.x - lineStart.x;
    const dy = lineEnd.y - lineStart.y;

    const a = dx * dx + dy * dy;
    const b =
      2 * (dx * (lineStart.x - center.x) + dy * (lineStart.y - center.y));
    const c =
      center.x * center.x +
      center.y * center.y +
      lineStart.x * lineStart.x +
      lineStart.y * lineStart.y -
      2 * (center.x * lineStart.x + center.y * lineStart.y) -
      this.radius * this.radius;

    const discriminant = b * b - 4 * a * c;

    if (discriminant < 0) {
      return []; // No intersections
    }

    const intersections: LineIntersection[] = [];
    const sqrtDiscriminant = Math.sqrt(discriminant);

    const t1 = (-b + sqrtDiscriminant) / (2 * a);
    const t2 = (-b - sqrtDiscriminant) / (2 * a);

    // Check each intersection point to see if it lies on the arc
    const point1 = new Vector2D(lineStart.x + t1 * dx, lineStart.y + t1 * dy);
    if (!point1.isValid()) {
      return [];
    }
    const angle1 = normalizeAngle(
      Math.atan2(point1.y - center.y, point1.x - center.x)
    );

    if (this.angleIsWithinArc(angle1, startAngle, this.endAngle)) {
      intersections.push({
        point: point1,
        t: t1,
        segment: this,
      });
    }

    const point2 = new Vector2D(lineStart.x + t2 * dx, lineStart.y + t2 * dy);
    if (!point2.isValid()) {
      return [];
    }
    const angle2 = normalizeAngle(
      Math.atan2(point2.y - center.y, point2.x - center.x)
    );

    if (this.angleIsWithinArc(angle2, startAngle, this.endAngle)) {
      intersections.push({
        point: point2,
        t: t2,
        segment: this,
      });
    }

    return intersections;
  }

  getSagitta(): number {
    const sagitta = this.radius * (1 - Math.cos(this.centralAngle / 2));
    return this.clockwise ? -sagitta : sagitta;
  }

  serialize(): SerializedSegment {
    return {
      type: SegmentType.ARC,
      start: this.localStart.toArray(),
      end: this.localEnd.toArray(),
      sagitta: this.sagitta,
    };
  }

  addToPath(camera: Camera, path: Path2D): void {
    const [centerX, centerY] = camera.worldToScreen(
      this.center.x,
      this.center.y
    );
    const radius = camera.worldToScreenDistance(this.radius);
    path.arc(
      centerX,
      centerY,
      radius,
      this.startAngle,
      this.endAngle,
      this.clockwise
    );
  }

  intersectsBounds(bounds: QuadBounds): boolean {
    // First check if start or end points are inside bounds
    if (
      this.localStart.x >= bounds.left &&
      this.localStart.x <= bounds.right &&
      this.localStart.y >= bounds.top &&
      this.localStart.y <= bounds.bottom
    ) {
      return true;
    }
    if (
      this.localEnd.x >= bounds.left &&
      this.localEnd.x <= bounds.right &&
      this.localEnd.y >= bounds.top &&
      this.localEnd.y <= bounds.bottom
    ) {
      return true;
    }

    // Check if arc intersects any of the four edges of the bounds
    if (this.intersectsHorizontalLine(bounds.top, bounds.left, bounds.right)) {
      return true;
    }
    if (
      this.intersectsHorizontalLine(bounds.bottom, bounds.left, bounds.right)
    ) {
      return true;
    }
    if (this.intersectsVerticalLine(bounds.left, bounds.top, bounds.bottom)) {
      return true;
    }
    if (this.intersectsVerticalLine(bounds.right, bounds.top, bounds.bottom)) {
      return true;
    }

    return false;
  }

  private intersectsHorizontalLine(
    y: number,
    left: number,
    right: number
  ): boolean {
    // Check if line is within vertical bounds of circle
    const dy = y - this.localCenter.y;
    if (Math.abs(dy) > this.radius) {
      return false;
    }

    // Calculate x-coordinates of intersections using circle equation
    const dx = Math.sqrt(this.radius * this.radius - dy * dy);
    const x1 = this.localCenter.x - dx;
    const x2 = this.localCenter.x + dx;

    // Check if any intersection point is within bounds and arc
    for (const x of [x1, x2]) {
      if (x >= left && x <= right) {
        const angle = normalizeAngle(Math.atan2(dy, x - this.localCenter.x));
        if (
          this.angleIsWithinArc(angle, this.localStartAngle, this.localEndAngle)
        ) {
          return true;
        }
      }
    }

    return false;
  }

  private intersectsVerticalLine(
    x: number,
    top: number,
    bottom: number
  ): boolean {
    // Check if line is within horizontal bounds of circle
    const dx = x - this.localCenter.x;
    if (Math.abs(dx) > this.radius) {
      return false;
    }

    // Calculate y-coordinates of intersections using circle equation
    const dy = Math.sqrt(this.radius * this.radius - dx * dx);
    const y1 = this.localCenter.y - dy;
    const y2 = this.localCenter.y + dy;

    // Check if any intersection point is within bounds and arc
    for (const y of [y1, y2]) {
      if (y >= top && y <= bottom) {
        const angle = normalizeAngle(Math.atan2(y - this.localCenter.y, dx));
        if (
          this.angleIsWithinArc(angle, this.localStartAngle, this.localEndAngle)
        ) {
          return true;
        }
      }
    }

    return false;
  }

  intersectsCircle(circle: Circle): boolean {
    // Calculate distance between centers
    const dx = circle.x - this.localCenter.x;
    const dy = circle.y - this.localCenter.y;
    const centerDistance = Math.sqrt(dx * dx + dy * dy);

    // Check if circle completely contains the arc
    // This happens when:
    // 1. The distance between centers is less than (circle.radius - arc.radius)
    // 2. The query circle's radius is larger than our arc's radius
    if (
      circle.radius > this.radius &&
      centerDistance <= circle.radius - this.radius
    ) {
      return true;
    }

    // Quick reject if circles are too far apart
    const radiusSum = this.radius + circle.radius;
    if (centerDistance > radiusSum) {
      return false;
    }

    // If circles are exactly touching, check if touch point is on arc
    if (centerDistance === radiusSum) {
      const angle = normalizeAngle(Math.atan2(dy, dx));
      return this.angleIsWithinArc(
        angle,
        this.localStartAngle,
        this.localEndAngle
      );
    }

    // Calculate intersection points
    const a =
      (this.radius * this.radius -
        circle.radius * circle.radius +
        centerDistance * centerDistance) /
      (2 * centerDistance);
    const h = Math.sqrt(this.radius * this.radius - a * a);

    // Calculate base point
    const basePx = this.localCenter.x + (dx * a) / centerDistance;
    const basePy = this.localCenter.y + (dy * a) / centerDistance;

    // Calculate intersection points
    const intersectionX1 = basePx + (h * dy) / centerDistance;
    const intersectionY1 = basePy - (h * dx) / centerDistance;
    const intersectionX2 = basePx - (h * dy) / centerDistance;
    const intersectionY2 = basePy + (h * dx) / centerDistance;

    // Check if either intersection point lies on the arc
    for (const [x, y] of [
      [intersectionX1, intersectionY1],
      [intersectionX2, intersectionY2],
    ]) {
      const angle = normalizeAngle(
        Math.atan2(y - this.localCenter.y, x - this.localCenter.x)
      );
      if (
        this.angleIsWithinArc(angle, this.localStartAngle, this.localEndAngle)
      ) {
        return true;
      }
    }

    return false;
  }
}
