import type { Transform } from "../../math/transform.ts";
import { Vector2D } from "../../math/vector2D.ts";
import type { Camera } from "../../plane/camera.ts";
import {
  type Segment,
  SegmentType,
  type CentroidAndAreaContribution,
  type SerializedLineSegment,
  type LineIntersection,
} from "../../models.ts";
import type { QuadBounds } from "../../plane/segmentQuadtree.ts";
import type { Circle } from "../../plane/quadtree.ts";

export class LineSegment implements Segment {
  type: SegmentType = SegmentType.LINE;
  localStart: Vector2D;
  localEnd: Vector2D;
  localDisplacement: Vector2D;
  length: number;
  start: Vector2D;
  end: Vector2D;
  displacement: Vector2D;

  constructor(start: Vector2D, end: Vector2D, cloning: boolean = false) {
    this.localStart = start;
    this.localEnd = end;
    if (cloning) {
      this.localDisplacement = new Vector2D(0, 0);
      this.length = 0;
    } else {
      this.localDisplacement = end.subtract(start);
      this.length = this.localDisplacement.magnitude();
    }
    this.start = new Vector2D(0, 0);
    this.end = new Vector2D(0, 0);
    this.displacement = new Vector2D(0, 0);
  }

  static fromSerialized(serialized: SerializedLineSegment): LineSegment {
    return new LineSegment(
      new Vector2D(serialized.start[0], serialized.start[1]),
      new Vector2D(serialized.end[0], serialized.end[1])
    );
  }

  updateGlobal(transform: Transform) {
    transform.applyInPlace(this.localStart, this.start);
    transform.applyInPlace(this.localEnd, this.end);
    this.displacement.x = this.end.x - this.start.x;
    this.displacement.y = this.end.y - this.start.y;
  }

  rayIntersectionCount(point: Vector2D, local: boolean): number {
    const startX = local ? this.localStart.x : this.start.x;
    const startY = local ? this.localStart.y : this.start.y;
    const endX = local ? this.localEnd.x : this.end.x;
    const endY = local ? this.localEnd.y : this.end.y;

    // Check if the line segment is horizontal
    if (Math.abs(startY - endY) < Number.EPSILON) {
      return 0; // Horizontal lines don't count as intersections
    }

    // Check if the point's y is within the line segment's y range
    if (
      (point.y < startY && point.y < endY) ||
      (point.y > startY && point.y > endY)
    ) {
      return 0;
    }

    // Calculate the x-coordinate of the intersection point
    const t = (point.y - startY) / (endY - startY);
    const intersectionX = startX + t * (endX - startX);

    // Check if the intersection point is to the right of the test point
    if (intersectionX > point.x) {
      // Special handling for when the ray passes through a vertex
      if (Math.abs(point.y - startY) < Number.EPSILON) {
        return endY > startY ? 1 : 0;
      }
      if (Math.abs(point.y - endY) < Number.EPSILON) {
        return endY > startY ? 1 : 0;
      }
      return 1;
    }

    return 0;
  }

  globalClone(
    invert: boolean,
    tValues?: [[number, number], [Vector2D | null, Vector2D | null]]
  ): Segment {
    if (tValues) {
      const [[t1, t2], [start, end]] = tValues;
      if (t1 < 0 || t1 > 1 || t2 < 0 || t2 > 1) {
        throw new Error("t values must be between 0 and 1");
      }

      if (t1 > t2) {
        throw new Error("t1 must be less than t2");
      }

      const dx = this.end.x - this.start.x;
      const dy = this.end.y - this.start.y;

      const tDiff = t2 - t1;

      if (!invert) {
        const segment = new LineSegment(
          start ?? this.start.clone(),
          end ?? this.end.clone(),
          true
        );
        segment.localDisplacement.x = dx * tDiff;
        segment.localDisplacement.y = dy * tDiff;
        segment.length = this.length * tDiff;
        return segment;
      } else {
        const segment = new LineSegment(
          end ?? this.end.clone(),
          start ?? this.start.clone(),
          true
        );
        segment.localDisplacement.x = -dx * tDiff;
        segment.localDisplacement.y = -dy * tDiff;
        segment.length = this.length * tDiff;
        return segment;
      }
    } else {
      const p1 = this.start.clone();
      const p2 = this.end.clone();

      if (!invert) {
        const segment = new LineSegment(p1, p2, true);
        segment.localDisplacement.x = this.localDisplacement.x;
        segment.localDisplacement.y = this.localDisplacement.y;
        segment.length = this.length;
        return segment;
      } else {
        const segment = new LineSegment(p2, p1, true);
        segment.localDisplacement.x = -this.localDisplacement.x;
        segment.localDisplacement.y = -this.localDisplacement.y;
        segment.length = this.length;
        return segment;
      }
    }
  }

  clone(invert: boolean): Segment {
    const p1 = this.localStart.clone();
    const p2 = this.localEnd.clone();

    if (!invert) {
      const segment = new LineSegment(p1, p2, true);
      segment.localDisplacement.x = this.localDisplacement.x;
      segment.localDisplacement.y = this.localDisplacement.y;
      segment.length = this.length;
      return segment;
    } else {
      const segment = new LineSegment(p2, p1, true);
      segment.localDisplacement.x = -this.localDisplacement.x;
      segment.localDisplacement.y = -this.localDisplacement.y;
      segment.length = this.length;
      return segment;
    }
  }

  translate(dx: number, dy: number) {
    this.localStart.x += dx;
    this.localStart.y += dy;
    this.localEnd.x += dx;
    this.localEnd.y += dy;
  }

  getCentroidAndAreaContribution(local: boolean): CentroidAndAreaContribution {
    const startX = local ? this.localStart.x : this.start.x;
    const startY = local ? this.localStart.y : this.start.y;
    const endX = local ? this.localEnd.x : this.end.x;
    const endY = local ? this.localEnd.y : this.end.y;

    const shoelaceFactor = startX * endY - endX * startY;

    // Calculate moment of inertia contribution directly for Izz
    // Izz = integral of (x² + y²)dA
    const shoelaceSecondMomentOfArea =
      (startX * startX +
        startX * endX +
        endX * endX + // x² terms
        startY * startY +
        startY * endY +
        endY * endY) * // y² terms
      shoelaceFactor;

    return {
      shoelaceFactor,
      shoelaceCentroid: new Vector2D(
        (startX + endX) * shoelaceFactor,
        (startY + endY) * shoelaceFactor
      ),
      shoelaceSecondMomentOfArea: shoelaceSecondMomentOfArea,
    };
  }

  getLineIntersection(
    lineStart: Vector2D,
    lineEnd: Vector2D,
    local: boolean
  ): LineIntersection[] {
    const start = local ? this.localStart : this.start;
    const end = local ? this.localEnd : this.end;

    const denominator =
      (lineEnd.y - lineStart.y) * (end.x - start.x) -
      (lineEnd.x - lineStart.x) * (end.y - start.y);

    if (Math.abs(denominator) < 1e-10) {
      return []; // Lines are parallel
    }

    const ua =
      ((lineEnd.x - lineStart.x) * (start.y - lineStart.y) -
        (lineEnd.y - lineStart.y) * (start.x - lineStart.x)) /
      denominator;

    const ub =
      ((end.x - start.x) * (start.y - lineStart.y) -
        (end.y - start.y) * (start.x - lineStart.x)) /
      denominator;

    // Check if the intersection is within the line segment
    if (ua < 0 || ua > 1) {
      return [];
    }

    const point = new Vector2D(
      start.x + ua * (end.x - start.x),
      start.y + ua * (end.y - start.y)
    );

    if (!point.isValid()) {
      return [];
    }

    return [
      {
        point,
        t: ub,
        segment: this,
      },
    ];
  }

  serialize(): SerializedLineSegment {
    return {
      type: SegmentType.LINE,
      start: [this.localStart.x, this.localStart.y],
      end: [this.localEnd.x, this.localEnd.y],
    };
  }

  addToPath(camera: Camera, path: Path2D): void {
    const [endX, endY] = camera.worldToScreen(this.end.x, this.end.y);
    path.lineTo(endX, endY);
  }

  intersectsBounds(bounds: QuadBounds): boolean {
    // Using Cohen-Sutherland algorithm for line-rectangle intersection
    const INSIDE = 0; // 0000
    const LEFT = 1; // 0001
    const RIGHT = 2; // 0010
    const BOTTOM = 4; // 0100
    const TOP = 8; // 1000

    // Calculate outcodes for both endpoints
    const computeOutCode = (x: number, y: number): number => {
      let code = INSIDE;
      if (x < bounds.left) code |= LEFT;
      else if (x > bounds.right) code |= RIGHT;
      if (y < bounds.top) code |= TOP;
      else if (y > bounds.bottom) code |= BOTTOM;
      return code;
    };

    let outcode0 = computeOutCode(this.localStart.x, this.localStart.y);
    let outcode1 = computeOutCode(this.localEnd.x, this.localEnd.y);

    // Early return if both points are on the same side of any boundary
    if ((outcode0 & outcode1) !== 0) {
      return false;
    }

    // If both points are inside, line must intersect
    if (outcode0 === 0 && outcode1 === 0) {
      return true;
    }

    // At this point, line might intersect. At least one endpoint is outside.
    return true;
  }

  intersectsCircle(circle: Circle): boolean {
    // Transform to make calculations relative to line segment starting at origin
    const dx = this.localEnd.x - this.localStart.x;
    const dy = this.localEnd.y - this.localStart.y;

    // Translate circle center to be relative to line segment start
    const cx = circle.x - this.localStart.x;
    const cy = circle.y - this.localStart.y;

    // Calculate length of line segment squared
    const lengthSquared = dx * dx + dy * dy;

    // If length is zero, just check distance to endpoint
    if (lengthSquared === 0) {
      return cx * cx + cy * cy <= circle.radius * circle.radius;
    }

    // Calculate projection of circle center onto line segment
    const t = Math.max(0, Math.min(1, (cx * dx + cy * dy) / lengthSquared));

    // Calculate closest point on line segment to circle center
    const projectionX = t * dx;
    const projectionY = t * dy;

    // Calculate distance squared from circle center to closest point
    const distanceSquared =
      (cx - projectionX) * (cx - projectionX) +
      (cy - projectionY) * (cy - projectionY);

    // Compare with circle radius squared
    return distanceSquared <= circle.radius * circle.radius;
  }
}
