import { welzl } from "../math/utils.ts";
import { Vector2D } from "../math/vector2D.ts";
import {
  type Segment,
  type SerializedShape,
  SegmentType,
  type LineIntersection,
  type SerializedSegment,
} from "../models.ts";
import { Transform } from "../math/transform.ts";
import type { Camera } from "../plane/camera.ts";
import { CircleSegment } from "./segments/circleSegment.ts";
import { LineSegment } from "./segments/lineSegment.ts";
import { ArcSegment } from "./segments/arcSegment.ts";
import { findShapeIntersections } from "../geometry/intersection.ts";
import { SegmentQuadTree, type QuadBounds } from "../plane/segmentQuadtree.ts";
import type { Circle } from "../plane/quadtree.ts";
import { intersectionOrUnionShapes } from "../geometry/buildResultShape.ts";

export type BoundaryInfo = {
  startIndex: number;
  area: number;
  centroid: Vector2D;
  secondMomentOfArea: number;
};

export class Shape {
  segments: Segment[];
  boundaryInfo: BoundaryInfo[];
  area: number;
  localMec: {
    center: Vector2D;
    radius: number;
  };
  centroid: Vector2D;
  rotation: number;
  transform: Transform;
  secondMomentOfArea: number;
  cachedSerialized: SerializedShape | null = null;
  globalDirty: boolean = true;
  segmentQuadtree: SegmentQuadTree | null = null;

  constructor(
    segments: Segment[],
    boundaryInfo?: BoundaryInfo[],
    precomputed?: {
      area: number;
      centroid: Vector2D;
      secondMomentOfArea: number;
      mec?: {
        center: Vector2D;
        radius: number;
      };
    }
  ) {
    this.segments = segments;

    const { area, centroid, secondMomentOfArea } = precomputed
      ? precomputed
      : this.calculateAreaAndCentroid(true);
    this.area = area;
    this.centroid = centroid;
    this.recenter(centroid);
    this.localMec = precomputed?.mec ?? this.calculateMec();
    this.boundaryInfo = boundaryInfo ?? [];
    this.rotation = 0;
    this.secondMomentOfArea = secondMomentOfArea;

    this.transform = new Transform(centroid.x, centroid.y, 0);
    this.updateAllGlobal(centroid.x, centroid.y, 0);
  }

  updateQuadtree(): void {
    const bounds: QuadBounds = {
      left: this.localMec.center.x - this.localMec.radius,
      right: this.localMec.center.x + this.localMec.radius,
      top: this.localMec.center.y - this.localMec.radius,
      bottom: this.localMec.center.y + this.localMec.radius,
    };

    this.segmentQuadtree = new SegmentQuadTree(bounds);
    for (const [index, segment] of this.segments.entries()) {
      this.segmentQuadtree.insert({ segment, index });
    }
  }

  querySegmentQuadtree(circle: Circle): Set<number> {
    if (!this.segmentQuadtree) {
      this.updateQuadtree();
    }

    // Convert circle center to local coordinates
    const localCenter = this.transform.applyInverse(
      new Vector2D(circle.x, circle.y)
    );

    // Create local circle (radius doesn't need transformation since we're not scaling)
    const localCircle: Circle = {
      x: localCenter.x,
      y: localCenter.y,
      radius: circle.radius,
      index: circle.index,
    };

    return this.segmentQuadtree!.query(localCircle);
  }

  containsPoint(point: Vector2D): boolean {
    if (!this.segmentQuadtree) {
      this.updateQuadtree();
    }

    // Convert point to local coordinates
    const localPoint = this.transform.applyInverse(point);

    const found = this.segmentQuadtree!.queryHorizontalRay(localPoint);

    let count = 0;

    for (const index of found) {
      count += this.segments[index].rayIntersectionCount(localPoint, true);
    }

    return count % 2 === 1;
  }

  getPointOnPerimeter(): Vector2D {
    if (this.segments[0].type !== SegmentType.CIRCLE) {
      return (this.segments[0] as LineSegment).start;
    }
    return new Vector2D(
      (this.segments[0] as CircleSegment).center.x +
        (this.segments[0] as CircleSegment).radius,
      (this.segments[0] as CircleSegment).center.y
    );
  }

  updateAllGlobal(
    transformX: number,
    transformY: number,
    transformRotation: number,
    force: boolean = false
  ): void {
    if (!this.globalDirty && !force) {
      return;
    }

    this.centroid.x = transformX;
    this.centroid.y = transformY;
    this.rotation = transformRotation;

    this.transform = new Transform(transformX, transformY, transformRotation);

    for (const segment of this.segments) {
      segment.updateGlobal(this.transform);
    }
    this.globalDirty = false;
  }

  recenter(centroid: Vector2D): void {
    for (const segment of this.segments) {
      segment.translate(-centroid.x, -centroid.y);
    }
  }

  calculateMec(): {
    center: Vector2D;
    radius: number;
  } {
    const points: Vector2D[] = [];
    for (const segment of this.segments) {
      if (segment.type === SegmentType.LINE) {
        const line = segment as LineSegment;
        points.push(line.localStart);
      } else if (segment.type === SegmentType.ARC) {
        const arc = segment as ArcSegment;
        points.push(arc.localStart);
        points.push(arc.localArcMidpoint);
      } else if (segment.type === SegmentType.CIRCLE) {
        const circle = segment as CircleSegment;
        return {
          center: circle.localCenter,
          radius: circle.radius,
        };
      }
    }
    const mec = welzl(points);
    if (mec === null) {
      throw new Error("Minimum enclosing circle not found");
    }
    return {
      center: mec.center,
      radius: mec.radius,
    };
  }

  calculateAreaAndCentroid(local: boolean): {
    area: number;
    centroid: Vector2D;
    secondMomentOfArea: number;
  } {
    let shoelaceAreaSum = 0;
    let shoelaceCentroidSum = new Vector2D(0, 0);
    let shoelaceSecondMomentOfAreaSum = 0;

    let compositeAreaSum = 0;
    let compositeCentroidSum = new Vector2D(0, 0);
    let compositeSecondMomentOfAreaSum = 0;

    // First pass: collect all contributions
    for (const segment of this.segments) {
      const {
        shoelaceFactor,
        shoelaceCentroid,
        shoelaceSecondMomentOfArea,
        area,
        centroid,
        compositeSecondMomentOfArea,
      } = segment.getCentroidAndAreaContribution(local);

      // Handle polygonal (shoelace) contributions
      if (shoelaceFactor && shoelaceCentroid && shoelaceSecondMomentOfArea) {
        shoelaceAreaSum += shoelaceFactor;
        shoelaceCentroidSum = shoelaceCentroidSum.add(shoelaceCentroid);
        shoelaceSecondMomentOfAreaSum += shoelaceSecondMomentOfArea;
      }

      // Handle composite (circle) contributions
      if (area && centroid) {
        compositeAreaSum += area;
        compositeCentroidSum = compositeCentroidSum.add(
          centroid.multiply(area)
        );
        if (compositeSecondMomentOfArea) {
          compositeSecondMomentOfAreaSum += compositeSecondMomentOfArea;
        }
      }
    }

    // Calculate polygonal area and centroid
    const shoelaceArea = shoelaceAreaSum / 2;
    let shoelaceCentroid: Vector2D;
    if (shoelaceArea === 0) {
      shoelaceCentroid = new Vector2D(0, 0);
    } else {
      shoelaceCentroid = shoelaceCentroidSum.divide(6 * shoelaceArea);
    }

    // Add polygonal contribution to composite sums
    compositeAreaSum += shoelaceArea;
    compositeCentroidSum = compositeCentroidSum.add(
      shoelaceCentroid.multiply(shoelaceArea)
    );

    // Calculate final centroid
    const finalCentroid = compositeCentroidSum.divide(compositeAreaSum);

    // Calculate polygonal moment of inertia about centroid
    const polygonalIzz = shoelaceSecondMomentOfAreaSum / 12;
    const polygonalParallelAxisCorrection =
      shoelaceArea *
      (shoelaceCentroid.x * shoelaceCentroid.x +
        shoelaceCentroid.y * shoelaceCentroid.y);
    const polygonalSecondMomentOfArea =
      polygonalIzz - polygonalParallelAxisCorrection;

    let totalSecondMomentOfArea = polygonalSecondMomentOfArea;
    for (const segment of this.segments) {
      const { area, centroid, compositeSecondMomentOfArea } =
        segment.getCentroidAndAreaContribution(local);

      if (area && centroid && compositeSecondMomentOfArea) {
        // Distance from segment centroid to shape centroid
        const r = centroid.subtract(finalCentroid);
        const distanceSquared = r.lengthSquared();

        // Apply parallel axis theorem
        totalSecondMomentOfArea +=
          compositeSecondMomentOfArea + area * distanceSquared;
      }
    }

    return {
      area: compositeAreaSum,
      centroid: finalCentroid,
      secondMomentOfArea: totalSecondMomentOfArea,
    };
  }

  getLineIntersections(
    lineStart: Vector2D,
    lineEnd: Vector2D
  ): LineIntersection[] {
    const intersections: LineIntersection[] = [];

    for (const segment of this.segments) {
      if (segment.type === SegmentType.LINE) {
        const intersection = (segment as LineSegment).getLineIntersection(
          lineStart,
          lineEnd,
          false
        );
        if (intersection) {
          intersections.push(...intersection);
        }
      } else {
        const segmentIntersections = (
          segment as ArcSegment | CircleSegment
        ).getLineIntersection(lineStart, lineEnd, false);
        intersections.push(...segmentIntersections);
      }
    }

    // Sort intersections by t value
    intersections.sort((a, b) => a.t - b.t);

    return intersections;
  }

  serialize(): SerializedShape {
    if (this.cachedSerialized) {
      return this.cachedSerialized;
    }
    this.cachedSerialized = {
      segments: this.segments.map((segment) => segment.serialize()),
      boundaryIndices: this.boundaryInfo.map((info) => info.startIndex),
      area: this.area,
      mec: {
        center: this.localMec.center.toArray(),
        radius: this.localMec.radius,
      },
      centroid: this.centroid.toArray(),
      secondMomentOfArea: this.secondMomentOfArea,
    };
    return this.cachedSerialized;
  }

  popBoundary(): Shape | null {
    // Return null if there are no boundaries to pop
    if (this.boundaryInfo.length === 0) {
      return null;
    }

    const lastBoundaryIndex = this.boundaryInfo.pop()!.startIndex;
    const previousBoundaryIndex =
      this.boundaryInfo.length > 0
        ? this.boundaryInfo[this.boundaryInfo.length - 1].startIndex
        : 0;

    // Extract the segments for the new shape
    const removedSegments = this.segments.splice(
      previousBoundaryIndex,
      lastBoundaryIndex - previousBoundaryIndex
    );

    // Recalculate properties for the original shape
    const { area, centroid, secondMomentOfArea } =
      this.calculateAreaAndCentroid(true);
    this.area = area;
    this.centroid = centroid;
    this.secondMomentOfArea = secondMomentOfArea;

    this.recenter(centroid);

    this.localMec = this.calculateMec();

    // Create and return a new shape from the removed segments
    return new Shape(removedSegments);
  }

  contains(point: Vector2D, transform: Transform): boolean {
    const localPoint = transform.applyInverse(point);

    let count = 0;
    for (const segment of this.segments) {
      count += segment.rayIntersectionCount(localPoint, true);
    }
    return count % 2 === 1;
  }

  globalClone(): Shape {
    const clonedSegments = this.segments.map((segment) =>
      segment.globalClone(false)
    );

    // Handle boundary indices
    const clonedBoundaryInfo = [...this.boundaryInfo];

    // Create new shape with common properties
    const newShape = new Shape(clonedSegments, clonedBoundaryInfo, {
      area: this.area,
      centroid: this.centroid,
      secondMomentOfArea: this.secondMomentOfArea,
    });

    // Copy cached properties
    newShape.localMec = this.localMec;
    newShape.cachedSerialized = this.cachedSerialized;

    return newShape;
  }

  inverse(): Shape {
    const clonedSegments = this.segments
      .map((segment) => segment.clone(true))
      .reverse();

    const clonedBoundaryInfo = this.boundaryInfo
      .map((value) => ({
        startIndex: this.segments.length - value.startIndex,
        area: value.area,
        centroid: value.centroid,
        secondMomentOfArea: value.secondMomentOfArea,
      }))
      .sort((a, b) => a.startIndex - b.startIndex);

    const newShape = new Shape(clonedSegments, clonedBoundaryInfo, {
      area: -this.area,
      centroid: new Vector2D(0, 0),
      secondMomentOfArea: -this.secondMomentOfArea,
    });

    newShape.localMec = this.localMec;

    newShape.updateAllGlobal(
      this.centroid.x,
      this.centroid.y,
      this.rotation,
      true
    );

    return newShape;
  }

  subtract(
    other: Shape,
    mecs: {
      this: Circle;
      other: Circle;
    }
  ): Shape[] {
    const otherInverse = other.inverse();

    const thisPossibleSegments = this.querySegmentQuadtree(mecs.other);
    const otherPossibleSegments = other.querySegmentQuadtree(mecs.this);

    const intersections = findShapeIntersections(
      this,
      otherInverse,
      thisPossibleSegments,
      otherPossibleSegments
    );

    if (intersections.intersections === null) {
      return [];
    }

    const result = intersectionOrUnionShapes(
      [this, otherInverse],
      0,
      0,
      intersections.intersections,
      false
    );

    if (!result) {
      return [];
    }

    return result.shapes;
  }

  render(ctx: CanvasRenderingContext2D, camera: Camera, color: string): void {
    const path = new Path2D();

    for (const [index, segment] of this.segments.entries()) {
      if (this.boundaryInfo.some((info) => info.startIndex === index)) {
        if (
          segment.type === SegmentType.LINE ||
          segment.type === SegmentType.ARC
        ) {
          const lineSegment = segment as LineSegment;
          const [startX, startY] = camera.worldToScreen(
            lineSegment.start.x,
            lineSegment.start.y
          );
          path.moveTo(startX, startY);
        } else if (segment.type === SegmentType.CIRCLE) {
          const circleSegment = segment as CircleSegment;
          // Scale the radius when moving to the circle's edge
          const screenRadius = camera.worldToScreenDistance(
            circleSegment.radius
          );
          const [centerX, centerY] = camera.worldToScreen(
            circleSegment.center.x,
            circleSegment.center.y
          );
          path.moveTo(centerX + screenRadius, centerY);
        }
      }
      segment.addToPath(camera, path);
    }

    path.closePath();

    ctx.fillStyle = color;

    ctx.fill(path, "evenodd");
    ctx.stroke(path);
  }

  renderQuadtree(ctx: CanvasRenderingContext2D, camera: Camera): void {
    // Set up initial styling
    ctx.save();
    ctx.lineWidth = 1;

    const transform = new Transform(
      this.centroid.x,
      this.centroid.y,
      this.rotation
    );

    const renderQuad = (quad: SegmentQuadTree, depth: number) => {
      // Different colors for different depths
      const alpha = Math.max(0.2, 1 - depth * 0.2);
      ctx.strokeStyle = `rgba(0, 255, 0, ${alpha})`;

      // Transform all four corners of the quad from local to global space
      const corners = [
        new Vector2D(quad.bounds.left, quad.bounds.top),
        new Vector2D(quad.bounds.right, quad.bounds.top),
        new Vector2D(quad.bounds.right, quad.bounds.bottom),
        new Vector2D(quad.bounds.left, quad.bounds.bottom),
      ];

      // Transform corners to global space
      const globalCorners = corners.map((corner) => {
        const global = new Vector2D(0, 0);
        transform.applyInPlace(corner, global);
        return global;
      });

      // Convert to screen coordinates and draw
      ctx.beginPath();
      const [startX, startY] = camera.worldToScreen(
        globalCorners[0].x,
        globalCorners[0].y
      );
      ctx.moveTo(startX, startY);

      for (let i = 1; i < globalCorners.length; i++) {
        const [x, y] = camera.worldToScreen(
          globalCorners[i].x,
          globalCorners[i].y
        );
        ctx.lineTo(x, y);
      }
      ctx.closePath();

      // if (quad.collidedThisFrame && quad.children.length === 0) {
      //   ctx.fillStyle = "rgba(0, 255, 0, 0.2)";
      //   ctx.fill();
      //   quad.collidedThisFrame = false;
      // }

      ctx.stroke();

      // Recursively render children
      if (quad.children.length > 0) {
        for (const child of quad.children) {
          renderQuad(child, depth + 1);
        }
      }
    };

    if (!this.segmentQuadtree) {
      this.updateQuadtree();
    }

    // Start recursion with root quad
    renderQuad(this.segmentQuadtree!, 0);

    ctx.restore();
  }

  static fromSerialized(serialized: SerializedShape): Shape {
    const shape = new Shape(
      serialized.segments.map((segment) => segmentFromSerialized(segment)),
      serialized.boundaryIndices.map((index) => ({
        startIndex: index,
        area: 0,
        centroid: new Vector2D(0, 0),
        secondMomentOfArea: 0,
      })),
      {
        area: serialized.area,
        centroid: new Vector2D(serialized.centroid[0], serialized.centroid[1]),
        secondMomentOfArea: serialized.secondMomentOfArea,
      }
    );
    shape.localMec = {
      center: new Vector2D(serialized.mec.center[0], serialized.mec.center[1]),
      radius: serialized.mec.radius,
    };
    shape.cachedSerialized = serialized;
    return shape;
  }
}

export function segmentFromSerialized(serialized: SerializedSegment): Segment {
  switch (serialized.type) {
    case SegmentType.LINE:
      return LineSegment.fromSerialized(serialized);
    case SegmentType.ARC:
      return ArcSegment.fromSerialized(serialized);
    case SegmentType.CIRCLE:
      return CircleSegment.fromSerialized(serialized);
  }
}
