import { Vector2D } from "../math/vector2D";
import {
  type IntersectionPoint,
  type ShapeMotionInfo,
  type IntersectionShape,
  SegmentType,
  type Segment,
} from "../models";
import type { ArcSegment } from "../shapes/segments/arcSegment";
import type { LineSegment } from "../shapes/segments/lineSegment";
import { Shape } from "../shapes/shape";
import { SegmentShapeBuilder } from "../shapes/shapeBuilder";

export function intersectionOrUnionShapes(
  shapes: [Shape, Shape],
  body1Index: number,
  body2Index: number,
  intersections: [IntersectionPoint[], IntersectionPoint[]] | boolean,
  union: boolean = false,
  motionInfo?: ShapeMotionInfo
): IntersectionShape | null {
  if (typeof intersections === "boolean") {
    if (!motionInfo || union) {
      if (intersections) {
        // Shape1 encloses shape2
        if (union) {
          return {
            shapes: [shapes[0].globalClone()],
            body1Index,
            body2Index,
            centroid: shapes[0].centroid.clone(),
            rotation: shapes[0].rotation,
            normal: new Vector2D(0, 0),
            penetrationDistance: 0,
            penetrationVector: new Vector2D(0, 0),
            enclosingShape: body1Index,
          };
        } else {
          return {
            shapes: [shapes[1].globalClone()],
            body1Index,
            body2Index,
            centroid: shapes[1].centroid.clone(),
            rotation: shapes[1].rotation,
            normal: new Vector2D(0, 0),
            penetrationDistance: 0,
            penetrationVector: new Vector2D(0, 0),
            enclosingShape: body2Index,
          };
        }
      }

      if (!intersections) {
        // Shape2 encloses shape1
        if (union) {
          return {
            shapes: [shapes[1].globalClone()],
            body1Index,
            body2Index,
            centroid: shapes[1].centroid.clone(),
            rotation: shapes[1].rotation,
            normal: new Vector2D(0, 0),
            penetrationDistance: 0,
            penetrationVector: new Vector2D(0, 0),
            enclosingShape: body2Index,
          };
        } else {
          return {
            shapes: [shapes[0].globalClone()],
            body1Index,
            body2Index,
            centroid: shapes[0].centroid.clone(),
            rotation: shapes[0].rotation,
            normal: new Vector2D(0, 0),
            penetrationDistance: 0,
            penetrationVector: new Vector2D(0, 0),
            enclosingShape: body1Index,
          };
        }
      }
    } else {
      // Calculate relative motion
      const enclosed = intersections ? shapes[1] : shapes[0];
      const enclosing = intersections ? shapes[0] : shapes[1];
      const info = intersections ? motionInfo.shape2 : motionInfo.shape1;
      const otherInfo = intersections ? motionInfo.shape1 : motionInfo.shape2;

      const enclosedCenter = new Vector2D(info.x, info.y);

      // Calculate velocities at the enclosed shape's center
      // For shape1: v = v_center + ω × r
      const r1 = enclosedCenter.subtract(
        new Vector2D(otherInfo.x, otherInfo.y)
      );
      const tangentialVelocity1 = new Vector2D(
        -otherInfo.angularVelocity * r1.y, // In 2D, cross product gives (-ωy, ωx)
        otherInfo.angularVelocity * r1.x
      );
      const v1 = new Vector2D(otherInfo.vx, otherInfo.vy).add(
        tangentialVelocity1
      );

      // For shape2: similar calculation
      const r2 = enclosedCenter.subtract(new Vector2D(info.x, info.y));
      const tangentialVelocity2 = new Vector2D(
        -info.angularVelocity * r2.y,
        info.angularVelocity * r2.x
      );
      const v2 = new Vector2D(info.vx, info.vy).add(tangentialVelocity2);

      // Calculate relative velocity at the enclosed shape's center
      const relativeVelocity = v2.subtract(v1);

      const lineStart = enclosedCenter.clone();

      // Check if relative velocity is too small
      const MINIMUM_VELOCITY = 0.1;
      let moveVector: Vector2D;
      let lineEnd: Vector2D;
      if (
        relativeVelocity.lengthSquared() <
        MINIMUM_VELOCITY * MINIMUM_VELOCITY
      ) {
        const randomAngle = Math.random() * Math.PI * 2;
        moveVector = new Vector2D(Math.cos(randomAngle), Math.sin(randomAngle));
        lineEnd = lineStart.subtract(moveVector);
      } else {
        moveVector = relativeVelocity.normalize();
        lineEnd = lineStart.subtract(relativeVelocity);
      }

      // Use enclosed shape's center as the line start point
      // Create end point by extending backwards along relative velocity

      // Initial penetration direction is the relative velocity

      const result: IntersectionShape = {
        shapes: [enclosed.globalClone()],
        body1Index,
        body2Index,
        centroid: enclosedCenter,
        rotation: enclosed.rotation,
        normal: moveVector.normalize(),
        penetrationDistance: moveVector.length(),
        penetrationVector: moveVector,
        enclosingShape: intersections ? body2Index : body1Index,
      };

      // Get intersection points from both shapes
      const intersectionsOnEnclosed = enclosed.getLineIntersections(
        lineStart,
        lineEnd
      );

      const intersectionsOnEnclosing = enclosing.getLineIntersections(
        lineStart,
        lineEnd
      );

      const firstOnEnclosed = intersectionsOnEnclosed[0];

      if (!firstOnEnclosed) {
        return result;
      }

      // Find the first intersection on the enclosing shape after the last t value of the intersections on the enclosed shape
      const firstOnEnclosing = intersectionsOnEnclosing.find(
        (intersection) => intersection.t > firstOnEnclosed.t
      );

      if (!firstOnEnclosing) {
        return result;
      }
      // If the enclosing is the first shape:
      if (enclosing === shapes[0]) {
        const penetrationVector = firstOnEnclosed.point.subtract(
          firstOnEnclosing.point
        );

        result.penetrationVector = penetrationVector;
        result.penetrationDistance = penetrationVector.length();

        result.normal = result.penetrationVector.normalize();

        return result;
      } else {
        const penetrationVector = firstOnEnclosing.point.subtract(
          firstOnEnclosed.point
        );

        result.penetrationVector = penetrationVector;
        result.penetrationDistance = penetrationVector.length();

        result.normal = result.penetrationVector.normalize();

        return result;
      }
    }
  }

  let normalSum = new Vector2D(0, 0);
  let rootIntersection = intersections[0][0].point;
  let points: Vector2D[] = [];

  const resultShapes: Shape[] = [];
  let shapeBuilder = new SegmentShapeBuilder();
  let totalNormal: Vector2D = new Vector2D(0, 0);
  let totalCentroid = new Vector2D(0, 0);
  let totalArea = 0;

  let currentIntersection: IntersectionPoint;
  let currentShape = shapes[0];
  let currentIntersections = intersections[0];
  let nextIntersection: IntersectionPoint;
  let count = 0;
  let forwards = true;
  let direction = intersections[0][0].entering ? 1 : -1;

  try {
    currentIntersection = currentIntersections[0];

    while (true) {
      count++;

      if (count > 100) {
        throw new Error("Infinite loop");
      }

      currentIntersection.visited = true;

      forwards = union
        ? !currentIntersection.entering
        : currentIntersection.entering;

      if (forwards) {
        nextIntersection = currentIntersections[currentIntersection.nextIndex!];
      } else {
        nextIntersection =
          currentIntersections[currentIntersection.previousIndex!];
      }

      if (currentShape === shapes[0]) {
        normalSum = normalSum.add(
          nextIntersection.point.subtract(currentIntersection.point)
        );
      }

      // If the next intersection is the same as the current one, error
      if (nextIntersection === currentIntersection) {
        // console.error(`Next intersection is the same as the current one`);
        break;
      }

      if (
        currentShape.segments[currentIntersection.segmentIndex].type ===
        SegmentType.CIRCLE
      ) {
        if (forwards) {
          const segment = currentShape.segments[
            currentIntersection.segmentIndex
          ].globalClone(false, [
            [Math.abs(currentIntersection.t), Math.abs(nextIntersection.t)],
            [currentIntersection.point.clone(), nextIntersection.point.clone()],
          ]) as ArcSegment;
          shapeBuilder.addToEnd(segment);

          points.push(segment.localStart);
          points.push(segment.localArcMidpoint);
          points.push(segment.localEnd);
        } else {
          const segment = currentShape.segments[
            currentIntersection.segmentIndex
          ].globalClone(false, [
            [Math.abs(nextIntersection.t), Math.abs(currentIntersection.t)],
            [nextIntersection.point.clone(), currentIntersection.point.clone()],
          ]) as ArcSegment;
          shapeBuilder.addToStart(segment);

          points.push(segment.localStart);
          points.push(segment.localArcMidpoint);
          points.push(segment.localEnd);
        }
      } else {
        if (forwards) {
          addSegmentsToShape(
            shapeBuilder,
            currentShape,
            currentIntersection,
            nextIntersection,
            points
          );
        } else {
          addSegmentsToShapeBackwards(
            shapeBuilder,
            currentShape,
            currentIntersection,
            nextIntersection,
            points
          );
        }
      }

      // Get the counterpart of the next intersection
      const counterpart = nextIntersection.counterpart;
      nextIntersection.visited = true;

      currentShape = currentShape === shapes[0] ? shapes[1] : shapes[0];
      currentIntersections =
        currentShape === shapes[0] ? intersections[0] : intersections[1];

      // If the counterpart is visited, try to find another unvisited intersection
      if (counterpart?.visited) {
        let minDistance = Infinity;
        let maxDistance = -Infinity;
        // Find the penetration distance
        const normal = normalSum.perpendicular().normalize();

        for (const point of points) {
          const penetration = point.subtract(rootIntersection).dot(normal);
          if (penetration > maxDistance) {
            maxDistance = penetration;
          }
          if (penetration < minDistance) {
            minDistance = penetration;
          }
        }

        const penetrationDistance = maxDistance - minDistance;

        totalNormal = totalNormal.add(
          normal.multiply(penetrationDistance * direction)
        );

        normalSum = new Vector2D(0, 0);
        points = [];

        currentIntersection = currentIntersections.find(
          (intersection) => !intersection.visited
        )!;

        // If there are no more unvisited intersections, we're done
        if (currentIntersection === undefined) {
          // Push the last shape
          const { shape, centroid, rotation } =
            shapeBuilder.buildWithCentroid();
          const area = shape.area;

          if (resultShapes.length === 0) {
            totalCentroid = centroid.multiply(area);
            totalArea = area;
          } else {
            totalCentroid = totalCentroid.add(centroid.multiply(area));
            totalArea += area;
          }

          resultShapes.push(shape);
          break;
        }

        rootIntersection = currentIntersection.point;
        direction = currentIntersection.entering ? 1 : -1;

        const { shape, centroid, rotation } = shapeBuilder.buildWithCentroid();
        const area = shape.area;

        if (resultShapes.length === 0) {
          totalCentroid = centroid.multiply(area);
          totalArea = area;
        } else {
          totalCentroid = totalCentroid.add(centroid.multiply(area));
          totalArea += area;
        }

        resultShapes.push(shape);

        shapeBuilder = new SegmentShapeBuilder();
      } else {
        // Set the current intersection to the counterpart
        currentIntersection = counterpart!;
      }
    }

    return {
      body1Index,
      body2Index,
      shapes: resultShapes,
      centroid: totalCentroid.divide(totalArea),
      rotation: 0,
      normal: totalNormal.normalize(),
      penetrationDistance: totalNormal.length(),
      penetrationVector: totalNormal,
      enclosingShape: null,
    };
  } catch (e) {
    console.error("Error building shape", e);
    return null;
  }
}

function addSegmentsToShapeBackwards(
  shapeBuilder: SegmentShapeBuilder,
  shape: Shape,
  intersection: IntersectionPoint,
  nextIntersection: IntersectionPoint,
  points: Vector2D[]
): void {
  // If the intersections are on the same segment, and the first t value is less than the second, create a subsegment of the segment
  if (
    intersection.segmentIndex === nextIntersection.segmentIndex &&
    intersection.t > nextIntersection.t
  ) {
    const subsegment = shape.segments[intersection.segmentIndex].globalClone(
      false,
      [
        [nextIntersection.t, intersection.t],
        [nextIntersection.point.clone(), intersection.point.clone()],
      ]
    ) as ArcSegment;
    shapeBuilder.addToStart(subsegment);

    points.push(subsegment.localStart);
    points.push(subsegment.localEnd);

    if (subsegment.type === SegmentType.ARC) {
      points.push(subsegment.localArcMidpoint);
    }

    return;
  }

  const startSegment = shape.segments[intersection.segmentIndex] as
    | LineSegment
    | ArcSegment;

  const clonedStartSegment = startSegment.globalClone(false, [
    [0, intersection.t],
    [null, intersection.point.clone()],
  ]) as ArcSegment;
  shapeBuilder.addToStart(clonedStartSegment);

  points.push(clonedStartSegment.localStart);
  points.push(clonedStartSegment.localEnd);

  if (clonedStartSegment.type === SegmentType.ARC) {
    points.push(clonedStartSegment.localArcMidpoint);
  }

  // Then, add the other segments up until the next intersection by slicing the segments array
  // Check for wrapping
  let segmentsToAdd: Segment[];
  if (nextIntersection.segmentIndex < intersection.segmentIndex) {
    segmentsToAdd = shape.segments.slice(
      nextIntersection.segmentIndex + 1,
      intersection.segmentIndex
    );
  } else {
    // To end
    segmentsToAdd = shape.segments.slice(nextIntersection.segmentIndex + 1);

    // From start
    segmentsToAdd.push(...shape.segments.slice(0, intersection.segmentIndex));
  }
  // Traverse backwards
  for (let i = segmentsToAdd.length - 1; i >= 0; i--) {
    const clonedSegment = segmentsToAdd[i].globalClone(false) as ArcSegment;
    shapeBuilder.addToStart(clonedSegment);

    points.push(clonedSegment.localStart);
    points.push(clonedSegment.localEnd);

    if (clonedSegment.type === SegmentType.ARC) {
      points.push(clonedSegment.localArcMidpoint);
    }
  }

  const endSegment = shape.segments[nextIntersection.segmentIndex] as
    | LineSegment
    | ArcSegment;

  const clonedEndSegment = endSegment.globalClone(false, [
    [nextIntersection.t, 1],
    [nextIntersection.point.clone(), null],
  ]) as ArcSegment;
  shapeBuilder.addToStart(clonedEndSegment);

  points.push(clonedEndSegment.localStart);
  points.push(clonedEndSegment.localEnd);

  if (clonedEndSegment.type === SegmentType.ARC) {
    points.push(clonedEndSegment.localArcMidpoint);
  }
}

function addSegmentsToShape(
  shapeBuilder: SegmentShapeBuilder,
  shape: Shape,
  intersection: IntersectionPoint,
  nextIntersection: IntersectionPoint,
  points: Vector2D[]
): void {
  // If the intersections are on the same segment, and the first t value is less than the second, create a subsegment of the segment
  if (
    intersection.segmentIndex === nextIntersection.segmentIndex &&
    intersection.t < nextIntersection.t
  ) {
    const subsegment = shape.segments[intersection.segmentIndex].globalClone(
      false,
      [
        [intersection.t, nextIntersection.t],
        [intersection.point.clone(), nextIntersection.point.clone()],
      ]
    ) as ArcSegment;
    shapeBuilder.addToEnd(subsegment);

    points.push(subsegment.localStart);
    points.push(subsegment.localEnd);

    if (subsegment.type === SegmentType.ARC) {
      points.push(subsegment.localArcMidpoint);
    }

    return;
  }

  // If the intersections are on different segments, first create a subsegment from the t value of the intersection to the end of the segment
  const startSegment = shape.segments[intersection.segmentIndex] as
    | LineSegment
    | ArcSegment;

  const clonedStartSegment = startSegment.globalClone(false, [
    [intersection.t, 1],
    [intersection.point.clone(), null],
  ]) as ArcSegment;
  shapeBuilder.addToEnd(clonedStartSegment);

  points.push(clonedStartSegment.localStart);
  points.push(clonedStartSegment.localEnd);

  if (clonedStartSegment.type === SegmentType.ARC) {
    points.push(clonedStartSegment.localArcMidpoint);
  }

  // Then, add the other segments up until the next intersection by slicing the segments array
  // Check for wrapping
  let segmentsToAdd: Segment[];
  if (intersection.segmentIndex < nextIntersection.segmentIndex) {
    segmentsToAdd = shape.segments.slice(
      intersection.segmentIndex + 1,
      nextIntersection.segmentIndex
    );
  } else {
    // To end
    segmentsToAdd = shape.segments.slice(intersection.segmentIndex + 1);

    // From start
    segmentsToAdd.push(
      ...shape.segments.slice(0, nextIntersection.segmentIndex)
    );
  }
  segmentsToAdd.forEach((segment) => {
    const clonedSegment = segment.globalClone(false) as ArcSegment;
    shapeBuilder.addToEnd(clonedSegment);

    points.push(clonedSegment.localStart);
    points.push(clonedSegment.localEnd);

    if (clonedSegment.type === SegmentType.ARC) {
      points.push(clonedSegment.localArcMidpoint);
    }
  });

  // Finally, add the subsegment from the next intersection from the start t value to the end of the segment
  const nextSegment = shape.segments[nextIntersection.segmentIndex] as
    | LineSegment
    | ArcSegment;

  const clonedNextSegment = nextSegment.globalClone(false, [
    [0, nextIntersection.t],
    [null, nextIntersection.point.clone()],
  ]) as ArcSegment;
  shapeBuilder.addToEnd(clonedNextSegment);

  points.push(clonedNextSegment.localStart);
  points.push(clonedNextSegment.localEnd);

  if (clonedNextSegment.type === SegmentType.ARC) {
    points.push(clonedNextSegment.localArcMidpoint);
  }
}
