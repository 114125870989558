import { Vector2D } from "../math/vector2D";
import type { Camera } from "../plane/camera";

class CCDSegment {
  position: Vector2D;
  angle: number;
  length: number;

  constructor(position: Vector2D, length: number) {
    this.position = position;
    this.angle = 0;
    this.length = length;
  }
}

export class CCDLimb {
  length: number;
  segments: CCDSegment[] = [];
  /** Maximum number of times to attempt reaching the target
   * Higher = more accurate but more computationally expensive
   * Lower = faster but might not reach target
   * Typical range: 10-100
   */
  maxIterations: number;
  /** How close we need to get to target before stopping (in world units)
   * Higher = faster but less precise
   * Lower = more precise but might take more iterations
   * Typical range: 0.001-1.0
   */
  tolerance: number;
  /** How much of each angle correction to apply
   * Higher (closer to 1.0) = faster but might overshoot/oscillate
   * Lower (closer to 0) = more stable but slower to reach target
   * Typical range: 0.1-0.5
   */
  dampingFactor: number;
  /** Controls how aggressively weight falls off along the chain
   * 0 = all segments have equal influence
   * 1 = linear falloff from end to root
   * 2+ = exponential falloff (higher = more aggressive)
   * Typical range: 1-3
   */
  weightDistributionPower: number;
  /** Minimum weight that any segment can have
   * Higher = more even distribution but less precise control
   * Lower = more precise but might make some segments too weak
   * Typical range: 0.05-0.2
   */
  baseWeight: number;

  constructor({
    length,
    numSegments = 2,
    maxIterations = 100,
    tolerance = 0.001,
    dampingFactor = 0.5,
    weightDistributionPower = 3,
    baseWeight = 0.1,
  }: {
    length: number;
    numSegments?: number;
    maxIterations?: number;
    tolerance?: number;
    dampingFactor?: number;
    weightDistributionPower?: number;
    baseWeight?: number;
  }) {
    this.length = length;
    this.maxIterations = maxIterations;
    this.tolerance = tolerance;
    this.dampingFactor = dampingFactor;
    this.weightDistributionPower = weightDistributionPower;
    this.baseWeight = baseWeight;
    const segmentLength = length / numSegments;

    // Create segments starting at origin
    for (let i = 0; i < numSegments; i++) {
      const position = new Vector2D(0, 0);
      const segment = new CCDSegment(position, segmentLength);
      this.segments.push(segment);
    }

    // Initialize segment positions
    this.updateSegmentPositions(0);
  }

  private updateSegmentPositions(startIndex: number): void {
    for (let i = startIndex + 1; i < this.segments.length; i++) {
      const prevSegment = this.segments[i - 1];
      const currentSegment = this.segments[i];

      // Calculate total angle up to this segment
      const totalAngle = this.segments
        .slice(0, i)
        .reduce((sum, seg) => sum + seg.angle, 0);

      // Update position based on previous segment
      currentSegment.position = new Vector2D(
        prevSegment.position.x + Math.cos(totalAngle) * prevSegment.length,
        prevSegment.position.y + Math.sin(totalAngle) * prevSegment.length
      );
    }
  }

  private getEndEffectorPosition(): Vector2D {
    const lastSegment = this.segments[this.segments.length - 1];
    const totalAngle = this.segments.reduce((sum, seg) => sum + seg.angle, 0);

    return new Vector2D(
      lastSegment.position.x + Math.cos(totalAngle) * lastSegment.length,
      lastSegment.position.y + Math.sin(totalAngle) * lastSegment.length
    );
  }

  private positionFromRoot(rootPosition: Vector2D): void {
    // Set first segment to root position
    this.segments[0].position = rootPosition.clone();

    // Update all other segments
    this.updateSegmentPositions(0);
  }

  private getSegmentWeight(index: number): number {
    // Calculate how far along the chain this segment is (1 = end, 0 = root)
    const normalizedPosition = 1 - index / (this.segments.length - 1);

    // Apply power curve to create non-linear distribution
    // Add baseWeight to ensure root segments still have some influence
    return (
      Math.pow(normalizedPosition, this.weightDistributionPower) *
        (1 - this.baseWeight) +
      this.baseWeight
    );
  }

  private straightenTowards(target: Vector2D): void {
    // Calculate direction from root to target
    const rootPos = this.segments[0].position;
    const toTarget = target.subtract(rootPos);

    // Calculate angle to target
    const angleToTarget = Math.atan2(toTarget.y, toTarget.x);

    // Set first segment to point towards target
    this.segments[0].angle = angleToTarget;

    // Reset all other angles to 0 (straight relative to first segment)
    for (let i = 1; i < this.segments.length; i++) {
      this.segments[i].angle = 0;
    }

    // Update positions to form straight line towards target
    this.updateSegmentPositions(0);
  }

  update(rootPosition: Vector2D, target: Vector2D): void {
    this.positionFromRoot(rootPosition);

    // // Check if target is beyond reach
    // const distanceToTarget = rootPosition.distanceTo(target);
    // const isTargetBeyondReach = distanceToTarget > this.length;

    // if (isTargetBeyondReach) {
    //   // Straighten the limb towards target
    //   this.straightenTowards(target);
    //   return; // Skip normal CCD if we're beyond reach
    // }

    // Normal CCD algorithm for reachable targets
    for (let iteration = 0; iteration < this.maxIterations; iteration++) {
      const endEffector = this.getEndEffectorPosition();

      if (endEffector.distanceTo(target) < this.tolerance) {
        break;
      }

      // Iterate through segments from end to start
      for (let i = this.segments.length - 1; i >= 0; i--) {
        const currentSegment = this.segments[i];
        const endEffector = this.getEndEffectorPosition();

        const toEnd = endEffector.subtract(currentSegment.position);
        const toTarget = target.subtract(currentSegment.position);

        const angle = Math.atan2(
          toTarget.y * toEnd.x - toTarget.x * toEnd.y,
          toTarget.x * toEnd.x + toTarget.y * toEnd.y
        );

        // Apply both damping and weight distribution
        const segmentWeight = this.getSegmentWeight(i);
        const dampedAngle = angle * this.dampingFactor * segmentWeight;

        // Update segment angle
        currentSegment.angle += dampedAngle;

        this.updateSegmentPositions(i);
      }
    }
  }

  // // Render as circles
  // render(ctx: CanvasRenderingContext2D, camera: Camera): void {
  //   // Set drawing style
  //   ctx.fillStyle = "#ffffff";

  //   const baseWorldRadius = this.segments[0].length * 0.5;

  //   // Draw circles at each segment position with tapering size
  //   this.segments.forEach((segment, index) => {
  //     // Calculate taper factor (1.0 at root, smaller towards end)
  //     const taperFactor = Math.pow(1 - index / this.segments.length, 0.2);

  //     // Apply taper to world radius and convert to screen space
  //     const worldRadius = baseWorldRadius * taperFactor;
  //     const screenRadius = camera.worldToScreenDistance(worldRadius);

  //     const [sx, sy] = camera.worldToScreen(
  //       segment.position.x,
  //       segment.position.y
  //     );

  //     ctx.beginPath();
  //     ctx.arc(sx, sy, screenRadius, 0, Math.PI * 2);
  //     ctx.fill();

  //     // If this is the last segment, draw a circle at its end point too
  //     if (index === this.segments.length - 1) {
  //       const totalAngle = this.segments.reduce(
  //         (sum, seg) => sum + seg.angle,
  //         0
  //       );
  //       const endPoint = new Vector2D(
  //         segment.position.x + Math.cos(totalAngle) * segment.length,
  //         segment.position.y + Math.sin(totalAngle) * segment.length
  //       );

  //       const [endX, endY] = camera.worldToScreen(endPoint.x, endPoint.y);

  //       ctx.beginPath();
  //       ctx.arc(endX, endY, screenRadius, 0, Math.PI * 2);
  //       ctx.fill();
  //     }
  //   });

  //   // Draw end effector (which should be at the same position as the last segment's end)
  //   const endEffector = this.getEndEffectorPosition();
  //   const [endX, endY] = camera.worldToScreen(endEffector.x, endEffector.y);

  //   const endEffectorTaper = Math.pow(
  //     1 - this.segments.length / this.segments.length,
  //     0.2
  //   );
  //   const endEffectorRadius = camera.worldToScreenDistance(
  //     baseWorldRadius * endEffectorTaper
  //   );

  //   ctx.beginPath();
  //   ctx.arc(endX, endY, endEffectorRadius, 0, Math.PI * 2);
  //   ctx.fill();
  // }

  // Render as lines
  render(ctx: CanvasRenderingContext2D, camera: Camera): void {
    // Set drawing style
    ctx.strokeStyle = "rgb(50, 100, 50)";
    ctx.lineWidth = 20 * camera.zoom;

    ctx.beginPath();

    // Start from first segment's position (which was set in update)
    const [screenX, screenY] = camera.worldToScreen(
      this.segments[0].position.x,
      this.segments[0].position.y
    );
    ctx.moveTo(screenX, screenY);

    // Draw lines through all segments using their stored positions
    for (let i = 1; i < this.segments.length; i++) {
      const segment = this.segments[i];
      const [sx, sy] = camera.worldToScreen(
        segment.position.x,
        segment.position.y
      );
      ctx.lineTo(sx, sy);
    }

    // Draw final line to end effector
    const endEffector = this.getEndEffectorPosition();
    const [endX, endY] = camera.worldToScreen(endEffector.x, endEffector.y);
    ctx.lineTo(endX, endY);

    ctx.stroke();
  }
}
