import { Vector2D } from "../math/vector2D.ts";
import { Shape } from "../shapes/shape.ts";
import { VertexShapeBuilder } from "../shapes/shapeBuilder.ts";
import type { BodyState, Material } from "../models.ts";
import { Body } from "../plane/body.ts";
import { getRandomColor } from "../rendering/color.ts";
export type VarietyGenerationParams = {
  boundaryRadius: number;
  smallCount: number;
  mediumCount: number;
  largeCount: number;
  planeId?: string;
};

function getMaterial(): Material {
  return {
    density: 1,
    restitution: 0.4,
    staticFriction: 0.6,
    dynamicFriction: 0.4,
    color: getRandomColor(),
  };
}

// Add this helper at the top of the file
function getSecureRandom(): number {
  // Use crypto.getRandomValues which is available in both environments
  const array = new Uint32Array(1);
  crypto.getRandomValues(array);
  return array[0] / (0xffffffff + 1); // Convert to [0, 1) range
}

function getRandomPointInCircle(radius: number): [number, number] {
  const angle = getSecureRandom() * Math.PI * 2;
  const distance = getSecureRandom() * radius;
  return [Math.cos(angle) * distance, Math.sin(angle) * distance];
}

interface RandomShapeParams {
  count: number;
  boundaryRadius: number;
  minSize: number;
  maxSize: number;
}

interface RandomCapsuleParams extends RandomShapeParams {
  minSagitta: number;
  maxSagitta: number;
}

interface RandomPolygonParams extends RandomShapeParams {
  minSides: number;
  maxSides: number;
}

interface RandomVelocityParams {
  minLinearVelocity: number;
  maxLinearVelocity: number;
  minAngularVelocity: number;
  maxAngularVelocity: number;
}

function getRandomVelocity(params: RandomVelocityParams): {
  velocity: Vector2D;
  angularVelocity: number;
} {
  const angle = getSecureRandom() * Math.PI * 2;
  const speed =
    getSecureRandom() * (params.maxLinearVelocity - params.minLinearVelocity) +
    params.minLinearVelocity;
  const angularVelocity =
    getSecureRandom() *
      (params.maxAngularVelocity - params.minAngularVelocity) +
    params.minAngularVelocity;
  return {
    velocity: new Vector2D(Math.cos(angle) * speed, Math.sin(angle) * speed),
    angularVelocity,
  };
}

function generateRandomRectangles(
  params: RandomShapeParams & RandomVelocityParams,
  nextId: () => string
): {
  body: Body;
  state: BodyState;
}[] {
  const { count, boundaryRadius, minSize, maxSize } = params;
  const bodies: {
    body: Body;
    state: BodyState;
  }[] = [];
  for (let i = 0; i < count; i++) {
    const [x, y] = getRandomPointInCircle(boundaryRadius);
    const width = getSecureRandom() * (maxSize - minSize) + minSize;
    const height = getSecureRandom() * (maxSize - minSize) + minSize;
    const { velocity, angularVelocity } = getRandomVelocity(params);

    bodies.push({
      body: new Body({
        id: nextId(),
        shape: VertexShapeBuilder.rectangle({ width, height }),
        material: getMaterial(),
        positionLocked: false,
        rotationLocked: false,
      }),
      state: {
        position: [x, y, 0],
        velocity: [velocity.x, velocity.y, angularVelocity],
      },
    });
  }
  return bodies;
}

function generateRandomCapsules(
  params: RandomCapsuleParams & RandomVelocityParams,
  nextId: () => string
): {
  body: Body;
  state: BodyState;
}[] {
  const { count, boundaryRadius, minSize, maxSize, minSagitta, maxSagitta } =
    params;
  const bodies: {
    body: Body;
    state: BodyState;
  }[] = [];

  for (let i = 0; i < count; i++) {
    const [x, y] = getRandomPointInCircle(boundaryRadius);
    const width = getSecureRandom() * (maxSize - minSize) + minSize;
    const height = getSecureRandom() * (maxSize - minSize) + minSize;
    const widthSagittaFactor =
      getSecureRandom() * (maxSagitta - minSagitta) + minSagitta;
    const heightSagittaFactor =
      getSecureRandom() * (maxSagitta - minSagitta) + minSagitta;
    const { velocity, angularVelocity } = getRandomVelocity(params);

    if (
      (widthSagittaFactor < 0 &&
        Math.abs(widthSagittaFactor) * 2 * width > height) ||
      (heightSagittaFactor < 0 &&
        Math.abs(heightSagittaFactor) * 2 * height > width)
    ) {
      continue;
    }

    bodies.push({
      body: new Body({
        id: nextId(),
        shape: VertexShapeBuilder.capsule({
          width,
          height,
          widthSagittaFactor,
          heightSagittaFactor,
        }),
        material: getMaterial(),
        positionLocked: false,
        rotationLocked: false,
      }),
      state: {
        position: [x, y, 0],
        velocity: [velocity.x, velocity.y, angularVelocity],
      },
    });
  }
  return bodies;
}

function generateRandomPolygons(
  params: RandomPolygonParams & RandomVelocityParams,
  nextId: () => string
): {
  body: Body;
  state: BodyState;
}[] {
  const { count, boundaryRadius, minSize, maxSize, minSides, maxSides } =
    params;
  const bodies: {
    body: Body;
    state: BodyState;
  }[] = [];

  for (let i = 0; i < count; i++) {
    const [x, y] = getRandomPointInCircle(boundaryRadius);
    const numSides =
      Math.floor(getSecureRandom() * (maxSides - minSides + 1)) + minSides;
    const radius = getSecureRandom() * (maxSize - minSize) + minSize;
    const { velocity, angularVelocity } = getRandomVelocity(params);

    bodies.push({
      body: new Body({
        id: nextId(),
        shape: VertexShapeBuilder.regularPolygon(radius, numSides),
        material: getMaterial(),
        positionLocked: false,
        rotationLocked: false,
      }),
      state: {
        position: [x, y, 0],
        velocity: [velocity.x, velocity.y, angularVelocity],
      },
    });
  }
  return bodies;
}

function generateRandomCircles(
  params: RandomShapeParams & RandomVelocityParams,
  nextId: () => string
): {
  body: Body;
  state: BodyState;
}[] {
  const { count, boundaryRadius, minSize, maxSize } = params;
  const bodies: {
    body: Body;
    state: BodyState;
  }[] = [];
  for (let i = 0; i < count; i++) {
    const [x, y] = getRandomPointInCircle(boundaryRadius);
    const radius = getSecureRandom() * (maxSize - minSize) + minSize;
    const { velocity, angularVelocity } = getRandomVelocity(params);

    bodies.push({
      body: new Body({
        id: nextId(),
        shape: VertexShapeBuilder.circle(radius),
        material: getMaterial(),
        positionLocked: false,
        rotationLocked: false,
      }),
      state: {
        position: [x, y, 0],
        velocity: [velocity.x, velocity.y, angularVelocity],
      },
    });
  }
  return bodies;
}

interface SizeRangeParams {
  count: number;
  minSize: number;
  maxSize: number;
  maxLinearVelocity: number;
  maxAngularVelocity: number;
}

function generateShapesForSizeRange(
  params: SizeRangeParams,
  boundaryRadius: number,
  nextId: () => string
): {
  body: Body;
  state: BodyState;
}[] {
  const shapeCount = Math.floor(params.count / 3);
  const bodies: {
    body: Body;
    state: BodyState;
  }[] = [];

  // Rectangles
  bodies.push(
    ...generateRandomRectangles(
      {
        count: shapeCount,
        boundaryRadius,
        minSize: params.minSize,
        maxSize: params.maxSize,
        minLinearVelocity: 0,
        maxLinearVelocity: params.maxLinearVelocity,
        minAngularVelocity: 0,
        maxAngularVelocity: params.maxAngularVelocity,
      },
      nextId
    )
  );

  // Capsules
  bodies.push(
    ...generateRandomCapsules(
      {
        count: shapeCount,
        boundaryRadius,
        minSize: params.minSize,
        maxSize: params.maxSize,
        minSagitta: -0.3,
        maxSagitta: 1.0,
        minLinearVelocity: 0,
        maxLinearVelocity: params.maxLinearVelocity,
        minAngularVelocity: 0,
        maxAngularVelocity: params.maxAngularVelocity,
      },
      nextId
    )
  );

  // Polygons
  bodies.push(
    ...generateRandomPolygons(
      {
        count: shapeCount,
        boundaryRadius,
        minSize: params.minSize,
        maxSize: params.maxSize,
        minSides: 3,
        maxSides: 20,
        minLinearVelocity: 0,
        maxLinearVelocity: params.maxLinearVelocity,
        minAngularVelocity: 0,
        maxAngularVelocity: params.maxAngularVelocity,
      },
      nextId
    )
  );

  // // Circles
  // bodies.push(
  //   ...generateRandomCircles(
  //     {
  //       count: shapeCount,
  //       boundaryRadius,
  //       minSize: params.minSize,
  //       maxSize: params.maxSize,
  //       minLinearVelocity: 0,
  //       maxLinearVelocity: params.maxLinearVelocity,
  //       minAngularVelocity: 0,
  //       maxAngularVelocity: params.maxAngularVelocity,
  //     },
  //     nextId
  //   )
  // );
  return bodies;
}

export function generateVarietyWithBoundary({
  boundaryRadius,
  small,
  medium,
  large,
  planeId,
}: {
  boundaryRadius: number;
  small: SizeRangeParams;
  medium: SizeRangeParams;
  large: SizeRangeParams;
  planeId?: string;
}): {
  body: Body;
  state: BodyState;
}[] {
  const bodies: {
    body: Body;
    state: BodyState;
  }[] = [];

  let id = 0;
  const nextId = () => {
    return planeId ? `${planeId}.${id++}` : `${id++}`;
  };

  // // Add boundary
  // bodies.push({
  //   body: new Body({
  //     id: nextId(),
  //     shape: VertexShapeBuilder.circle(boundaryRadius, true),
  //     material: getMaterial(),
  //     positionLocked: true,
  //     rotationLocked: true,
  //   }),
  //   state: {
  //     position: [0, 0, 0],
  //     velocity: [0, 0, 0],
  //   },
  // });

  // Generate shapes for each size range
  bodies.push(...generateShapesForSizeRange(small, boundaryRadius, nextId));
  bodies.push(...generateShapesForSizeRange(medium, boundaryRadius, nextId));
  bodies.push(...generateShapesForSizeRange(large, boundaryRadius, nextId));

  bodies.push(
    ...generateCutShapes({
      minRadius: 10000,
      maxRadius: 20000,
      minCuts: 1000,
      maxCuts: 2000,
      count: 1,
      boundaryRadius,
    })
  );

  return bodies;
}

export function generateVariety({
  boundaryRadius,
  smallCount,
  mediumCount,
  largeCount,
  planeId,
}: VarietyGenerationParams): {
  body: Body;
  state: BodyState;
}[] {
  return generateVarietyWithBoundary({
    planeId,
    boundaryRadius,
    small: {
      count: smallCount,
      minSize: 50,
      maxSize: 100,
      maxLinearVelocity: 100,
      maxAngularVelocity: 10,
    },
    medium: {
      count: mediumCount,
      minSize: 1000,
      maxSize: 2000,
      maxLinearVelocity: 20,
      maxAngularVelocity: 1,
    },
    large: {
      count: largeCount,
      minSize: 2000,
      maxSize: 5000,
      maxLinearVelocity: 10,
      maxAngularVelocity: 1,
    },
  });
}

export function generateCutShape(
  position: [number, number],
  baseRadius: number,
  numCuts: number,
  nextId: () => string
): {
  body: Body;
  state: BodyState;
}[] {
  const base = VertexShapeBuilder.circle(baseRadius);
  base.updateAllGlobal(0, 0, 0, true);

  let resultShapes: Shape[] = [base];

  const cutSizeFactor = 0.1;

  for (let i = 0; i < numCuts; i++) {
    const [x, y] = getRandomPointInCircle(baseRadius);
    const cut = VertexShapeBuilder.circle(
      getSecureRandom() * baseRadius * cutSizeFactor
    );
    const angle = getSecureRandom() * Math.PI * 2;
    cut.updateAllGlobal(x, y, 0, true);

    const newResults: Shape[] = [];

    for (const shape of resultShapes) {
      const results = shape.subtract(cut, {
        this: {
          x: shape.localMec.center.x + shape.centroid.x,
          y: shape.localMec.center.y + shape.centroid.y,
          radius: shape.localMec.radius,
          index: -1,
        },
        other: {
          x: cut.localMec.center.x + cut.centroid.x,
          y: cut.localMec.center.y + cut.centroid.y,
          radius: cut.localMec.radius,
          index: -1,
        },
      });
      if (!results || results.length === 0) {
        // If subtraction fails or returns empty, keep the original shape
        newResults.push(shape);
      } else {
        newResults.push(...results);
      }
    }

    if (newResults.length === 0) {
      console.warn(
        `Cut ${i} eliminated all shapes, reverting to previous state`
      );
      continue;
    }

    resultShapes = newResults;
  }

  const bodies: {
    body: Body;
    state: BodyState;
  }[] = [];

  const material = getMaterial();

  for (let i = 0; i < resultShapes.length; i++) {
    bodies.push({
      body: new Body({
        id: nextId(),
        shape: resultShapes[i],
        material,
        positionLocked: false,
        rotationLocked: false,
      }),
      state: {
        position: [
          resultShapes[i].centroid.x + position[0],
          resultShapes[i].centroid.y + position[1],
          0,
        ],
        velocity: [10, 10, 0.001],
      },
    });
  }

  return bodies;
}

export function generateCutShapes({
  minRadius,
  maxRadius,
  minCuts,
  maxCuts,
  count,
  boundaryRadius,
}: {
  minRadius: number;
  maxRadius: number;
  minCuts: number;
  maxCuts: number;
  count: number;
  boundaryRadius: number;
}): {
  body: Body;
  state: BodyState;
}[] {
  const bodies: {
    body: Body;
    state: BodyState;
  }[] = [];

  let id = 0;

  const nextId = () => {
    return `cut-circle-piece-${id++}`;
  };

  for (let i = 0; i < count; i++) {
    const radius = getSecureRandom() * (maxRadius - minRadius) + minRadius;
    const cuts =
      Math.floor(getSecureRandom() * (maxCuts - minCuts + 1)) + minCuts;
    const position = getRandomPointInCircle(boundaryRadius);
    bodies.push(...generateCutShape(position, radius, cuts, nextId));
  }

  return bodies;
}
