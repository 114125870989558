export interface Bounds {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface Circle {
  x: number;
  y: number;
  radius: number;
  index: number;
}

export interface Rectangle {
  left: number;
  right: number;
  top: number;
  bottom: number;
}

export class QuadTree {
  bounds: Bounds;
  private capacity: number;
  private circles: Circle[] = [];
  private divided: boolean = false;
  children: QuadTree[] = [];
  private readonly minSize: number = 100; // Minimum quad size - adjust as needed

  constructor(bounds: Bounds, capacity: number = 4) {
    this.bounds = bounds;
    this.capacity = capacity;
  }

  // Check if a circle intersects with this quad
  intersectsQuad(circle: Circle): boolean {
    const dx = Math.abs(circle.x - (this.bounds.x + this.bounds.width / 2));
    const dy = Math.abs(circle.y - (this.bounds.y + this.bounds.height / 2));

    if (dx > this.bounds.width / 2 + circle.radius) return false;
    if (dy > this.bounds.height / 2 + circle.radius) return false;

    if (dx <= this.bounds.width / 2) return true;
    if (dy <= this.bounds.height / 2) return true;

    const cornerDistSq =
      Math.pow(dx - this.bounds.width / 2, 2) +
      Math.pow(dy - this.bounds.height / 2, 2);

    return cornerDistSq <= circle.radius * circle.radius;
  }

  insert(circle: Circle): void {
    // If this circle doesn't intersect this quad, ignore it
    if (!this.intersectsQuad(circle)) {
      return;
    }

    // If we haven't reached capacity and haven't subdivided
    if (this.circles.length < this.capacity && !this.divided) {
      this.circles.push(circle);
      return;
    }

    // Subdivide if needed
    if (!this.divided) {
      this.subdivide();

      // Redistribute existing circles to children
      const existingCircles = this.circles;
      this.circles = [];
      for (const existing of existingCircles) {
        this.insertToChildren(existing);
      }
    }

    // Insert new circle into children
    this.insertToChildren(circle);
  }

  private insertToChildren(circle: Circle): void {
    // A circle might need to go into multiple children
    for (const child of this.children) {
      child.insert(circle);
    }
  }

  query(range: Circle): Set<Circle> {
    const found = new Set<Circle>();
    this.queryCircle(range, found);
    return found;
  }

  private queryCircle(range: Circle, found: Set<Circle>): void {
    // If this quad doesn't intersect with the query range, return
    if (!this.intersectsQuad(range)) {
      return;
    }

    // Check circles at this level
    for (const circle of this.circles) {
      if (this.circlesIntersect(circle, range)) {
        found.add(circle);
      }
    }

    // If subdivided, check children
    if (this.divided) {
      for (const child of this.children) {
        child.queryCircle(range, found);
      }
    }
  }

  private circlesIntersect(a: Circle, b: Circle): boolean {
    const dx = a.x - b.x;
    const dy = a.y - b.y;
    const radiiSum = a.radius + b.radius;
    return dx * dx + dy * dy <= radiiSum * radiiSum;
  }

  private subdivide(): void {
    // Prevent subdivision if quad is too small
    if (
      this.bounds.width <= this.minSize ||
      this.bounds.height <= this.minSize
    ) {
      return;
    }

    const x = this.bounds.x;
    const y = this.bounds.y;
    const w = this.bounds.width / 2;
    const h = this.bounds.height / 2;

    this.children = [
      new QuadTree({ x: x, y: y, width: w, height: h }, this.capacity),
      new QuadTree({ x: x + w, y: y, width: w, height: h }, this.capacity),
      new QuadTree({ x: x, y: y + h, width: w, height: h }, this.capacity),
      new QuadTree({ x: x + w, y: y + h, width: w, height: h }, this.capacity),
    ];

    this.divided = true;
  }

  queryRect(range: Rectangle): Set<Circle> {
    const found = new Set<Circle>();
    this.queryRectangle(range, found);
    return found;
  }

  private queryRectangle(range: Rectangle, found: Set<Circle>): void {
    // If this quad doesn't intersect with the query rectangle, return
    if (!this.rectanglesIntersect(this.bounds, range)) {
      return;
    }

    // Check circles at this level
    for (const circle of this.circles) {
      if (this.circleIntersectsRectangle(circle, range)) {
        found.add(circle);
      }
    }

    // If subdivided, check children
    if (this.divided) {
      for (const child of this.children) {
        child.queryRectangle(range, found);
      }
    }
  }

  private rectanglesIntersect(a: Bounds, b: Rectangle): boolean {
    return !(
      a.x + a.width < b.left ||
      b.right < a.x ||
      a.y + a.height < b.top ||
      b.bottom < a.y
    );
  }

  private circleIntersectsRectangle(circle: Circle, rect: Rectangle): boolean {
    // Find closest point on rectangle to circle center
    const closestX = Math.max(rect.left, Math.min(circle.x, rect.right));
    const closestY = Math.max(rect.top, Math.min(circle.y, rect.bottom));

    // Calculate distance between closest point and circle center
    const dx = circle.x - closestX;
    const dy = circle.y - closestY;

    // Compare distance with circle radius
    return dx * dx + dy * dy <= circle.radius * circle.radius;
  }

  findIntersectingPairs(): {
    pairs: Set<[number, number]>;
    checkCount: number;
  } {
    const intersectingPairs = new Set<[number, number]>();
    const checkCount = this.findPairsInNode(intersectingPairs);
    return { pairs: intersectingPairs, checkCount };
  }

  private findPairsInNode(
    intersectingPairs: Set<[number, number]>,
    checkedPairs: Set<string> = new Set()
  ): number {
    let checkCount = 0;
    if (!this.divided) {
      for (let i = 0; i < this.circles.length; i++) {
        for (let j = i + 1; j < this.circles.length; j++) {
          const minIndex = Math.min(
            this.circles[i].index,
            this.circles[j].index
          );
          const maxIndex = Math.max(
            this.circles[i].index,
            this.circles[j].index
          );
          const pairKey: string = `${minIndex}-${maxIndex}`;

          if (checkedPairs.has(pairKey)) continue;

          checkedPairs.add(pairKey);

          // Increment check count before performing the intersection check
          checkCount++;

          if (this.circlesIntersect(this.circles[i], this.circles[j])) {
            intersectingPairs.add([minIndex, maxIndex]);
          }
        }
      }
    } else {
      for (const child of this.children) {
        checkCount += child.findPairsInNode(intersectingPairs, checkedPairs);
      }
    }

    return checkCount;
  }

  remove(index: number): boolean {
    // Remove from current node's circles
    const initialLength = this.circles.length;
    this.circles = this.circles.filter((circle) => circle.index !== index);
    const removedFromCurrent = initialLength > this.circles.length;

    // If divided, recursively remove from children
    if (this.divided) {
      let removedFromChildren = false;
      for (const child of this.children) {
        if (child.remove(index)) {
          removedFromChildren = true;
        }
      }
      return removedFromCurrent || removedFromChildren;
    }

    return removedFromCurrent;
  }
}
