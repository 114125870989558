export class Camera {
  x: number;
  y: number;
  zoom: number;
  width: number;
  height: number;
  followSpeed: number = 0.5;
  followSpeedFactor: number = 0.2;
  minZoom: number = 0.001;
  maxZoom: number = 10;
  zoomSpeed: number = 0.1;

  constructor(cameraWidth: number, cameraHeight: number) {
    this.x = 0;
    this.y = 0;
    this.zoom = 1;
    this.width = cameraWidth;
    this.height = cameraHeight;
  }

  pan(dx: number, dy: number): void {
    this.x += dx / this.zoom;
    this.y += dy / this.zoom;
  }

  zoomTo(newZoom: number): void {
    const clampedZoom = Math.max(this.minZoom, Math.min(this.maxZoom, newZoom));
    this.zoom = clampedZoom;
  }

  zoomBy(factor: number): void {
    this.zoomTo(this.zoom * factor);
  }

  worldToScreen(worldX: number, worldY: number): [number, number] {
    return [
      (worldX - this.x) * this.zoom + this.width / 2,
      (worldY - this.y) * this.zoom + this.height / 2,
    ];
  }

  screenToWorld(screenX: number, screenY: number): [number, number] {
    return [
      (screenX - this.width / 2) / this.zoom + this.x,
      (screenY - this.height / 2) / this.zoom + this.y,
    ];
  }

  updateSize(width: number, height: number): void {
    this.width = width;
    this.height = height;
  }

  worldToScreenDistance(worldDistance: number): number {
    return worldDistance * this.zoom;
  }

  update(x: number, y: number, velocity: number, dt: number): void {
    const velocityScale = Math.pow(velocity, this.followSpeedFactor);
    const adjustedFollowSpeed = this.followSpeed * velocityScale * dt;

    this.x += (x - this.x) * adjustedFollowSpeed;
    this.y += (y - this.y) * adjustedFollowSpeed;
  }

  getWorldBounds(margin: number = 0): {
    left: number;
    right: number;
    top: number;
    bottom: number;
  } {
    const topLeft = this.screenToWorld(0, 0);
    const bottomRight = this.screenToWorld(this.width, this.height);

    return {
      left: topLeft[0] - margin,
      top: topLeft[1] - margin,
      right: bottomRight[0] + margin,
      bottom: bottomRight[1] + margin,
    };
  }
}
