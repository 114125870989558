import { nanoid } from "nanoid";
import { Vector2D } from "../math/vector2D.ts";
import { Camera } from "./camera.ts";
import type { Actuator } from "./actuator.ts";

export class Player {
  id: string;
  key: string;
  camera: Camera;
  actuator: Actuator;
  mousePosition: Vector2D;
  screenMousePosition: Vector2D;
  mouseDown: boolean = false;
  rightMouseDown: boolean = false;
  keysDown: Record<string, boolean> = {};
  movingShapeIndex: number | null = null;
  send: (data: ArrayBuffer) => void;

  constructor(
    id: string,
    cameraWidth: number,
    cameraHeight: number,
    actuator: Actuator
  ) {
    this.camera = new Camera(cameraWidth, cameraHeight);
    this.actuator = actuator;
    this.mousePosition = new Vector2D(0, 0);
    this.screenMousePosition = new Vector2D(0, 0);
    this.send = () => {};
    this.id = id;
    this.key = `${id}:${nanoid()}`;
  }

  setSendFunction(send: (data: ArrayBuffer) => void): void {
    this.send = send;
  }

  updateWorldMousePosition(): void {
    const worldPos = this.camera.screenToWorld(
      this.screenMousePosition.x,
      this.screenMousePosition.y
    );
    this.mousePosition.x = worldPos[0];
    this.mousePosition.y = worldPos[1];
  }
}
