export interface TimingProvider {
  now(): number;
  setInterval(callback: () => void, ms: number): number;
  clearInterval(id: number): void;
}

// For browser environments
export class BrowserTiming implements TimingProvider {
  private readonly startTime: number;

  constructor() {
    this.startTime = performance.now();
  }

  now(): number {
    return performance.now() - this.startTime;
  }

  setInterval(callback: () => void, ms: number): number {
    return window.setInterval(callback, ms);
  }

  clearInterval(id: number): void {
    window.clearInterval(id);
  }
}

// For Cloudflare Workers environment
export class WorkerTiming implements TimingProvider {
  private readonly startTime: number;

  constructor() {
    this.startTime = performance.now();
  }

  now(): number {
    return performance.now() - this.startTime;
  }

  setInterval(callback: () => void, ms: number): number {
    return setInterval(callback, ms);
  }

  clearInterval(id: number): void {
    clearInterval(id);
  }
}

// For testing
export class MockTiming implements TimingProvider {
  private currentTime: number = 0;
  private intervals: Map<number, { callback: () => void; ms: number }> =
    new Map();
  private nextIntervalId: number = 1;

  now(): number {
    return this.currentTime;
  }

  setInterval(callback: () => void, ms: number): number {
    const id = this.nextIntervalId++;
    this.intervals.set(id, { callback, ms });
    return id;
  }

  clearInterval(id: number): void {
    this.intervals.delete(id);
  }

  // Helper for tests to advance time
  advanceTime(ms: number): void {
    this.currentTime += ms;
    // Trigger any intervals that should have fired
    this.intervals.forEach(({ callback, ms: interval }, id) => {
      if (this.currentTime % interval < ms) {
        callback();
      }
    });
  }
}
