const TWO_PI = Math.PI * 2;

export class Vector2D {
  x: number;
  y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  magnitude(): number {
    return Math.sqrt(this.x * this.x + this.y * this.y);
  }

  magnitudeSquared(): number {
    return this.x * this.x + this.y * this.y;
  }

  normalized(): Vector2D {
    return this.magnitude() > 0 ? this.divide(this.magnitude()) : ZERO_VECTOR;
  }

  perpendicular(): Vector2D {
    return new Vector2D(-this.y, this.x);
  }

  negated(): Vector2D {
    return new Vector2D(-this.x, -this.y);
  }

  angle(): number {
    return Math.atan2(this.y, this.x);
  }

  add(v: Vector2D): Vector2D {
    return new Vector2D(this.x + v.x, this.y + v.y);
  }

  subtract(v: Vector2D): Vector2D {
    return new Vector2D(this.x - v.x, this.y - v.y);
  }

  multiply(n: number): Vector2D {
    return new Vector2D(this.x * n, this.y * n);
  }

  divide(n: number): Vector2D {
    return new Vector2D(this.x / n, this.y / n);
  }

  dot(v: Vector2D): number {
    return this.x * v.x + this.y * v.y;
  }

  cross(v: Vector2D): number {
    return this.x * v.y - this.y * v.x;
  }

  length(): number {
    return this.magnitude();
  }

  lengthSquared(): number {
    return this.magnitudeSquared();
  }

  normalize(): Vector2D {
    return this.normalized();
  }

  inverse(): Vector2D {
    return new Vector2D(-this.x, -this.y);
  }

  limit(max: number): Vector2D {
    const mag = this.magnitude();
    if (mag > max) {
      return this.normalize().multiply(max);
    }
    return this;
  }

  static fromAngle(angle: number, magnitude: number = 1): Vector2D {
    return new Vector2D(
      Math.cos(angle) * magnitude,
      Math.sin(angle) * magnitude
    );
  }

  distanceTo(v: Vector2D): number {
    return Math.sqrt(this.distanceToSquared(v));
  }

  scale(scalar: number): Vector2D {
    return this.multiply(scalar);
  }

  // Maybe add cached values to the clone?
  clone(): Vector2D {
    return new Vector2D(this.x, this.y);
  }

  distanceToSquared(v: Vector2D): number {
    const dx = this.x - v.x;
    const dy = this.y - v.y;
    return dx * dx + dy * dy;
  }

  rotateAround(angle: number, center: Vector2D = ZERO_VECTOR): Vector2D {
    const cos = Math.cos(angle);
    const sin = Math.sin(angle);
    const x = this.x - center.x;
    const y = this.y - center.y;
    return new Vector2D(
      x * cos - y * sin + center.x,
      x * sin + y * cos + center.y
    );
  }

  rotate(angle: number): Vector2D {
    const cos = Math.cos(angle);
    const sin = Math.sin(angle);
    return new Vector2D(
      this.x * cos - this.y * sin,
      this.x * sin + this.y * cos
    );
  }

  projectOnto(v: Vector2D): Vector2D {
    return v.multiply(this.dot(v) / v.magnitudeSquared());
  }

  isAllignedWith(v: Vector2D): boolean {
    return Math.abs(this.cross(v)) < 0.0001;
  }

  isValid(): boolean {
    return isFinite(this.x) && isFinite(this.y);
  }

  clampMagnitude(max: number): Vector2D {
    const mag = this.magnitude();
    if (mag > max) {
      return this.normalize().multiply(max);
    }
    return this;
  }

  toArray(): [number, number] {
    return [this.x, this.y];
  }

  // Calculate the angle between this vector and another vector, in radians (0 to 2π)
  angleBetween(other: Vector2D): number {
    const dotProduct = this.dot(other);
    const crossProduct = this.cross(other);
    const signedAngle = Math.atan2(crossProduct, dotProduct); // angle in range -π to π
    return signedAngle >= 0 ? signedAngle : signedAngle + TWO_PI;
  }
}

export const ZERO_VECTOR = new Vector2D(0, 0);
export const UNIT_X = new Vector2D(1, 0);
export const UNIT_Y = new Vector2D(0, 1);
