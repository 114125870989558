export function preparePacket(
  floatData: Float32Array,
  ids: string[],
  numElements: number,
  dt: number
): ArrayBuffer {
  // Calculate string section size
  const encoder = new TextEncoder();
  const encodedStrings = ids.map((str) => encoder.encode(str));
  const stringSectionLength = encodedStrings.reduce(
    (sum, encoded) => sum + 4 + encoded.length,
    0
  );

  // Calculate aligned offset for float data
  const headerSize = 16; // 4 + 4 + 8
  const stringEndOffset = headerSize + stringSectionLength;
  const alignedFloatOffset = Math.ceil(stringEndOffset / 4) * 4;
  const paddingBytes = alignedFloatOffset - stringEndOffset;

  // Calculate total buffer size including padding
  const floatSectionSize = numElements * 4;
  const totalSize = alignedFloatOffset + floatSectionSize;

  // Create buffer and view
  const buffer = new ArrayBuffer(totalSize);
  const view = new DataView(buffer);
  let offset = 0;

  // Write header
  view.setUint32(offset, stringSectionLength);
  offset += 4;
  view.setUint32(offset, numElements);
  offset += 4;
  view.setFloat64(offset, dt);
  offset += 8;

  // Write strings
  for (const encoded of encodedStrings) {
    view.setUint32(offset, encoded.length);
    offset += 4;
    new Uint8Array(buffer, offset, encoded.length).set(encoded);
    offset += encoded.length;
  }

  // Skip to aligned position for float data
  offset = alignedFloatOffset;

  // Write float data
  const floatView = new Float32Array(buffer, offset, numElements);
  floatView.set(floatData.subarray(0, numElements));

  return buffer;
}

export function parsePacket(buffer: ArrayBuffer): {
  ids: string[];
  states: Float32Array;
  dt: number;
} {
  const view = new DataView(buffer);
  const decoder = new TextDecoder();
  let offset = 0;

  // Read header
  const stringSectionLength = view.getUint32(offset);
  offset += 4;
  const numElements = view.getUint32(offset);
  offset += 4;
  const dt = view.getFloat64(offset);
  offset += 8;

  // Read strings
  const ids: string[] = [];
  const stringSectionEnd = offset + stringSectionLength;

  while (offset < stringSectionEnd) {
    const stringLength = view.getUint32(offset);
    offset += 4;
    const stringData = new Uint8Array(buffer, offset, stringLength);
    ids.push(decoder.decode(stringData));
    offset += stringLength;
  }

  // Calculate aligned offset for float data
  const alignedFloatOffset = Math.ceil((16 + stringSectionLength) / 4) * 4;

  // Read float data
  const states = new Float32Array(buffer, alignedFloatOffset, numElements);

  return { ids, states, dt };
}

export function testPacket(
  floatData: Float32Array,
  ids: string[],
  numElements: number,
  dt: number
) {
  const packet = preparePacket(floatData, ids, numElements, dt);
  const parsed = parsePacket(packet);
  const trimmedData = floatData.subarray(0, numElements);
  let valid = true;

  if (parsed.states.length !== numElements) {
    valid = false;
  }

  for (let i = 0; i < numElements; i++) {
    if (parsed.states[i] !== trimmedData[i]) {
      valid = false;
      break;
    }
  }

  if (parsed.ids.length !== ids.length) {
    valid = false;
  }

  for (let i = 0; i < ids.length; i++) {
    if (parsed.ids[i] !== ids[i]) {
      valid = false;
      break;
    }
  }

  if (parsed.dt !== dt) {
    valid = false;
  }

  if (!valid) {
    console.error(
      `Packet test failed\nOriginal ids: ${ids}\nParsed ids: ${parsed.ids}\nOriginal states: ${floatData}\nParsed states: ${parsed.states}\nServer dt: ${dt}\nParsed dt: ${parsed.dt}`
    );
    throw new Error("Packet test failed");
  }
}
