import type { Vector2D } from "../math/vector2D.ts";
import type { Segment } from "../models.ts";
import type { Circle } from "./quadtree.ts";

export interface QuadBounds {
  left: number;
  right: number;
  top: number;
  bottom: number;
}

export class SegmentQuadTree {
  bounds: QuadBounds;
  private capacity: number;
  private segments: { segment: Segment; index: number }[] = [];
  private divided: boolean = false;
  children: SegmentQuadTree[] = [];
  private readonly minSize: number;
  collidedThisFrame: boolean = false;

  constructor(bounds: QuadBounds, capacity: number = 2) {
    this.bounds = bounds;
    this.capacity = capacity;

    // Calculate min size based on bounds
    this.minSize =
      Math.max(bounds.right - bounds.left, bounds.bottom - bounds.top) / 64;
  }

  // Check if a segment intersects with this quad
  private intersectsQuad(segment: Segment): boolean {
    return segment.intersectsBounds(this.bounds);
  }

  insert(segment: { segment: Segment; index: number }): void {
    // If this segment doesn't intersect this quad, ignore it
    if (!this.intersectsQuad(segment.segment)) {
      return;
    }

    // Add size check here to prevent infinite subdivision
    const width = this.bounds.right - this.bounds.left;
    const height = this.bounds.bottom - this.bounds.top;
    if (width <= this.minSize || height <= this.minSize) {
      this.segments.push(segment);
      return;
    }

    // Check if the segment spans multiple quadrants
    const midX = (this.bounds.left + this.bounds.right) / 2;
    const midY = (this.bounds.top + this.bounds.bottom) / 2;
    const spansMultipleQuadrants =
      segment.segment.intersectsBounds({
        left: this.bounds.left,
        right: midX,
        top: this.bounds.top,
        bottom: midY,
      }) &&
      segment.segment.intersectsBounds({
        left: midX,
        right: this.bounds.right,
        top: this.bounds.top,
        bottom: midY,
      });

    // If it spans multiple quadrants, keep it at this level
    if (spansMultipleQuadrants) {
      this.segments.push(segment);
      return;
    }

    // If we haven't reached capacity and haven't subdivided
    if (this.segments.length < this.capacity && !this.divided) {
      this.segments.push(segment);
      return;
    }

    // Subdivide if needed
    if (!this.divided) {
      this.subdivide();

      // Redistribute existing segments to children
      const existingSegments = this.segments;
      this.segments = [];
      for (const existing of existingSegments) {
        // Check again for spanning multiple quadrants
        if (
          existing.segment.intersectsBounds({
            left: this.bounds.left,
            right: midX,
            top: this.bounds.top,
            bottom: midY,
          }) &&
          existing.segment.intersectsBounds({
            left: midX,
            right: this.bounds.right,
            top: this.bounds.top,
            bottom: midY,
          })
        ) {
          this.segments.push(existing);
        } else {
          // Only try to insert into children if it doesn't span quadrants
          for (const child of this.children) {
            if (child.intersectsQuad(existing.segment)) {
              child.insert(existing);
            }
          }
        }
      }
    }

    // Insert new segment into appropriate children
    for (const child of this.children) {
      if (child.intersectsQuad(segment.segment)) {
        child.insert(segment);
      }
    }
  }

  query(circle: Circle): Set<number> {
    const found = new Set<number>();
    this.queryCircle(circle, found);
    return found;
  }

  private queryCircle(circle: Circle, found: Set<number>): void {
    // If this quad doesn't intersect with the query circle, return
    if (!this.circleIntersectsQuad(circle)) {
      return;
    }

    this.collidedThisFrame = true;

    // // Check segments at this level
    // for (const segment of this.segments) {
    //   if (segment.segment.intersectsCircle(circle)) {
    //     found.add(segment.index);
    //   }
    // }

    // Add all segments in this quad
    for (const segment of this.segments) {
      found.add(segment.index);
    }

    // If subdivided, check children
    if (this.divided) {
      for (const child of this.children) {
        child.queryCircle(circle, found);
      }
    }
  }

  queryHorizontalRay(point: Vector2D): Set<number> {
    const found = new Set<number>();
    this.queryRay(point, found);
    return found;
  }

  private queryRay(point: Vector2D, found: Set<number>): void {
    // Early exit optimization: Skip quads that can't possibly intersect the ray
    // The ray extends horizontally to the right from the point, so:
    // - Skip if quad is entirely to the left of the point
    // - Skip if quad is entirely above or below the point's y-coordinate
    if (
      this.bounds.right < point.x || // quad is to the left
      this.bounds.bottom < point.y || // quad is above
      this.bounds.top > point.y // quad is below
    ) {
      return;
    }

    // Add all segments in this quad
    for (const segment of this.segments) {
      found.add(segment.index);
    }

    // If subdivided, check children
    if (this.divided) {
      for (const child of this.children) {
        child.queryRay(point, found);
      }
    }
  }

  private circleIntersectsQuad(circle: Circle): boolean {
    // Find closest point on rectangle to circle center
    const closestX = Math.max(
      this.bounds.left,
      Math.min(circle.x, this.bounds.right)
    );
    const closestY = Math.max(
      this.bounds.top,
      Math.min(circle.y, this.bounds.bottom)
    );

    // Calculate distance between closest point and circle center
    const dx = circle.x - closestX;
    const dy = circle.y - closestY;

    // Compare distance with circle radius
    return dx * dx + dy * dy <= circle.radius * circle.radius;
  }

  private subdivide(): void {
    const midX = (this.bounds.left + this.bounds.right) / 2;
    const midY = (this.bounds.top + this.bounds.bottom) / 2;

    // Create four children
    this.children = [
      // Northwest
      new SegmentQuadTree(
        {
          left: this.bounds.left,
          right: midX,
          top: this.bounds.top,
          bottom: midY,
        },
        this.capacity
      ),
      // Northeast
      new SegmentQuadTree(
        {
          left: midX,
          right: this.bounds.right,
          top: this.bounds.top,
          bottom: midY,
        },
        this.capacity
      ),
      // Southwest
      new SegmentQuadTree(
        {
          left: this.bounds.left,
          right: midX,
          top: midY,
          bottom: this.bounds.bottom,
        },
        this.capacity
      ),
      // Southeast
      new SegmentQuadTree(
        {
          left: midX,
          right: this.bounds.right,
          top: midY,
          bottom: this.bounds.bottom,
        },
        this.capacity
      ),
    ];

    this.divided = true;
  }
}
