import { Vector2D } from "../math/vector2D";
import type { BodyState, Material, SerializedBodyData } from "../models";
import { Shape } from "../shapes/shape";
import type { Composite } from "./composite";
import { BodyOffset, type Plane } from "./plane";
import type { Circle } from "./quadtree";
import type {
  AfterCollisionTrigger,
  BeforeCollisionTrigger,
  CollisionTriggerInput,
} from "./triggers";

export class Body {
  id: string;
  index: number = -1;
  strideIndex: number = -1;
  stateArray: Float32Array | null = null;

  positionCorrections: Vector2D[] = [];

  shape: Shape;
  material: Material;
  positionLocked: boolean;
  rotationLocked: boolean;

  deferRotationLock: boolean = false;

  mass: number;
  momentOfInertia: number;

  composite: Composite | null = null;
  beforeCollisionTriggers: BeforeCollisionTrigger[] = [];
  afterCollisionTriggers: AfterCollisionTrigger[] = [];

  constructor({
    id,
    shape,
    material,
    positionLocked,
    rotationLocked,
  }: {
    id: string;
    shape: Shape;
    material: Material;
    positionLocked: boolean;
    rotationLocked: boolean;
  }) {
    this.id = id;
    this.shape = shape;
    this.material = material;
    this.positionLocked = positionLocked;
    this.rotationLocked = rotationLocked;

    this.mass = shape.area * material.density;
    this.momentOfInertia = shape.secondMomentOfArea * material.density;
  }

  isLoaded(): boolean {
    return this.stateArray !== null;
  }

  getPosition(): [number, number, number] {
    if (!this.stateArray) {
      console.error("Body not loaded");
      return [0, 0, 0];
    }
    return [
      this.stateArray[this.strideIndex + BodyOffset.PositionX],
      this.stateArray[this.strideIndex + BodyOffset.PositionY],
      this.stateArray[this.strideIndex + BodyOffset.Rotation],
    ];
  }

  getVelocity(): [number, number, number] {
    if (!this.stateArray) {
      console.error("Body not loaded");
      return [0, 0, 0];
    }
    return [
      this.stateArray[this.strideIndex + BodyOffset.VelocityX],
      this.stateArray[this.strideIndex + BodyOffset.VelocityY],
      this.stateArray[this.strideIndex + BodyOffset.AngularVelocity],
    ];
  }

  getAcceleration(): [number, number, number] {
    if (!this.stateArray) {
      console.error("Body not loaded");
      return [0, 0, 0];
    }
    return [
      this.stateArray[this.strideIndex + BodyOffset.AccelerationX],
      this.stateArray[this.strideIndex + BodyOffset.AccelerationY],
      this.stateArray[this.strideIndex + BodyOffset.AngularAcceleration],
    ];
  }

  getMec(): Circle {
    if (!this.stateArray) {
      console.error("Body not loaded");
      return {
        x: 0,
        y: 0,
        radius: 0,
        index: -1,
      };
    }
    return {
      x: this.stateArray[this.strideIndex + BodyOffset.MecPositionX],
      y: this.stateArray[this.strideIndex + BodyOffset.MecPositionY],
      radius: this.stateArray[this.strideIndex + BodyOffset.MecRadius],
      index: this.index,
    };
  }

  getMass(considerComposite: boolean = false): number {
    if (considerComposite && this.composite) {
      return this.composite.positionLocked ? Infinity : this.composite.mass;
    }
    return this.mass;
  }

  getMomentOfInertia(considerComposite: boolean = false): number {
    if (considerComposite && this.composite) {
      return this.composite.momentOfInertia;
    }
    return this.momentOfInertia;
  }

  getCenter(considerComposite: boolean = false): Vector2D {
    if (!this.stateArray) {
      console.error("Body not loaded");
      return new Vector2D(0, 0);
    }

    if (considerComposite && this.composite) {
      const pos = this.composite.centroidPosition;
      return new Vector2D(pos[0], pos[1]);
    }
    return new Vector2D(
      this.stateArray[this.strideIndex + BodyOffset.PositionX],
      this.stateArray[this.strideIndex + BodyOffset.PositionY]
    );
  }

  setPosition(x: number, y: number, rotation: number): void {
    if (!this.stateArray) {
      console.error("Body not loaded");
      return;
    }
    this.stateArray[this.strideIndex + BodyOffset.PositionX] = x;
    this.stateArray[this.strideIndex + BodyOffset.PositionY] = y;
    this.stateArray[this.strideIndex + BodyOffset.Rotation] = rotation;
  }

  setVelocity(x: number, y: number, angularVelocity: number): void {
    if (!this.stateArray) {
      console.error("Body not loaded");
      return;
    }
    this.stateArray[this.strideIndex + BodyOffset.VelocityX] = x;
    this.stateArray[this.strideIndex + BodyOffset.VelocityY] = y;
    this.stateArray[this.strideIndex + BodyOffset.AngularVelocity] =
      angularVelocity;
  }

  getAllData(): {
    position: [number, number, number];
    velocity: [number, number, number];
    acceleration: [number, number, number];
    mec: Circle;
  } {
    return {
      position: this.getPosition(),
      velocity: this.getVelocity(),
      acceleration: this.getAcceleration(),
      mec: this.getMec(),
    };
  }

  applyLinearImpulse(
    impulse: Vector2D,
    considerComposite: boolean = false
  ): void {
    if (!this.stateArray) {
      console.error("Body not loaded");
      return;
    }

    if (!impulse.isValid()) {
      console.error(`impulse is invalid: ${impulse.x}, ${impulse.y}`);
      return;
    }

    if (considerComposite && this.composite) {
      // Skip if position locked or mass is invalid
      if (!this.composite.positionLocked) {
        this.composite.velocity[0] += impulse.x / this.composite.mass;
        this.composite.velocity[1] += impulse.y / this.composite.mass;
      }
      return;
    }

    // Skip if position locked or mass is invalid
    if (!this.positionLocked) {
      this.stateArray[this.strideIndex + BodyOffset.VelocityX] +=
        impulse.x / this.mass;
      this.stateArray[this.strideIndex + BodyOffset.VelocityY] +=
        impulse.y / this.mass;
    }
  }

  addPositionCorrection(
    correction: Vector2D,
    considerComposite: boolean = false
  ): void {
    if (!this.stateArray) {
      console.error("Body not loaded");
      return;
    }

    if (!correction.isValid()) {
      console.error(`correction is invalid: ${correction.x}, ${correction.y}`);
      return;
    }

    if (considerComposite && this.composite) {
      this.composite.addPositionCorrection(correction);
      return;
    }

    this.positionCorrections.push(correction);
  }

  applyPositionCorrections(): void {
    if (!this.stateArray) {
      console.error("Body not loaded");
      return;
    }

    if (this.positionLocked) {
      return;
    }

    if (this.composite) {
      this.composite.applyPositionCorrections();
      return;
    }

    // Calculate average correction
    if (this.positionCorrections.length > 0) {
      const avgCorrection = this.positionCorrections
        .reduce((sum, correction) => sum.add(correction), new Vector2D(0, 0))
        .scale(1 / this.positionCorrections.length);

      // Apply the average correction
      this.stateArray[this.strideIndex + BodyOffset.PositionX] +=
        avgCorrection.x / this.mass;
      this.stateArray[this.strideIndex + BodyOffset.PositionY] +=
        avgCorrection.y / this.mass;
    }

    this.updateShapeTransform(true);
    this.positionCorrections = [];
  }

  applyForce(
    force: Vector2D,
    globalPosition?: Vector2D,
    considerComposite: boolean = false
  ): void {
    if (!this.stateArray) {
      console.error("Body not loaded");
      return;
    }

    if (!force.isValid()) {
      console.error(`force is invalid: ${force.x}, ${force.y}`);
      return;
    }

    if (considerComposite && this.composite) {
      // Skip if position locked or mass is infinite/zero
      if (!this.composite.positionLocked) {
        const linearAcc = force.scale(1 / this.composite.mass);
        this.composite.acceleration[0] += linearAcc.x;
        this.composite.acceleration[1] += linearAcc.y;
      }

      // Skip if rotation locked or moment of inertia is invalid
      if (!this.composite.rotationLocked) {
        const forcePosition =
          globalPosition ??
          new Vector2D(
            this.stateArray[this.strideIndex + BodyOffset.PositionX],
            this.stateArray[this.strideIndex + BodyOffset.PositionY]
          );

        const centroidPos = new Vector2D(
          this.composite.centroidPosition[0],
          this.composite.centroidPosition[1]
        );
        const r = forcePosition.subtract(centroidPos);
        const torque = r.x * force.y - r.y * force.x;
        this.composite.angularAcceleration +=
          torque / this.composite.momentOfInertia;
      }
      return;
    }

    // Skip if position locked or mass is invalid
    if (!this.positionLocked) {
      const linearAcc = force.scale(1 / this.mass);
      this.stateArray[this.strideIndex + BodyOffset.AccelerationX] +=
        linearAcc.x;
      this.stateArray[this.strideIndex + BodyOffset.AccelerationY] +=
        linearAcc.y;
    }

    // Skip if rotation locked or inertia is invalid
    if (globalPosition && !this.rotationLocked) {
      const center = this.getCenter();
      const r = globalPosition.subtract(center);
      const torque = r.x * force.y - r.y * force.x;
      this.stateArray[this.strideIndex + BodyOffset.AngularAcceleration] +=
        torque / this.momentOfInertia;
    }
  }

  applyAngularImpulse(
    r: Vector2D,
    impulse: Vector2D,
    considerComposite: boolean = false,
    enclosure: boolean = false
  ): void {
    if (!this.stateArray) {
      console.error("Body not loaded");
      return;
    }

    if (!impulse.isValid()) {
      console.error(
        `applyAngularImpulse: impulse is invalid: ${impulse.x}, ${impulse.y}`
      );
      return;
    }

    if (considerComposite && this.composite) {
      const composite = this.composite;
      if (!composite.rotationLocked) {
        const rCrossImpulse = r.x * impulse.y - r.y * impulse.x;
        composite.angularVelocity += rCrossImpulse / composite.momentOfInertia;
      }
      return;
    }

    if (r.length() > this.shape.localMec.radius * 10) {
      // console.error(
      //   `r is too long: ${r.length()} > ${
      //     this.shape.localMec.radius
      //   } * 2\nenclosure: ${enclosure}`
      // );
      return;
    }

    if (!this.rotationLocked) {
      const rCrossImpulse = r.x * impulse.y - r.y * impulse.x;
      this.stateArray[this.strideIndex + BodyOffset.AngularVelocity] +=
        rCrossImpulse / this.momentOfInertia;
    }
  }

  applyTorque(torque: number, considerComposite: boolean = false): void {
    if (!this.stateArray) {
      console.error("Body not loaded");
      return;
    }

    if (!isFinite(torque)) {
      console.error(`applyTorque: torque is invalid: ${torque}`);
      return;
    }

    if (considerComposite && this.composite) {
      // Skip if rotation locked or moment of inertia is invalid
      if (!this.composite.rotationLocked) {
        this.composite.angularAcceleration +=
          torque / this.composite.momentOfInertia;
      }
      return;
    }

    // Skip if rotation locked or inertia is invalid
    if (!this.rotationLocked) {
      this.stateArray[this.strideIndex + BodyOffset.AngularAcceleration] +=
        torque / this.momentOfInertia;
    }
  }

  getLinearVelocity(considerComposite: boolean = false): Vector2D {
    if (!this.stateArray) {
      console.error("Body not loaded");
      return new Vector2D(0, 0);
    }

    if (considerComposite && this.composite) {
      const velocity = this.composite.velocity;
      return new Vector2D(velocity[0], velocity[1]);
    }
    return new Vector2D(
      this.stateArray[this.strideIndex + BodyOffset.VelocityX],
      this.stateArray[this.strideIndex + BodyOffset.VelocityY]
    );
  }

  getAngularVelocity(considerComposite: boolean = false): number {
    if (!this.stateArray) {
      console.error("Body not loaded");
      return 0;
    }

    if (considerComposite && this.composite) {
      return this.composite.angularVelocity;
    }

    return this.stateArray[this.strideIndex + BodyOffset.AngularVelocity];
  }

  getPointVelocity(r: Vector2D, considerComposite: boolean = false): Vector2D {
    const linearVel = this.getLinearVelocity(considerComposite);
    const angularVel = this.getAngularVelocity(considerComposite);

    const pointVel = linearVel.add(
      new Vector2D(-r.y * angularVel, r.x * angularVel)
    );

    return pointVel;
  }

  updateShapeTransform(force: boolean = false): void {
    if (!this.stateArray) {
      console.error("Body not loaded");
      return;
    }
    this.shape.updateAllGlobal(
      this.stateArray[this.strideIndex + BodyOffset.PositionX],
      this.stateArray[this.strideIndex + BodyOffset.PositionY],
      this.stateArray[this.strideIndex + BodyOffset.Rotation],
      force
    );
  }

  callBeforeCollisionTriggers(input: CollisionTriggerInput): boolean {
    if (this.beforeCollisionTriggers.length === 0) {
      return true;
    }

    let shouldResolve = true;

    for (const trigger of this.beforeCollisionTriggers) {
      if (!trigger(input)) {
        shouldResolve = false;
      }
    }
    return shouldResolve;
  }

  callAfterCollisionTriggers(input: CollisionTriggerInput): void {
    for (const trigger of this.afterCollisionTriggers) {
      trigger(input);
    }
  }

  cut(
    cutter: Shape,
    cutterMec: Circle
  ): {
    state: BodyState;
    body: Body;
  }[] {
    const subtractionResult = this.shape.subtract(cutter, {
      this: this.getMec(),
      other: cutterMec,
    });

    if (subtractionResult.length === 0) {
      return [];
    }

    // Get the original body's center and angular velocity
    const originalCenter = this.getCenter(true);
    const originalAngularVel = this.getAngularVelocity(true);
    const targetVelocity = this.getLinearVelocity(true);
    const targetAngularVel = this.getAngularVelocity(true);

    // Create new bodies from the subtraction result
    const bodies: {
      state: BodyState;
      body: Body;
    }[] = [];
    for (const [index, shape] of subtractionResult.entries()) {
      if (shape.area < 50) {
        continue;
      }

      // Calculate the vector from original center to new centroid
      const r = new Vector2D(
        shape.centroid.x - originalCenter.x,
        shape.centroid.y - originalCenter.y
      );

      // Calculate rotational velocity component (v = ω × r)
      // In 2D, this is: vx = -ω * ry, vy = ω * rx
      const rotationalVel = new Vector2D(
        -originalAngularVel * r.y,
        originalAngularVel * r.x
      );

      bodies.push({
        body: new Body({
          id: `${this.id}-${index}`,
          shape,
          material: this.material,
          positionLocked: this.positionLocked,
          rotationLocked: this.rotationLocked,
        }),
        state: {
          position: [shape.centroid.x, shape.centroid.y, 0],
          velocity: [
            targetVelocity.x + rotationalVel.x,
            targetVelocity.y + rotationalVel.y,
            targetAngularVel,
          ],
        },
      });
    }

    return bodies;
  }

  static fromSerialized(serialized: SerializedBodyData): Body {
    return new Body({
      id: serialized.id,
      shape: Shape.fromSerialized(serialized.shape),
      material: serialized.material,
      positionLocked: serialized.positionLocked,
      rotationLocked: serialized.rotationLocked,
    });
  }
}
