const baseColors: string[] = [
  "aqua",
  "black",
  "blue",
  "fuchsia",
  "gray",
  "green",
  "lime",
  "maroon",
  "navy",
  "olive",
  "purple",
  "red",
  "silver",
  "teal",
  "white",
  "yellow",
  "orange",
  "pink",
  "brown",
  "cyan",
  "magenta",
  "indigo",
  "violet",
  "turquoise",
  "gold",
  "salmon",
  "khaki",
  "plum",
  "coral",
];

let colorIndex = 0;

interface ColorOptions {
  color?: string | number;
  transparency?: number;
}

// Add this color mapping object
const colorMap: { [key: string]: number[] } = {
  aqua: [0, 255, 255],
  black: [0, 0, 0],
  blue: [0, 0, 255],
  fuchsia: [255, 0, 255],
  gray: [128, 128, 128],
  green: [0, 128, 0],
  lime: [0, 255, 0],
  maroon: [128, 0, 0],
  navy: [0, 0, 128],
  olive: [128, 128, 0],
  purple: [128, 0, 128],
  red: [255, 0, 0],
  silver: [192, 192, 192],
  teal: [0, 128, 128],
  white: [255, 255, 255],
  yellow: [255, 255, 0],
  orange: [255, 165, 0],
  pink: [255, 192, 203],
  brown: [165, 42, 42],
  cyan: [0, 255, 255],
  magenta: [255, 0, 255],
  indigo: [75, 0, 130],
  violet: [238, 130, 238],
  turquoise: [64, 224, 208],
  gold: [255, 215, 0],
  salmon: [250, 128, 114],
  khaki: [240, 230, 140],
  plum: [221, 160, 221],
  coral: [255, 127, 80],
  lavender: [230, 230, 250],
};

// Main color generation function
function makeColor(options: ColorOptions = {}): string {
  const { color, transparency = 1 } = options;
  let selectedColor: string;

  if (color === undefined) {
    // Random color if not specified
    selectedColor = baseColors[Math.floor(Math.random() * baseColors.length)];
  } else if (typeof color === "string") {
    // Use the provided color string
    selectedColor = color.toLowerCase();
  } else if (typeof color === "number") {
    // Use the color at the specified index (with wrapping)
    selectedColor = baseColors[color % baseColors.length];
  } else {
    throw new Error("Invalid color type");
  }

  // Convert the selected color to RGB values
  const rgbValues = colorMap[selectedColor] || [0, 0, 0]; // Default to black if color not found

  const result = `rgba(${rgbValues[0]}, ${rgbValues[1]}, ${rgbValues[2]}, ${transparency})`;
  return result;
}

// Function to get the next color in the array
function getNextColor(): string {
  const color = baseColors[colorIndex];
  colorIndex = (colorIndex + 1) % baseColors.length;
  return color;
}

// Utility function to get a random color
function getRandomColor(): string {
  return baseColors[Math.floor(Math.random() * baseColors.length)];
}

// Utility function to get a color by index
function getColorByIndex(index: number): string {
  return baseColors[index % baseColors.length];
}

// Export the functions and array for use in other modules
export { baseColors, makeColor, getNextColor, getRandomColor, getColorByIndex };
