import { getSolidColor, TWO_PI } from "../math/utils";
import type { Camera } from "../plane/camera";
import { BodyOffset, Plane } from "../plane/plane";
import type { Player } from "../plane/player";

export class Renderer {
  private ctx: CanvasRenderingContext2D;
  private plane: Plane;

  constructor(canvas: HTMLCanvasElement, plane: Plane) {
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      throw new Error("Failed to get canvas context");
    }
    this.ctx = ctx;
    this.plane = plane;
  }

  render(player: Player, metricsReport: string): void {
    // Clear canvas
    this.ctx.fillStyle = "#1e1e1e";
    this.ctx.fillRect(0, 0, player.camera.width, player.camera.height);

    this.ctx.lineJoin = "round";

    // this.renderGrid(player.camera);
    this.renderVisibleBodies(player, false);
    this.renderMetrics(player.camera, metricsReport);
    this.renderControls(player.camera);
    // this.renderCompositeCentroids(player.camera);
    // this.renderBooleanResults(player.camera);
  }

  renderVisibleBodies(player: Player, renderMecs: boolean = false): void {
    const visibleBodyIndices = this.plane.getVisibleBodyIndices(player);

    const screenViewSize = Math.min(player.camera.width, player.camera.height);

    // Define LOD thresholds
    const FULL_DETAIL_SIZE_PERCENT = 0.002;
    const LOD_SIZE_PERCENT = 0.0007;

    const minFullDetailRadius = screenViewSize * FULL_DETAIL_SIZE_PERCENT;
    const minLODRadius = screenViewSize * LOD_SIZE_PERCENT;

    const stride = this.plane.bodyDataStride;

    // Render actuators
    for (const actuator of this.plane.actuators) {
      if (
        visibleBodyIndices.includes(actuator.body1.index) ||
        visibleBodyIndices.includes(actuator.body2.index)
      ) {
        actuator.render(player.camera, this.ctx);
      }
    }

    this.ctx.strokeStyle = "rgb(255, 255, 255)";
    this.ctx.lineWidth = 4 * player.camera.zoom;

    // Separate shapes into full detail and LOD
    for (const index of visibleBodyIndices) {
      const mecX =
        this.plane.bodyStates[index * stride + BodyOffset.MecPositionX];
      const mecY =
        this.plane.bodyStates[index * stride + BodyOffset.MecPositionY];
      const mecRadius =
        this.plane.bodyStates[index * stride + BodyOffset.MecRadius];
      const screenRadius = mecRadius * player.camera.zoom;

      const body = this.plane.getBody(index);
      if (!body) {
        // console.warn("body is null", index);
        continue;
      }

      if (screenRadius >= minFullDetailRadius) {
        // Render full detail shape

        body.shape.updateAllGlobal(
          this.plane.bodyStates[index * stride + BodyOffset.PositionX],
          this.plane.bodyStates[index * stride + BodyOffset.PositionY],
          this.plane.bodyStates[index * stride + BodyOffset.Rotation]
        );
        body.shape.render(this.ctx, player.camera, body.material.color);
        // body.shape.renderQuadtree(this.ctx, player.camera);

        if (renderMecs) {
          this.renderMec(player.camera, index);
        }
      } else if (screenRadius >= minLODRadius) {
        // Render simplified LOD circle
        this.renderCircle(
          player.camera,
          mecX,
          mecY,
          mecRadius,
          body.material.color
        );
      }
    }
  }

  renderMec(camera: Camera, bodyIndex: number): void {
    const stride = this.plane.bodyDataStride;
    const mecX =
      this.plane.bodyStates[bodyIndex * stride + BodyOffset.MecPositionX];
    const mecY =
      this.plane.bodyStates[bodyIndex * stride + BodyOffset.MecPositionY];
    const mecRadius =
      this.plane.bodyStates[bodyIndex * stride + BodyOffset.MecRadius];
    this.renderCircle(
      camera,
      mecX,
      mecY,
      mecRadius,
      "rgb(255, 255, 255)",
      false
    );
  }

  renderBooleanResults(camera: Camera): void {
    for (const result of this.plane.booleanResults) {
      result.shapes.forEach((shape) => {
        shape.updateAllGlobal(result.centroid.x, result.centroid.y, 0);

        shape.render(this.ctx, camera, "rgba(255, 0, 0, 0.6)");
      });

      // render centroid
      const [screenX, screenY] = camera.worldToScreen(
        result.centroid.x,
        result.centroid.y
      );
      this.ctx.fillRect(screenX - 10, screenY - 10, 20, 20);

      // render penetration vector
      const penetrationVector = result.penetrationVector;
      const [screenPenetrationX, screenPenetrationY] = camera.worldToScreen(
        penetrationVector.x + result.centroid.x,
        penetrationVector.y + result.centroid.y
      );
      this.ctx.strokeStyle = "rgb(255, 255, 255)";
      this.ctx.lineWidth = 10 * camera.zoom;
      this.ctx.beginPath();
      this.ctx.moveTo(screenX, screenY);
      this.ctx.lineTo(screenPenetrationX, screenPenetrationY);
      this.ctx.stroke();
    }
  }

  renderCircle(
    camera: Camera,
    centerX: number,
    centerY: number,
    radius: number,
    color: string = "rgb(255, 255, 255)",
    fill: boolean = true
  ): void {
    this.ctx.beginPath();

    const [screenCenterX, screenCenterY] = camera.worldToScreen(
      centerX,
      centerY
    );
    const screenRadius = camera.worldToScreenDistance(radius);
    this.ctx.arc(screenCenterX, screenCenterY, screenRadius, 0, TWO_PI);
    this.ctx.fillStyle = color;
    if (fill) {
      this.ctx.fill();
    }
    this.ctx.stroke();
  }

  renderCompositeCentroids(camera: Camera): void {
    for (const composite of this.plane.compositeBodies) {
      const [screenX, screenY] = camera.worldToScreen(
        composite.centroidPosition[0],
        composite.centroidPosition[1]
      );
      this.ctx.fillRect(screenX - 5, screenY - 5, 10, 10);
    }
  }

  renderMetrics(camera: Camera, metricsReport: string): void {
    // Setup text rendering style
    this.ctx.save();
    this.ctx.fillStyle = "white";
    this.ctx.font = "14px monospace";
    this.ctx.textAlign = "right";
    this.ctx.textBaseline = "top";

    // Draw each metric line
    const lines = metricsReport.split("\n");
    lines.forEach((line, i) => {
      this.ctx.fillText(line, camera.width - 10, 10 + i * 20);
    });

    this.ctx.restore();
  }

  renderGrid(camera: Camera): void {
    const GRID_SIZE = 50; // World units between grid lines
    const DOT_SIZE = 2; // Screen pixels for dot size

    this.ctx.fillStyle = "rgba(255, 255, 255, 0.2)";

    // Calculate the visible area in world coordinates
    const leftEdge = camera.x - camera.width / (2 * camera.zoom);
    const topEdge = camera.y - camera.height / (2 * camera.zoom);
    const rightEdge = camera.x + camera.width / (2 * camera.zoom);
    const bottomEdge = camera.y + camera.height / (2 * camera.zoom);

    // Adjust grid size based on zoom level
    let gridSize = GRID_SIZE; // Base grid size (50)
    const viewWidth = rightEdge - leftEdge;

    // Scale up grid size when zoomed out
    while (viewWidth / gridSize > 100) {
      // More than 100 lines would be drawn
      gridSize *= 10;
    }
    // Scale down grid size when zoomed in
    while (viewWidth / gridSize < 10) {
      // Fewer than 10 lines would be drawn
      gridSize /= 2;
    }

    // Find the first grid line positions
    const startX = Math.floor(leftEdge / gridSize) * gridSize;
    const startY = Math.floor(topEdge / gridSize) * gridSize;

    // Draw the grid
    for (let x = startX; x <= rightEdge; x += gridSize) {
      for (let y = startY; y <= bottomEdge; y += gridSize) {
        const [screenX, screenY] = camera.worldToScreen(x, y);

        // Determine dot size based on grid level
        const isMajorDot =
          x % (gridSize * 10) === 0 && y % (gridSize * 10) === 0;
        const isMediumDot =
          x % (gridSize * 5) === 0 && y % (gridSize * 5) === 0;

        let dotSize;
        if (isMajorDot) {
          dotSize = DOT_SIZE * 3;
          this.ctx.fillStyle = "rgba(255, 255, 255, 0.4)";
        } else if (isMediumDot) {
          dotSize = DOT_SIZE * 2;
          this.ctx.fillStyle = "rgba(255, 255, 255, 0.3)";
        } else {
          dotSize = DOT_SIZE;
          this.ctx.fillStyle = "rgba(255, 255, 255, 0.2)";
        }

        this.ctx.fillRect(
          screenX - dotSize / 2,
          screenY - dotSize / 2,
          dotSize,
          dotSize
        );
      }
    }
  }

  renderControls(camera: Camera): void {
    // Setup text rendering style
    this.ctx.save();
    this.ctx.fillStyle = "white";
    this.ctx.font = "14px monospace";
    this.ctx.textAlign = "left";
    this.ctx.textBaseline = "bottom";

    const controlsText = [
      "Controls:",
      "Thrust - W",
      "Shoot  - Space",
      "Grab   - Left mouse",
      "Cut    - Right mouse",
    ];

    // Draw each line from bottom up
    controlsText.forEach((line, i) => {
      const bottomOffset = controlsText.length - 1 - i;
      this.ctx.fillText(
        line,
        10, // 10px from left edge (instead of right)
        camera.height - (10 + bottomOffset * 20)
      );
    });

    this.ctx.restore();
  }
}
