import { PlanarClient } from "./planar/planarClient.ts";
import { PlanarLocal } from "./planar/planarLocal.ts";
const canvas = document.getElementById("limbleCanvas") as HTMLCanvasElement;

// Handle high DPI displays
const pixelRatio = window.devicePixelRatio || 1;
const rect = canvas.getBoundingClientRect();

// Set canvas size accounting for pixel ratio
canvas.width = rect.width * pixelRatio;
canvas.height = rect.height * pixelRatio;

// Scale the canvas context
const ctx = canvas.getContext("2d");
if (ctx) {
  ctx.scale(pixelRatio, pixelRatio);
}

// Handle resize events
function resizeCanvas() {
  const rect = canvas.getBoundingClientRect();
  canvas.width = rect.width * pixelRatio;
  canvas.height = rect.height * pixelRatio;
  if (ctx) {
    ctx.scale(pixelRatio, pixelRatio);
  }
}

// Listen for resize and orientation changes
window.addEventListener("resize", resizeCanvas);
window.addEventListener("orientationchange", resizeCanvas);

const local = true;

let planar: PlanarLocal | PlanarClient | null = null;

if (local) {
  planar = new PlanarLocal(canvas);
  planar.generateVariety({
    boundaryRadius: 10000,
    smallCount: 0,
    mediumCount: 7,
    largeCount: 0,
  });
  planar.start();
} else {
  const host = `${window.location.hostname}:${window.location.port}`;

  planar = new PlanarClient({
    canvas,
    host: host,
    room: "ayyo",
    maxBodies: 10000,
  });
  planar.start();
}
